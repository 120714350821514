import { Box, FormHelperText, Grid, InputLabel } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import { useCallback } from 'react'

import DKTCheckbox from '../Shared/DKTCheckbox'
import DKTInput from '../Shared/DKTInput'
import DKTSearchableSelect from '../Shared/DKTSearchableSelect'
import DKTSelect from '../Shared/DKTSelect'
import DKTTextArea from '../Shared/DKTTextArea'
import DKTCurrencyTextField from '../Shared/DKTCurrencyTextField'
import { BOOLEAN } from './constant'
import {
  checkIncludes,
  checkUndefined,
  entries,
  equal,
  isArray,
  notNull,
  ternary,
} from './javascript'

const useStyles = makeStyles(() => ({
  labelledCheckbox: {
    '&.MuiFormControl-root': {
      marginLeft: -10,
      alignItems: 'center',
      flexDirection: 'row',
    },
    '& .MuiInputLabel-root': {
      color: '#3E3E3E',
      position: 'inherit',
      fontSize: 13,
      transform: 'translate(0)',
      whiteSpace: 'normal',
      width: 'max-content',
    },
  },
}))

export const renderFormFields = ({
  formFields,
  values,
  errors,
  handleChange,
  customFormControlFields = () => {},
}) => {
  const classes = useStyles()
  const renderFormControl = useCallback(
    ({
      formControl,
      name,
      fields,
      hideFormControl = false,
      isRequired,
      innerRef,
      sx,
      ...rest
    }) => {
      if (hideFormControl) return null
      switch (formControl.type) {
        case 'checkbox':
          return (
            <Box>
              {!equal(formControl.showLabel, false) && formControl.label && (
                <InputLabel
                  shrink
                  sx={{ fontWeight: 'medium', color: 'gray.extraDark' }}
                >
                  {formControl.label}
                </InputLabel>
              )}
              <Grid container columnSpacing={{ sm: 2, xl: 10 }}>
                {(fields || formControl.fields)?.map((field, index) => (
                  <Grid item xs={6} key={index}>
                    <DKTCheckbox
                      name={name}
                      checked={
                        equal(formControl?.valueType, BOOLEAN)
                          ? !!values[name]
                          : !!checkIncludes(field?.value, values[name])
                      }
                      value={field?.value || ''}
                      onChange={handleChange}
                      label={field?.label}
                      className={classNames({
                        [classes.labelledCheckbox]:
                          field.labelledCheckbox ||
                          formControl.labelledCheckbox,
                      })}
                      sx={formControl?.sx}
                      {...rest}
                    />
                  </Grid>
                ))}
              </Grid>
              {errors && errors[name] ? (
                isArray(errors[name]) ? (
                  errors[name].map((errorMessage, index) => (
                    <FormHelperText error={!!errors[name]} key={index}>
                      {errorMessage}
                    </FormHelperText>
                  ))
                ) : (
                  <FormHelperText error={!!errors[name]}>
                    {errors[name]}
                  </FormHelperText>
                )
              ) : null}
            </Box>
          )
        case 'select':
          return ternary(
            formControl.isSearchable,
            <DKTSearchableSelect
              innerRef={innerRef}
              name={name}
              label={formControl.label}
              onChange={(option) => {
                handleChange({ target: { value: option?.value, name } })
              }}
              clearValue={() => handleChange({ target: { value: '', name } })}
              error={errors[name]}
              {...formControl}
              {...rest}
            />,
            <DKTSelect
              label={formControl.label}
              name={name}
              error={errors[name]}
              value={
                (values &&
                  ternary(
                    formControl?.multiple,
                    values[name] || [],
                    values[name]?.toString(),
                  )) ||
                ''
              }
              onChange={handleChange}
              {...formControl}
              {...rest}
            />,
          )
        case 'textarea':
          return (
            <DKTTextArea
              value={(values && values[name]) || ''}
              name={name}
              error={errors[name]}
              onChange={handleChange}
              {...formControl}
              {...rest}
            />
          )
        default:
          return ternary(
            formControl?.isCurrency ||
              rest.isCurrency ||
              formControl?.decimalPlaces ||
              rest.decimalPlaces ||
              formControl?.formatDigits,
            <DKTCurrencyTextField
              label={formControl.label}
              variant="standard"
              value={(values && values[name]) ?? ''}
              isRequired={formControl?.isRequired || isRequired}
              error={errors[name]}
              disabled={formControl?.disabled || rest?.disabled}
              currencySymbol={
                formControl?.isCurrency || rest.isCurrency ? '$' : ''
              }
              minimumValue="0"
              outputFormat="string"
              decimalCharacter="."
              digitGroupSeparator={
                formControl?.isCurrency ||
                rest.isCurrency ||
                formControl?.formatDigits
                  ? ','
                  : ''
              }
              onChange={(event, value) =>
                handleChange({ target: { name, value } })
              }
              decimalPlaces={formControl?.decimalPlaces}
              endAdornment={formControl?.endAdornment}
              isLimitLess={formControl?.isLimitLess}
              tooltip={rest?.tooltip || formControl?.tooltip}
              sx={formControl?.sx}
            />,
            <DKTInput
              label={formControl.label}
              name={name}
              error={errors[name]}
              value={ternary(
                values &&
                  notNull(values[name]) &&
                  !checkUndefined(values[name]),
                values[name]?.toString(),
                '',
              )}
              onChange={handleChange}
              isRequired={isRequired}
              {...rest}
              {...formControl}
            />,
          )
      }
    },
    [values, errors, handleChange],
  )

  return entries(formFields).map(
    (
      [
        name,
        {
          isPhoneNumber,
          isConfirmPassword,
          isDynamicOptions,
          dependsOn,
          disableEmpty,
          clearable,
          validate,
          ...formControl
        },
      ],
      index,
    ) => {
      const formFieldOptions = { formControl, name }
      return (
        <Grid
          item
          xs={formControl.xs ?? 12}
          key={index}
          sx={formControl?.style ?? {}}
        >
          {renderFormControl(
            {
              ...formFieldOptions,
              ...customFormControlFields(formFieldOptions),
            } || formFieldOptions,
          )}
        </Grid>
      )
    },
  )
}
