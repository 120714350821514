import api from '../../Utils/api'
import { apiEndPoints, method } from '../../Utils/constant'
import {
  cancelApiCallSource,
  downloadItem,
  equal,
} from '../../Utils/javascript'
import { showToast } from '../../Utils/toastService'
import {
  FETCH_REPORT_PDF_REQUESTED,
  FETCH_REPORT_PDF_SUCCESS,
  FETCH_REPORT_PDF_FAILED,
  FETCH_GENERATE_PDF_LINK_REQUESTED,
  FETCH_GENERATE_PDF_LINK_SUCCESS,
  FETCH_GENERATE_PDF_LINK_FAILED,
  CLEAR_GENERATE_PDF_LINK,
  OPEN_CURVE_MODAL,
} from '../constants/ReportDownload'

const fetchReportDownloadRequested = () => ({
  type: FETCH_REPORT_PDF_REQUESTED,
})
const fetchReportDownloadSuccess = (payload) => ({
  type: FETCH_REPORT_PDF_SUCCESS,
  payload,
})
export const openCurveModal = (payload) => ({
  type: OPEN_CURVE_MODAL,
  payload,
})
const fetchReportDownloadFailed = (payload) => ({
  type: FETCH_REPORT_PDF_FAILED,
  payload,
})

const deleteGeneratedReport = (reportIds, projectId) => async () => {
  await api({
    endPoint: `${apiEndPoints.generateLink}${reportIds}/?projectId=${projectId}`,
    method: method.delete,
  })
}

export const downloadReport =
  (url, reportIds, projectId) => async (dispatch) => {
    dispatch(fetchReportDownloadRequested())

    const { data, error } = await api({
      urlEndPoint: url,
      method: method.get,
      responseType: 'arraybuffer',
      attachAccessToken: false,
    })

    if (data) {
      const isPdf = url.endsWith('.pdf')
      const fileType = isPdf ? 'application/pdf' : 'application/zip'

      const projectName = url.substring(
        url.lastIndexOf('/') + 1,
        url.lastIndexOf('.'),
      )

      const filename = isPdf ? `${projectName}.pdf` : `${projectName}.zip`
      downloadItem(data, fileType, filename)

      dispatch(fetchReportDownloadSuccess(data))
      dispatch(deleteGeneratedReport(reportIds, projectId))
    } else if (error) {
      dispatch(fetchReportDownloadFailed(error.response.data))
    }
  }

const fetchGenerateLinkRequested = () => ({
  type: FETCH_GENERATE_PDF_LINK_REQUESTED,
})
const fetchGenerateLinkSuccess = (payload) => ({
  type: FETCH_GENERATE_PDF_LINK_SUCCESS,
  payload,
})
const fetchGenerateLinkFailed = (payload) => ({
  type: FETCH_GENERATE_PDF_LINK_FAILED,
  payload,
})
export const clearGenerateLinkFailed = () => ({
  type: CLEAR_GENERATE_PDF_LINK,
})

export const generateLink =
  ({
    reportIds,
    isIncludeAttachment,
    isGeneratedReport = false,
    setCancelTokenSource,
    projectId,
  }) =>
  async (dispatch, getState) => {
    dispatch(fetchGenerateLinkRequested())
    if (!isGeneratedReport) {
      dispatch(fetchReportDownloadRequested())
    }
    const { reportList } = getState().reportMonitoring
    const source = cancelApiCallSource()
    setCancelTokenSource(source)
    const { data, error } = await api({
      endPoint: `${
        apiEndPoints.generateLink
      }?reportIds=${reportIds}&projectId=${projectId}&isIncludeAttachment=${isIncludeAttachment}&isDownloadReport=${!isGeneratedReport}&isGeneratedReport=${isGeneratedReport}`,
      method: method.post,
      cancelToken: source.token,
    })

    const pollLink = async (ids) => {
      const { data, error } = await api({
        endPoint: `${apiEndPoints.generateLink}${ids}/?projectId=${projectId}`,
        method: method.get,
        cancelToken: source.token,
      })

      if (data?.status) {
        if (equal(data?.status, 'CO')) {
          const updateReportData = reportList?.map((item) => {
            if (equal(item?.id, reportIds)) {
              return {
                ...item,
                reportPdf: data?.url,
              }
            }
            return item
          })
          if (!isGeneratedReport) {
            dispatch(downloadReport(data?.downloadedFile, data?.id, projectId))
            return
          }
          dispatch(
            fetchGenerateLinkSuccess({ reportList: updateReportData, data }),
          )
        } else if (equal(data?.status, 'F')) {
          dispatch(fetchGenerateLinkFailed(error))
          showToast('Something went wrong!', 'error')
        } else {
          setTimeout(() => pollLink(ids), 5000)
        }
      } else if (error) {
        dispatch(fetchGenerateLinkFailed(error))
      }
    }

    if (data?.request_id) {
      const ids = Array.isArray(data?.request_id)
        ? data?.request_id.toString()
        : data?.request_id
      pollLink(ids)
    } else {
      dispatch(fetchGenerateLinkFailed(error))
    }
  }
