export const CREATE_CONSTRUCTION_MONITORING_REPORT_REQUESTED =
  'CREATE_CONSTRUCTION_MONITORING_REPORT_REQUESTED'
export const CREATE_CONSTRUCTION_MONITORING_REPORT_FAILED =
  'CREATE_CONSTRUCTION_MONITORING_REPORT_FAILED'
export const CREATE_CONSTRUCTION_MONITORING_REPORT_SUCCESS =
  'CREATE_CONSTRUCTION_MONITORING_REPORT_SUCCESS'

export const FETCH_CONSTRUCTION_MONITORING_REPORT_REQUESTED =
  'FETCH_CONSTRUCTION_MONITORING_REPORT_REQUESTED'
export const FETCH_CONSTRUCTION_MONITORING_REPORT_FAILED =
  'FETCH_CONSTRUCTION_MONITORING_REPORT_FAILED'
export const FETCH_CONSTRUCTION_MONITORING_REPORT_SUCCESS =
  'FETCH_CONSTRUCTION_MONITORING_REPORT_SUCCESS'

export const FETCH_LIMITATIONS_REQUESTED = 'FETCH_LIMITATIONS_REQUESTED'
export const FETCH_LIMITATIONS_FAILED = 'FETCH_LIMITATIONS_FAILED'
export const FETCH_LIMITATIONS_SUCCESS = 'FETCH_LIMITATIONS_SUCCESS'

export const FETCH_REPORT_TIER_SETTINGS_REQUESTED =
  'FETCH_REPORT_TIER_SETTINGS_REQUESTED'
export const FETCH_REPORT_TIER_SETTINGS_FAILED =
  'FETCH_REPORT_TIER_SETTINGS_FAILED'
export const FETCH_REPORT_TIER_SETTINGS_SUCCESS =
  'FETCH_REPORT_TIER_SETTINGS_SUCCESS'

export const FETCH_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED =
  'FETCH_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED'
export const FETCH_CONSTRUCTION_SUMMARY_FORMAT_FAILED =
  'FETCH_CONSTRUCTION_SUMMARY_FORMAT_FAILED'
export const FETCH_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS =
  'FETCH_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS'

export const SAVE_LIMITATIONS_REQUESTED = 'SAVE_LIMITATIONS_REQUESTED'
export const SAVE_LIMITATIONS_FAILED = 'SAVE_LIMITATIONS_FAILED'
export const SAVE_LIMITATIONS_SUCCESS = 'SAVE_LIMITATIONS_SUCCESS'

export const SAVE_REPORT_TIER_SETTINGS_REQUESTED =
  'SAVE_REPORT_TIER_SETTINGS_REQUESTED'
export const SAVE_REPORT_TIER_SETTINGS_FAILED =
  'SAVE_REPORT_TIER_SETTINGS_FAILED'
export const SAVE_REPORT_TIER_SETTINGS_SUCCESS =
  'SAVE_REPORT_TIER_SETTINGS_SUCCESS'

export const SAVE_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED =
  'SAVE_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED'
export const SAVE_CONSTRUCTION_SUMMARY_FORMAT_FAILED =
  'SAVE_CONSTRUCTION_SUMMARY_FORMAT_FAILED'
export const SAVE_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS =
  'SAVE_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS'

export const HANDLE_REPORT_TIER_SETTINGS_CHANGE =
  'HANDLE_REPORT_TIER_SETTINGS_CHANGE'

// executive summary
export const FETCH_ES_REQUESTED = 'FETCH_ES_REQUESTED'
export const FETCH_ES_SUCCESS = 'FETCH_ES_SUCCESS'
export const FETCH_ES_FAILED = 'FETCH_ES_FAILED'

export const SAVE_ES_REQUESTED = 'SAVE_ES_REQUESTED'
export const SAVE_ES_SUCCESS = 'SAVE_ES_SUCCESS'
export const SAVE_ES_FAILED = 'SAVE_ES_FAILED'

export const FETCH_CP_REQUESTED = 'FETCH_CP_REQUESTED'
export const FETCH_CP_SUCCESS = 'FETCH_CP_SUCCESS'
export const FETCH_CP_FAILED = 'FETCH_CP_FAILED'

export const SAVE_CP_REQUESTED = 'SAVE_CP_REQUESTED'
export const SAVE_CP_SUCCESS = 'SAVE_CP_SUCCESS'
export const SAVE_CP_FAILED = 'SAVE_CP_FAILED'
