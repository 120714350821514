import {
  Divider,
  Typography,
  Box,
  Stack,
  Card,
  CardContent,
  Grid,
} from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { useState, memo, useEffect } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { chartTiles } from '../../../Description/performanceChart.description'
import { equal, gt, keys, lt, ternary, values } from '../../../Utils/javascript'
import DKTButton from '../../../Shared/DKTButton'
import DKTSelect from '../../../Shared/DKTSelect'
import DrawProcess from './DrawProcess'
import EconomicCompletion from './EconomicCompletion'
import { useStyles } from '../../../Styles/reportChart.style'
import theme from '../../../Theme/index'
import {
  clearOldDataForChart,
  createEconomicCompletion,
  createScheduleDuration,
  fetchDrawProjectChartData,
  getEconomicCompletionData,
  getPerformanceChartData,
} from '../../../Redux/actions/reportMonitoring'
import { clearOldReportChoiceData } from '../../../Redux/actions/reportChoices'
import { clientPortal } from '../../../Utils/constant'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  plugins: {
    legend: {
      display: false,
      labels: {
        color: '',
        font: {
          size: 12,
          weight: 'bold',
          family: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  cutout: '70%',
}

const ChartRender = ({
  data,
  textCenter,
  sectionTitle,
  cardDetail,
  performanceChartData,
}) => {
  const classes = useStyles()
  return (
    <>
      <FormSectionDivider sectionTitle={sectionTitle} />
      <Box mx="auto" className={classes.pieChart}>
        <Doughnut data={data} options={options} plugins={[textCenter]} />
      </Box>
      {values(cardDetail)?.map(({ title, name, type }) => (
        <Card
          sx={{
            backgroundColor: theme.palette.secondary.main,
            boxShadow: 'none',
            marginBottom: 0.8,
            height: '106px',
          }}
          key={title}
        >
          <CardContent sx={{ '&.MuiCardContent-root': { padding: 2 } }}>
            <Typography
              sx={{ fontSize: 14, marginBottom: 2.4 }}
              color="#042440"
              gutterBottom
            >
              {title}
            </Typography>
            <Box>
              <Typography
                sx={{
                  fontSize: 24,
                  color: ternary(
                    equal(name, 'delay_days') && performanceChartData[name] > 0,
                    '#ff0000',
                    '#042440',
                  ),
                  fontWeight: 500,
                }}
              >
                {equal(type, 'date') && performanceChartData[name]
                  ? moment(performanceChartData[name]).format('MM/DD/YYYY')
                  : equal(name, 'delay_days')
                  ? performanceChartData[name] || 0
                  : performanceChartData[name] || '--'}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  )
}

const FormSectionDivider = ({ sectionTitle, rightSection, ...rest }) => (
  <>
    <Stack
      {...rest}
      direction="row"
      alignItems="end"
      justifyContent="space-between"
      width="100%"
      mt={3}
    >
      <Typography
        variant="body2"
        color="gray.extraDark"
        sx={{ fontWeight: 'medium', paddingBottom: 0.5 }}
      >
        {sectionTitle}
      </Typography>
      {rightSection}
    </Stack>
    <Divider sx={{ mb: 1, background: '#D9C666' }} />
  </>
)

const selectOptions = Array.from({ length: 60 }, (_, index) => ({
  label: (index + 1).toString().padStart(2, '0'),
  value: `${index + 1}`,
}))

const PerformanceChart = ({ projectId, isCooModalOpen, setIsCooModalOpen }) => {
  const [scheduleDuration, setScheduleDuration] = useState('')
  const classes = useStyles()
  const dispatch = useDispatch()
  const { portalType } = useSelector(({ auth }) => auth)

  const {
    reportList,
    performanceChartData,
    economicCompletionData,
    drawProgressData,
    isPerformanceChartDataLoading,
    isEconomicDataLoading,
    isDrawProgressDataLoading,
  } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const lastReportId = reportList
    ?.filter((item) => equal(item?.status, 'Published'))
    ?.at(-1)?.id

  useEffect(() => {
    if (
      projectId &&
      keys(drawProgressData)?.length &&
      !drawProgressData?.progress_value
    ) {
      const payload = {
        projectId,
        progressValue: 12,
      }
      dispatch(createScheduleDuration(payload, projectId))
    }
  }, [projectId, drawProgressData])

  useEffect(() => {
    if (lastReportId) {
      dispatch(getPerformanceChartData(projectId, lastReportId))
    }
  }, [lastReportId])

  useEffect(() => {
    if (projectId) {
      dispatch(fetchDrawProjectChartData(projectId))
      dispatch(getEconomicCompletionData(projectId))
    }
  }, [projectId])

  useEffect(() => {
    if (drawProgressData?.progress_value) {
      setScheduleDuration(`${drawProgressData?.progress_value}`)
    }
  }, [drawProgressData])

  useEffect(
    () => () => {
      dispatch(clearOldDataForChart())
      dispatch(clearOldReportChoiceData())
    },
    [],
  )

  const openCooModal = () => setIsCooModalOpen(true)
  const closeCooModal = () => setIsCooModalOpen(false)

  const handleChange = (e) => {
    const { value } = e.target
    const payload = {
      projectId,
      progressValue: value,
    }
    setScheduleDuration(value)
    dispatch(createScheduleDuration(payload, projectId))
    if (value < +scheduleDuration && keys(economicCompletionData).length) {
      const cloneData = keys(economicCompletionData)
        ?.slice(0, +value)
        .reduce((acc, key) => {
          acc[key] = { ...economicCompletionData[key] }
          return acc
        }, {})

      const economicPayload = {
        economicCompletion: { ...cloneData },
        projectId,
      }
      dispatch(createEconomicCompletion(economicPayload, projectId))
    }
  }

  const editEconomicCompletion = !equal(portalType, clientPortal) && (
    <DKTButton
      variant="text"
      disableRipple
      sx={{ paddingLeft: 0, paddingRight: 0 }}
      onClick={openCooModal}
    >
      Edit Economic Completion
    </DKTButton>
  )

  const checkPercentage = (value) =>
    ternary(gt(value, 100), 100, ternary(lt(value, 0), 0, value))

  return (
    <Box px={5} marginTop="164px">
      <Grid item xs={4} mt={3}>
        <Typography variant="h6" fontWeight={700}>
          Performance Chart
        </Typography>
      </Grid>
      <Grid container>
        <Grid item sm={6}>
          {equal(isPerformanceChartDataLoading, 'FAILED') ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: 'calc(100vh - 290px)' }}
            >
              <Typography variant="body2" color="gray.extraDark" ml={2}>
                There might be some issue with fetching Performance chart data.
                Please try contacting the admin or refreshing this page.
              </Typography>
            </Stack>
          ) : (
            <Grid container columnSpacing={0.6}>
              {chartTiles?.map((item) => {
                const textCenter = {
                  id: 'textCenter',
                  beforeDatasetsDraw(chart) {
                    const { ctx, data } = chart
                    ctx.save()
                    ctx.font = '20px Roboto'
                    ctx.fillStyle = 'black'
                    ctx.textAlign = 'center'
                    ctx.textBaseline = 'middle'
                    ctx.fillText(
                      `${
                        equal(item.heading, 'Remaining HC Contingency')
                          ? Math.round(
                              checkPercentage(data.datasets[0].data[1]),
                            )
                          : equal(item.heading, 'Contract Time')
                          ? Math.round(
                              checkPercentage(data.datasets[0].data[0]),
                            )
                          : checkPercentage(data.datasets[0].data[0])
                      }%`,
                      chart.getDatasetMeta(0).data[0].x,
                      chart.getDatasetMeta(0).data[0].y,
                    )
                  },
                }
                const data = {
                  labels: ['Complete', 'Remaining'],
                  datasets: [
                    {
                      label: ' ',
                      data: equal(item.heading, 'Remaining HC Contingency')
                        ? [
                            100 -
                              (checkPercentage(
                                performanceChartData[item.chartId],
                              ) || 0),
                            checkPercentage(
                              performanceChartData[item.chartId],
                            ) || 0,
                          ]
                        : [
                            checkPercentage(
                              performanceChartData[item.chartId],
                            ) || 0,
                            100 -
                              (checkPercentage(
                                performanceChartData[item.chartId],
                              ) || 0),
                          ],
                      backgroundColor: equal(
                        item.heading,
                        'Remaining HC Contingency',
                      )
                        ? [theme.palette.secondary.main, 'rgb(217, 198, 102)']
                        : [
                            equal(item.heading, 'Percent Complete')
                              ? 'rgb(4, 36, 64)'
                              : '#00acf0',
                            theme.palette.secondary.main,
                          ],
                      borderWidth: 0,
                    },
                  ],
                }
                return (
                  <Grid item sm={4} key={item?.heading}>
                    <ChartRender
                      {...{ data, textCenter }}
                      sectionTitle={item?.heading}
                      cardDetail={item?.card}
                      performanceChartData={performanceChartData}
                    />
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          mt={equal(portalType, clientPortal) ? 0 : -2}
          pl={4}
        >
          <FormSectionDivider
            sectionTitle="Draw Progress"
            rightSection={editEconomicCompletion}
          />
          {equal(isDrawProgressDataLoading, 'FAILED') ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: 'calc(100vh - 405px)' }}
            >
              <Typography variant="body2" color="gray.extraDark" ml={2}>
                There might be some issue with fetching Economic chart draw
                data. Please try contacting the admin or refreshing this page.
              </Typography>
            </Stack>
          ) : (
            <>
              {!equal(portalType, clientPortal) && (
                <Box>
                  <DKTSelect
                    name="scheduleDuration"
                    id="scheduleDuration"
                    placeholder="Schedule Duration"
                    options={selectOptions}
                    displayEmpty
                    sx={{ marginTop: 0 }}
                    className={classes.select}
                    value={scheduleDuration || ''}
                    onChange={handleChange}
                  />
                </Box>
              )}

              <Box
                className={
                  equal(portalType, clientPortal)
                    ? classes.removeDrawWrapper
                    : classes.chartWrapper
                }
              >
                <DrawProcess
                  scheduleDuration={scheduleDuration}
                  drawProgressData={drawProgressData}
                />
              </Box>
            </>
          )}
        </Grid>
        <EconomicCompletion
          open={isCooModalOpen}
          onClose={closeCooModal}
          rowCount={+scheduleDuration}
          projectId={projectId}
          drawProgressData={drawProgressData}
          isEconomicDataLoading={isEconomicDataLoading}
        />
      </Grid>
    </Box>
  )
}

export default memo(PerformanceChart)
