import { equal, ternary } from '../../Utils/javascript'
import {
  CLEAR_GENERATE_PDF_LINK,
  FETCH_GENERATE_PDF_LINK_FAILED,
  FETCH_GENERATE_PDF_LINK_REQUESTED,
  FETCH_GENERATE_PDF_LINK_SUCCESS,
  FETCH_REPORT_PDF_FAILED,
  FETCH_REPORT_PDF_REQUESTED,
  FETCH_REPORT_PDF_SUCCESS,
  OPEN_CURVE_MODAL,
} from '../constants/ReportDownload'
import {
  CREATE_AND_UPDATE_REPORT_FAILED,
  CREATE_AND_UPDATE_REPORT_REQUESTED,
  CREATE_AND_UPDATE_REPORT_SUCCESS,
  DELETE_REPORT_FAILED,
  DELETE_REPORT_REQUESTED,
  DELETE_REPORT_SUCCESS,
  FETCH_REPORT_LIST_FAILED,
  FETCH_REPORT_LIST_REQUESTED,
  FETCH_REPORT_LIST_SUCCESS,
  FETCH_REPORT_SUCCESS,
  PUBLIC_REPORT_FAILED,
  PUBLIC_REPORT_REQUESTED,
  PUBLIC_REPORT_SUCCESS,
  FETCH_PERFORMANCE_CHART_DATA_FAILED,
  FETCH_PERFORMANCE_CHART_DATA_REQUESTED,
  FETCH_PERFORMANCE_CHART_DATA_SUCCESS,
  FETCH_ECONOMIC_DATA_REQUESTED,
  FETCH_ECONOMIC_DATA_SUCCESS,
  FETCH_ECONOMIC_DATA_FAILED,
  CREATE_AND_UPDATE_ECONOMIC_COMPLETION_FAILED,
  CREATE_AND_UPDATE_ECONOMIC_COMPLETION_REQUESTED,
  CREATE_AND_UPDATE_ECONOMIC_COMPLETION_SUCCESS,
  CLEAR_PERFORMANCE_CHART_DATA,
  FETCH_DRAW_PROGRESS_CHART_DATA_REQUESTED,
  FETCH_DRAW_PROGRESS_CHART_DATA_SUCCESS,
  FETCH_DRAW_PROGRESS_CHART_DATA_FAILED,
} from '../constants/reportMonitoring'

const initialState = {
  isCreateAndUpdateReportLoading: false,
  isReportListLoading: false,
  reportList: [],
  isReportDeletingLoading: false,
  reportError: '',
  isReportPublishingLoading: false,
  reportData: null,
  isReportDownloadingLoading: false,
  // performanceChart
  isPerformanceChartDataLoading: false,
  performanceChartData: {},
  performanceChartError: '',
  // economic completion
  isEconomicDataLoading: false,
  economicCompletionData: {},
  EconomicDataError: '',
  isEconomicDataSaveLoading: false,
  // draw progress
  isDrawProgressDataLoading: false,
  drawProgressData: {},
  drawProgressDataError: '',
  // generate link
  isGenerateLinkLoading: false,
  reportLink: {},
}

export const reportMonitoringReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Fetch report list
    case FETCH_REPORT_LIST_REQUESTED:
      return {
        ...state,
        isReportListLoading: true,
        reportError: '',
      }
    case FETCH_REPORT_LIST_SUCCESS:
      return {
        ...state,
        isReportListLoading: false,
        reportList: action?.payload,
      }
    case FETCH_REPORT_SUCCESS:
      return {
        ...state,
        isReportListLoading: false,
        reportData: state?.reportData
          ? { ...state?.reportData, ...action?.payload }
          : action?.payload,
      }
    case FETCH_REPORT_LIST_FAILED:
      return {
        ...state,
        isReportListLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        reportList: [],
      }
    // Create and update report
    case CREATE_AND_UPDATE_REPORT_REQUESTED:
      return {
        ...state,
        isCreateAndUpdateReportLoading: true,
        reportError: '',
      }
    case CREATE_AND_UPDATE_REPORT_SUCCESS:
      return {
        ...state,
        isCreateAndUpdateReportLoading: false,
        reportList: [action.payload],
      }
    case CREATE_AND_UPDATE_REPORT_FAILED:
      return {
        ...state,
        isCreateAndUpdateReportLoading: false,
        reportError: action.payload,
      }
    // Delete reports
    case DELETE_REPORT_REQUESTED:
      return {
        ...state,
        isReportDeletingLoading: true,
      }
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        isReportDeletingLoading: false,
        reportList: action.payload,
      }
    case DELETE_REPORT_FAILED:
      return {
        ...state,
        isReportDeletingLoading: false,
      }
    // publish report
    case PUBLIC_REPORT_REQUESTED:
      return {
        ...state,
        isReportPublishingLoading: true,
      }
    case PUBLIC_REPORT_SUCCESS:
      return {
        ...state,
        isReportPublishingLoading: false,
      }
    case PUBLIC_REPORT_FAILED:
      return {
        ...state,
        isReportPublishingLoading: false,
      }
    // download report pdf
    case FETCH_REPORT_PDF_REQUESTED:
      return {
        ...state,
        isReportDownloadingLoading: true,
      }
    case FETCH_REPORT_PDF_SUCCESS:
      return {
        ...state,
        isReportDownloadingLoading: false,
        isGenerateLinkLoading: false,
      }
    case OPEN_CURVE_MODAL:
      return {
        ...state,
        ...action.payload,
      }
    case FETCH_REPORT_PDF_FAILED:
      return {
        ...state,
        isReportDownloadingLoading: false,
        isGenerateLinkLoading: false,
      }
    // fetch data for the performance chart
    case FETCH_PERFORMANCE_CHART_DATA_REQUESTED:
      return {
        ...state,
        isPerformanceChartDataLoading: true,
      }
    case FETCH_PERFORMANCE_CHART_DATA_SUCCESS:
      return {
        ...state,
        isPerformanceChartDataLoading: false,
        performanceChartData: action.payload,
      }
    case FETCH_PERFORMANCE_CHART_DATA_FAILED:
      return {
        ...state,
        isPerformanceChartDataLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        performanceChartError: action.payload.data,
      }
    // fetch data for the economic completion data
    case FETCH_ECONOMIC_DATA_REQUESTED:
      return {
        ...state,
        isEconomicDataLoading: true,
      }
    case FETCH_ECONOMIC_DATA_SUCCESS:
      return {
        ...state,
        isEconomicDataLoading: false,
        economicCompletionData: action.payload,
      }
    case FETCH_ECONOMIC_DATA_FAILED:
      return {
        ...state,
        isEconomicDataLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        EconomicDataError: action?.payload?.data,
      }
    // fetch data for the draw progress data
    case FETCH_DRAW_PROGRESS_CHART_DATA_REQUESTED:
      return {
        ...state,
        isDrawProgressDataLoading: true,
      }
    case FETCH_DRAW_PROGRESS_CHART_DATA_SUCCESS:
      return {
        ...state,
        isDrawProgressDataLoading: false,
        drawProgressData: action.payload,
      }
    case FETCH_DRAW_PROGRESS_CHART_DATA_FAILED:
      return {
        ...state,
        isDrawProgressDataLoading: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        drawProgressDataError: action?.payload?.data,
      }
    case CREATE_AND_UPDATE_ECONOMIC_COMPLETION_REQUESTED:
      return {
        ...state,
        isEconomicDataSaveLoading: true,
      }
    case CREATE_AND_UPDATE_ECONOMIC_COMPLETION_SUCCESS:
      return {
        ...state,
        isEconomicDataSaveLoading: false,
      }
    case CREATE_AND_UPDATE_ECONOMIC_COMPLETION_FAILED:
      return {
        ...state,
        isEconomicDataSaveLoading: false,
      }
    // generate link
    case FETCH_GENERATE_PDF_LINK_REQUESTED:
      return {
        ...state,
        isGenerateLinkLoading: true,
      }
    case FETCH_GENERATE_PDF_LINK_SUCCESS:
      return {
        ...state,
        isGenerateLinkLoading: false,
        reportLink: action.payload?.data,
        reportList: action.payload?.reportList,
      }
    case FETCH_GENERATE_PDF_LINK_FAILED:
      return {
        ...state,
        isGenerateLinkLoading: false,
        isReportDownloadingLoading: false,
      }

    case CLEAR_GENERATE_PDF_LINK:
      return {
        ...state,
        isGenerateLinkLoading: false,
        isReportDownloadingLoading: false,
        reportLink: {},
      }
    // Clear chart old data
    case CLEAR_PERFORMANCE_CHART_DATA:
      return {
        ...state,
        reportList: [],
        performanceChartData: {},
        performanceChartError: '',
        economicCompletionData: {},
        EconomicDataError: '',
        drawProgressData: {},
        drawProgressDataError: '',
        isGenerateLinkLoading: false,
        reportLink: {},
      }
    default:
      return state
  }
}
