import { Box, Divider } from '@mui/material'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import DKTForm from '../../../Shared/DKTForm'
import DKTGrid from '../../../Shared/DKTGrid'
import CommentsSection from './CommentsSection'
import DKTTypography from '../../../Shared/DKTTypography'
import { renderFormFields } from '../../../Utils/renderFormFields'
import { equal, ternary } from '../../../Utils/javascript'
import usePermission from '../../../Hooks/usePermission'

const FormSection = ({
  title,
  commentsSectionProps,
  values,
  errors,
  handleChange,
  formFields,
  mergedFormFields,
  dynamicOptions,
}) => {
  const { choices: projectChoices } = useSelector(
    ({ projectChoices }) => projectChoices,
  )
  const { hasOnlyViewPermission } = usePermission()

  const customFormControlFields = ({ name, formControl }) => {
    const disabledFields = formControl?.disabledKey
      ? !values?.[formControl?.disabledKey]
      : false
    return {
      options: ternary(
        mergedFormFields[name].isDynamicOptions,
        { ...dynamicOptions, ...projectChoices }[name],
        mergedFormFields[name].options,
      ),
      disabled:
        hasOnlyViewPermission ||
        disabledFields ||
        (equal(name, 'remainingHcc') &&
          equal(values.useValueFromBudgetSummaryCo, 'YES')) ||
        (equal(name, 'remaining') &&
          equal(values.useValueFromBudgetSummaryCoAndPco, 'YES')),
    }
  }
  const renderFormFieldsWithOptions = useCallback(
    (formFields) =>
      renderFormFields({
        values,
        errors,
        handleChange,
        formFields,
        customFormControlFields,
      }),
    [values, errors, handleChange, formFields, customFormControlFields],
  )

  const fields = renderFormFieldsWithOptions(formFields)

  return (
    <Box mb={2.3}>
      <FormSectionDivider sectionTitle={title} />
      <DKTForm autoComplete="off">
        <DKTGrid container spacing={2}>
          {fields}
          <CommentsSection {...commentsSectionProps} />
        </DKTGrid>
      </DKTForm>
    </Box>
  )
}

const FormSectionDivider = ({ sectionTitle, ...rest }) => (
  <Box {...rest}>
    <DKTTypography
      variant="body2"
      color="gray.extraDark"
      sx={{ fontWeight: 'medium' }}
    >
      {sectionTitle}
    </DKTTypography>
    <Divider sx={{ mb: 3 }} />
  </Box>
)

export default FormSection
