import { useTheme } from '@emotion/react'
import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { CircledPlusIcon } from '../../../Assets/SVGs'
import {
  potentialChangeOrderTrackerHeader,
  pcoFormFields,
} from '../../../Description/trackers.description'
import useForm from '../../../Hooks/useForm'
import {
  deletePCOTrackers,
  getPCOTracker,
  savePCOTracker,
  updatePCOTracker,
} from '../../../Redux/actions/reportTrackers'
import DKTButton from '../../../Shared/DKTButton'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTCurrencyTextField from '../../../Shared/DKTCurrencyTextField'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTSelect from '../../../Shared/DKTSelect'
import DKTTextArea from '../../../Shared/DKTTextArea'
import { useStyles } from '../../../Styles/projects.style'
import { spinnerSize } from '../../../Utils/constant'
import { entries, equal, isArray, ternary } from '../../../Utils/javascript'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTTooltip from '../../../Shared/DKTTooltip'
import DKTForm from '../../../Shared/DKTForm'

// Convert string to US currency
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const PotentialChangeOrder = ({
  handleClose,
  open = true,
  hasOnlyViewPermission,
  dynamicOptions,
  payAppId,
  reportId,
  ownerContractorId,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState([])
  const { slug: projectId } = useParams()
  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)
  const [changeNotificationOpen, setChangeNotificationOpen] =
    React.useState(false)
  const [selectedPotentialChangeOrder, setSelectedPotentialChangeOrder] =
    useState(null)
  const [tempData, setTempData] = useState(null)
  const [isUnSavedChanges, setIsUnSavedChanges] = useState(false)
  const [isUnSavedWarningOpen, setIsUnSavedWarningOpen] = useState(false)
  const [sorting, setSorting] = useState({ column: null, direction: 'asc' })

  const { values, errors, handleChange, setValues, resetForm, handleSubmit } =
    useForm({
      ...pcoFormFields?.potentialChangeOrderFields,
    })

  const {
    pcoTracker,
    isFetchingPCOTracker,
    isDeletingPCOTrackers,
    isUpdatingPCOTracker,
    isSavingPCOTracker,
  } = useSelector(({ reportTrackers }) => reportTrackers)

  const removeTableRow = () => {
    setOpenDeleteNotification(true)
  }

  useEffect(() => {
    dispatch(getPCOTracker(reportId, ownerContractorId, projectId))
  }, [payAppId, reportId, ownerContractorId])

  // Pre generated table data
  useEffect(() => {
    const tableDataBody = pcoTracker?.changeOrderTrackers?.map((order) => ({
      id: order?.id,
      potentialChangeOrder: order?.potentialChangeOrder,
      amount: order?.amount,
      contractDay: order?.contractDay,
      description: order?.description,
      report: order?.report,
    }))
    setTableData(tableDataBody || [])
    setTempData(tableDataBody || [])
  }, [pcoTracker])

  useEffect(() => {
    if (selectedPotentialChangeOrder) {
      const currentPopulateValue = tableData?.find((data) =>
        equal(data?.id, selectedPotentialChangeOrder),
      )
      const { amount, potentialChangeOrder, description, contractDay } =
        currentPopulateValue || {}
      setValues({
        amount,
        potentialChangeOrder,
        description,
        contractDay: `${contractDay}`,
      })
    }
  }, [selectedPotentialChangeOrder, setValues])
  useEffect(() => {
    const res = tableData?.map(
      (value, i) => JSON.stringify(value) !== JSON.stringify(tempData[i]),
    )
    if (res.length) {
      setIsUnSavedChanges(
        ternary(
          res.some((val) => val === true),
          true,
          false,
        ),
      )
    }
  }, [selectedPotentialChangeOrder, values])

  const sortedTableData = () => {
    if (!sorting.column) return

    const sortedData = [...tableData]
    sortedData.sort((a, b) => {
      if (a[sorting.column] > b[sorting.column]) {
        return sorting.direction === 'asc' ? 1 : -1
      }
      if (a[sorting.column] < b[sorting.column]) {
        return sorting.direction === 'asc' ? -1 : 1
      }
      return 0
    })

    setTableData(sortedData)
  }
  useEffect(() => {
    if (sorting?.column) sortedTableData()
  }, [sorting])

  const handleCloseDeletePopup = () => {
    setOpenDeleteNotification(false)
  }

  const handleClosePotentialChangeOrderPopup = () => {
    setChangeNotificationOpen(false)
    resetForm()
    setSelectedPotentialChangeOrder(null)
  }

  const handlePotentialChangeOrderPopup = () => {
    setChangeNotificationOpen(true)
  }
  const handleSavePotentialChangeOrder = () => {
    const isValid = handleSubmit()
    if (isValid) {
      if (selectedPotentialChangeOrder) {
        const cloneTableData = [...tableData]
        const index = cloneTableData?.findIndex(({ id }) =>
          equal(id, selectedPotentialChangeOrder),
        )
        const updatedRow = {
          id: selectedPotentialChangeOrder,
          potentialChangeOrder: values?.potentialChangeOrder,
          amount: values?.amount || '0',
          contractDay: values?.contractDay || '0',
          description: values?.description,
          report: values?.report,
        }
        if (~index) {
          cloneTableData.splice(index, 1, updatedRow)
        }
        setTableData(cloneTableData)
        handleClosePotentialChangeOrderPopup()
      } else {
        const newData = [
          {
            id: new Date().valueOf(),
            isNewRow: true,
            potentialChangeOrder: values?.potentialChangeOrder,
            amount: values?.amount || '0',
            contractDay: values?.contractDay || '0',
            description: values?.description,
            report: reportId,
          },
        ]
        setTableData([...tableData, ...newData])
        handleClosePotentialChangeOrderPopup()
      }
    }
  }

  const handleCurrentPotentialChangeOrder = (id) => {
    setSelectedPotentialChangeOrder(id)
    handlePotentialChangeOrderPopup()
  }

  // Delete table row
  const handleDelete = (id) => {
    const body = {
      pcoTracker: [id],
    }
    const isNew = tableData?.some((row) => row?.isNewRow)
    if (isNew) {
      const handleDeleteSuccess = () => {
        handleCloseDeletePopup()
        handleClosePotentialChangeOrderPopup()
      }
      const filteredTableData = tableData?.filter(
        (data) => !equal(data?.id, id),
      )
      setTableData(filteredTableData)
      handleDeleteSuccess()
    } else {
      const handleDeleteSuccess = () => {
        handleCloseDeletePopup()
        handleClosePotentialChangeOrderPopup()
      }
      dispatch(
        deletePCOTrackers(
          body,
          reportId,
          ownerContractorId,
          handleDeleteSuccess,
          projectId,
        ),
      )
    }
  }

  const handleSort = (column) => {
    setSorting((prevSorting) => {
      if (prevSorting.column === column) {
        return {
          ...prevSorting,
          direction: prevSorting.direction === 'asc' ? 'desc' : 'asc',
        }
      }
      return { column, direction: 'asc' }
    })
  }

  // Sum total of selected column value
  const getColumnTotal = (array, columnName) =>
    array?.reduce((total, currentRow) => total + +currentRow[columnName], 0)

  // For save or update table data
  const handleSavePotentialChangeOrderTracker = () => {
    const filterTableData = tableData?.map((row) => {
      if (row?.isNewRow) {
        return {
          potentialChangeOrder: row?.potentialChangeOrder,
          amount: row?.amount,
          contractDay: row?.contractDay,
          description: row?.description,
          uniqueCode: payAppId,
          ownerContractor: ownerContractorId,
          report: reportId,
        }
      }
      return {
        ...row,
        uniqueCode: payAppId,
        ownerContractor: ownerContractorId,
      }
    })
    setIsUnSavedChanges(false)
    if (!pcoTracker) {
      dispatch(
        savePCOTracker(filterTableData, reportId, ownerContractorId, projectId),
      )
    } else {
      dispatch(
        updatePCOTracker(
          filterTableData,
          reportId,
          ownerContractorId,
          projectId,
        ),
      )
    }
  }

  const deleteNotificationAction = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={handleCloseDeletePopup}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={() => handleDelete(selectedPotentialChangeOrder)}
        disabled={isDeletingPCOTrackers}
      >
        {ternary(
          isDeletingPCOTrackers,
          <DKTCircularProgress
            size={spinnerSize?.sm}
            color={theme.palette.gray.dark}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )

  const pcoChangeOrderNotificationAction = (
    <>
      {ternary(
        selectedPotentialChangeOrder,
        <DKTButton
          variant="outlined"
          color="error"
          disableElevation
          onClick={removeTableRow}
          disabled={hasOnlyViewPermission}
        >
          Delete Potential Change Order
        </DKTButton>,
        null,
      )}
      <DKTButton
        variant="contained"
        sx={{ marginLeft: 'auto' }}
        onClick={handleSavePotentialChangeOrder}
        disabled={hasOnlyViewPermission}
      >
        Save
      </DKTButton>
    </>
  )

  const mapFormFields = ({ formFields }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            options,
            isConfirmPassword,
            isSeparate,
            separateSectionTitle,
            isPopUp,
            popupText,
            dependsOn,
            defaultValue,
            ...formField
          },
        ],
        index,
      ) => {
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        return (
          <Grid
            key={index}
            item
            lg={formField.lg ?? 10}
            xs={formField.xs ?? 12}
          >
            {ternary(
              equal(formField.type, 'textarea'),
              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Typography
                    variant="body2"
                    color="grey.extraDark"
                    sx={{ fontWeight: 'medium' }}
                  >
                    {formField.label}
                  </Typography>
                </Stack>
                <DKTTextArea
                  {...formField}
                  id={name}
                  name={name}
                  value={(values && values[name]) || defaultValue || ''}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  error={errors[name]}
                  variant="standard"
                  label=""
                  disabled={hasOnlyViewPermission || formField?.disabled}
                />{' '}
              </Box>,
              ternary(
                formField.isCurrency,
                <DKTCurrencyTextField
                  label={formField.label}
                  isRequired={isRequired}
                  variant="standard"
                  value={(values && values[name]) ?? ''}
                  error={errors[name]}
                  currencySymbol="$"
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={(event, value) =>
                    handleChange({ target: { name, value } })
                  }
                  disabled={hasOnlyViewPermission || formField?.disabled}
                />,
                <FormControl
                  {...formField}
                  options={ternary(
                    isDynamicOptions,
                    dynamicOptions[name] || [],
                    options,
                  )}
                  isRequired={isRequired}
                  id={name}
                  name={name}
                  value={(values && values[name]) || defaultValue || ''}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  error={errors[name]}
                  disabled={hasOnlyViewPermission || formField.disabled}
                />,
              ),
            )}
          </Grid>
        )
      },
    )

  const renderFormFields = (formFields) => (
    <Grid container spacing={2}>
      {isArray(formFields)
        ? formFields.map((formField) =>
            mapFormFields({
              formFields: formField.data,
              makeFieldNameUnique: true,
              id: formField.id,
            }),
          )
        : mapFormFields({ formFields })}
    </Grid>
  )
  // confirm navigate show modal functionality
  const handleCloseSaveWarningModal = () => {
    setIsUnSavedWarningOpen(false)
  }
  const confirmSaveWarningModal = () => {
    setIsUnSavedChanges(false)
    setIsUnSavedWarningOpen(false)
    handleClose()
  }
  const handleCloseChangeOrder = () => {
    if (isUnSavedChanges) {
      setIsUnSavedWarningOpen(true)
      return
    }
    handleClose()
  }

  return (
    <>
      <DKTDialog
        open={open}
        handleClose={handleCloseChangeOrder}
        title="Potential Change Order Tracker"
        maxWidth="md"
      >
        {equal(isFetchingPCOTracker, true) ? (
          <Stack alignItems="center" justifyContent="center">
            <DKTCircularProgress />
          </Stack>
        ) : equal(isFetchingPCOTracker, 'FAILED') ? (
          <Stack alignItems="center" justifyContent="center">
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching Potential Change Order
              Tracker data. Please try contacting the admin or refreshing this
              page.
            </Typography>
          </Stack>
        ) : (
          <>
            <TableContainer sx={{ maxHeight: '418px', overflow: 'auto' }}>
              <Table className={classes.commonTable} aria-label="simple table">
                <TableHead
                  sx={{ position: 'sticky', top: 0, left: 0, zIndex: 999 }}
                >
                  <TableRow>
                    {potentialChangeOrderTrackerHeader?.map(
                      (heading, index) => (
                        <TableCell
                          key={index}
                          onClick={() => handleSort(heading?.id)}
                        >
                          {heading?.header}
                          {sorting.column === heading?.id ? (
                            sorting.direction === 'asc' ? (
                              <ArrowUpwardIcon
                                sx={{
                                  fontSize: 'medium',
                                  color: 'grey',
                                  ml: '5px',
                                }}
                              />
                            ) : (
                              <ArrowDownwardIcon
                                sx={{
                                  fontSize: 'medium',
                                  color: 'grey',
                                  ml: '5px',
                                }}
                              />
                            )
                          ) : null}
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((cell, index) => (
                    <TableRow key={index}>
                      {Object?.entries(cell)?.map((data, index, ary) => {
                        if (
                          !equal(data[0], 'id') &&
                          !equal(data[0], 'isNewRow') &&
                          !equal(data[0], 'report')
                        ) {
                          return ternary(
                            equal(data[0], 'amount'),
                            <TableCell key={index}>
                              <Typography px={2}>
                                {formatter.format(data[1])}
                              </Typography>
                            </TableCell>,
                            <TableCell key={index}>
                              {equal(data[0], 'potentialChangeOrder') ? (
                                <DKTButton
                                  variant="text"
                                  color="primary"
                                  sx={{
                                    textTransform: 'initial',
                                    p: 0,
                                    height: 'auto',
                                  }}
                                >
                                  <Typography
                                    onClick={() =>
                                      handleCurrentPotentialChangeOrder(
                                        ternary(
                                          equal(ary[0][0], 'id'),
                                          ary[0][1],
                                          null,
                                        ),
                                      )
                                    }
                                  >
                                    {data[1]}
                                  </Typography>
                                </DKTButton>
                              ) : (
                                <DKTTooltip title={data[1]}>
                                  <Typography px={2}>{data[1]}</Typography>
                                </DKTTooltip>
                              )}
                            </TableCell>,
                          )
                        }
                        return null
                      })}
                    </TableRow>
                  ))}
                  {ternary(
                    !tableData?.length,
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        sx={{ fontSize: '1rem', textAlign: 'center' }}
                      >
                        Sorry, there is no matching data to display
                      </TableCell>
                    </TableRow>,
                    null,
                  )}
                  {ternary(
                    tableData?.length,
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            px: 2.2,
                            textTransform: 'initial',
                            color: 'rgba(0, 0, 0, 0.26)',
                          }}
                        >
                          {formatter.format(
                            getColumnTotal(tableData, 'amount'),
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography></Typography>
                        <Typography
                          sx={{
                            textTransform: 'initial',
                            px: 2,
                            color: 'rgba(0, 0, 0, 0.26)',
                          }}
                        >
                          {getColumnTotal(tableData, 'contractDay')}
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>,
                    null,
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack direction="row" justifyContent="space-between" mt={3}>
              <DKTButton
                variant="text"
                color="primary"
                sx={{ textTransform: 'initial', px: 1 }}
                startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
                onClick={handlePotentialChangeOrderPopup}
                disabled={hasOnlyViewPermission}
              >
                <Typography>Add Potential Change Order</Typography>
              </DKTButton>
              {ternary(
                tableData?.length,
                <DKTButton
                  disabled={
                    hasOnlyViewPermission ||
                    isFetchingPCOTracker ||
                    isUpdatingPCOTracker ||
                    isSavingPCOTracker
                  }
                  onClick={handleSavePotentialChangeOrderTracker}
                >
                  {ternary(
                    isFetchingPCOTracker ||
                      isUpdatingPCOTracker ||
                      isSavingPCOTracker,
                    <DKTCircularProgress
                      size={spinnerSize?.sm}
                      color={theme.palette.gray.dark}
                    />,
                    'Save',
                  )}
                </DKTButton>,
                null,
              )}
            </Stack>
          </>
        )}
      </DKTDialog>
      <DKTDialog
        open={deleteNotificationOpen}
        handleClose={handleCloseDeletePopup}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      <DKTDialog
        open={changeNotificationOpen}
        handleClose={handleClosePotentialChangeOrderPopup}
        title="Potential Change Order"
        actions={pcoChangeOrderNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <DKTForm autoComplete="off">
              {renderFormFields(pcoFormFields?.potentialChangeOrderFields)}
            </DKTForm>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show modal when tries to navigate without save data */}
      {!hasOnlyViewPermission && (
        <>
          <DKTConfirmNavigateShowModal
            isActive={isUnSavedWarningOpen}
            onConfirm={confirmSaveWarningModal}
            onCancel={handleCloseSaveWarningModal}
            onSave={handleSavePotentialChangeOrderTracker}
          />
          <DKTReactRouterPrompt
            isDirty={isUnSavedChanges}
            onSave={handleSavePotentialChangeOrderTracker}
          />
        </>
      )}
    </>
  )
}

export default memo(PotentialChangeOrder)
