import coverPageImage from '../Assets/projectDashboard.jpeg'

export const coverPageConfigFeed = [
  {
    id: '#Client_01_Name',
    name: 'Client01Name',
    avatar: 'CN1',
  },
  {
    id: '#Client_02_Name',
    name: 'Client02Name',
    avatar: 'CN2',
  },
  {
    id: '#Client_03_Name',
    name: 'Client03Name',
    avatar: 'CN3',
  },
  {
    id: '#Client_04_Name',
    name: 'Client04Name',
    avatar: 'CN4',
  },
  {
    id: '#Client_05_Name',
    name: 'Client05Name',
    avatar: 'CN5',
  },
  {
    id: '#Client_01_Address',
    name: 'Client01Address',
    avatar: 'CA1',
  },
  {
    id: '#Client_02_Address',
    name: 'Client02Address',
    avatar: 'CA2',
  },
  {
    id: '#Company_Name',
    name: 'CompanyName',
    avatar: 'CN',
  },
  {
    id: '#Company_Address',
    name: 'CompanyAddress',
    avatar: 'CA',
  },
  {
    id: '#Company_Phone_Number',
    name: 'CompanyPhoneNumber',
    avatar: 'CPN',
  },
  {
    id: '#Company_Website',
    name: 'Company_Website',
    avatar: 'W',
  },
  {
    id: '#Cover_Photo',
    name: 'CoverPhoto',
    avatar: 'CP',
  },
  {
    id: '#Draw_No',
    name: 'DrawNo',
    avatar: 'DN',
  },
  {
    id: '#Project_Number_Internal',
    name: 'ProjectNumberInternal',
    avatar: 'PNI',
  },
  {
    id: '#Project_Name',
    name: 'ProjectName',
    avatar: 'PN',
  },
  {
    id: '#Project_Address',
    name: 'ProjectAddress',
    avatar: 'PA',
  },
  {
    id: '#Project_Manager_Name',
    name: 'ProjectManagerName',
    avatar: 'PMN',
  },
  {
    id: '#Project_Manager_Work_Phone',
    name: 'ProjectManagerWorkPhone',
    avatar: 'PMWP',
  },
  {
    id: '#Project_Manager_Cell_Phone',
    name: 'ProjectManagerCellPhone',
    avatar: 'PMCP',
  },
  {
    id: '#Project_Manager_Email_Address',
    name: 'ProjectManagerAddress',
    avatar: 'PMA',
  },
  {
    id: '#Payment_Application_No',
    name: 'PaymentApplicationNo',
    avatar: 'PAN',
  },
  {
    id: '#Report_Number',
    name: 'Report_Number',
    avatar: 'RN',
  },
  {
    id: '#Report_Date',
    name: 'ReportDate',
    avatar: 'RD',
  },
  {
    id: '#Site_Visit_Date',
    name: 'SiteVisitDate',
    avatar: 'SVD',
  },
]

export const coverPageValues = {
  Cover_Photo: coverPageImage,
  Project_Number_Internal: '01',
  Project_Name: 'Project Name',
  Project_Address: 'Project Address, City, State, Zip Code',
  Project_Manager_Name: 'Project Manager Name',
  Project_Manager_Work_Phone: '###-###-####',
  Project_Manager_Cell_Phone: '###-###-####',
  Project_Manager_Email_Address: 'name@companyname.com',
  Payment_Application_No: '01',
  Draw_No: '01',
  Report_Number: '01',
  Report_Date: 'Jan 01, 2025',
  Site_Visit_Date: 'Jan 01, 2025',
  Client_01_Name: 'Client 01',
  Client_02_Name: 'Client 02',
  Client_03_Name: 'Client 03',
  Client_04_Name: 'Client 04',
  Client_05_Name: 'Client 05',
  Client_01_Address: 'Client 01 Address',
  Client_02_Address: 'Client 02 Address',
  Company_Name: 'Company Name',
  Company_Address: 'Company Address, City, State, Zip Code',
  Company_Phone_Number: '###-###-####',
  Company_Website: 'www.companyname.com',
}

export const coverPageToolbarItems = [
  'bold',
  'italic',
  'underline',
  '|',
  'bulletedList',
  'numberedList',
  'alignment',
  '|',
  'fontColor',
  'fontBackgroundColor',
  'fontSize',
]

export const defaultCoverPageHtmlText = `<p><span style="color:#000000;"><span class="mention" data-mention="#Project_Name">#Project_Name</span>&nbsp;</span></p><p><span style="color:#000000;"><span class="mention" data-mention="#Project_Address">#Project_Address</span>&nbsp;</span></p><p><span style="color:#000000;"><span class="mention" data-mention="#Cover_Photo">#Cover_Photo</span>&nbsp;</span></p><p><span style="color:#000000;">Construction Monitoring Report: <span class="mention" data-mention="#Report_Number">#Report_Number</span>&nbsp;</span></p><p><span style="color:#000000;">Report Date: <span class="mention" data-mention="#Report_Date">#Report_Date</span>&nbsp;</span></p><p><span style="color:#000000;">Site Visit Date: <span class="mention" data-mention="#Site_Visit_Date">#Site_Visit_Date</span>&nbsp;</span></p><p>&nbsp;</p><p><span style="color:#000000;"><span class="mention" data-mention="#Client_01_Name">#Client_01_Name</span>&nbsp;</span></p><p><span style="color:#000000;"><span class="mention" data-mention="#Client_02_Name">#Client_02_Name</span>&nbsp;</span></p><p>&nbsp;</p><p><span style="color:#000000;">Report By:</span></p><p><span style="color:#000000;"><span class="mention" data-mention="#Company_Name">#Company_Name</span>&nbsp;</span></p><p><span style="color:#000000;"><span class="mention" data-mention="#Company_Address">#Company_Address</span>&nbsp;</span></p><p><span style="color:#000000;"><span class="mention" data-mention="#Company_Phone_Number">#Company_Phone_Number</span>&nbsp;</span></p><p><span style="color:#000000;"><span class="mention" data-mention="#Website">#Website</span>&nbsp;</span></p>`
