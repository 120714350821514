import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  Stack,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Toolbar,
  alpha,
  Skeleton,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import RemoveCircleOutlineTwoToneIcon from '@mui/icons-material/RemoveCircleOutlineTwoTone'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTheme } from '@mui/styles'
import { Delete } from '@mui/icons-material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import DKTSelect from '../../../Shared/DKTSelect'
import { useStyles } from '../../../Styles/constructionSummary.style'
import { equal, keys, ternary } from '../../../Utils/javascript'
import { CircledPlusIcon, DragIcon } from '../../../Assets/SVGs'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'

import {
  SA,
  importButtonTooltip,
  publish,
  spinnerSize,
} from '../../../Utils/constant'
import DKTButton from '../../../Shared/DKTButton'
import {
  dynamicColor,
  options,
} from '../../../Description/constructionSummary.description'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTInput from '../../../Shared/DKTInput'
import DKTTooltip from '../../../Shared/DKTTooltip'
import { importPhaseBasedComments } from '../../../Redux/actions/constructionSummary'
import DKTCheckbox from '../../../Shared/DKTCheckbox'
import { showToast } from '../../../Utils/toastService'
import { ManpowerTrackerButton } from './ConstructionSummary'
import DKTEditor from '../../../Shared/DKTEditor'

const EnhancedTableToolbar = (props) => {
  const { numSelected, openDeleteRowNotification } = props

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 2, sm: 2 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        minHeight: { xs: '56px' },
      }}
    >
      {numSelected > 0 && (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      )}

      {numSelected > 0 && (
        <DKTTooltip title="Delete">
          <IconButton onClick={openDeleteRowNotification}>
            <Delete color="error" />
          </IconButton>
        </DKTTooltip>
      )}
    </Toolbar>
  )
}

const PhaseBased = (props) => {
  const {
    handleCollectDataFromChild,
    handleCollectTableStructure,
    constructionSummaryData,
    constructionSummaryFormat,
    dataForUpdate,
    memorizedPhaseBaseTable,
    isConstructionSummaryLoading,
    isFetchingConstructionSummaryFormat,
    isReportListLoading,
    setPhaseBasedFormIsDirty,
    deleteUnSaved,
    setDeleteUnSaved,
    isImportingPhaseBasedComments,
    ownerContractorId,
    reportId,
    setCkEditorChange,
    setDataForUpdate,
    constructionSummaryFeatures,
    showHideManpowerTracker,
  } = props
  const classes = useStyles()
  const { slug: projectId } = useParams()
  const theme = useTheme()
  const dispatch = useDispatch()
  const [phaseBasedHeader, setPhaseBasedHeader] = useState([
    { columnName: 'Description of Work', isShowDeleteButton: false },
    { columnName: 'Phase 1', isShowDeleteButton: false },
  ])
  const [tableData, setTableData] = useState([])
  const [deleteNotificationOpen, setOpenDeleteNotification] =
    React.useState(false)
  const [values, setValues] = useState({
    '01': { unit1: ' ' },
  })
  const [selectedColumn, setSelectedColumn] = useState(null)
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)
  const [comments, setComments] = useState(constructionSummaryData?.comments)
  const [selected, setSelected] = useState([])
  const [isDeleteRowNotificationOpen, setIsDeleteRowNotificationOpen] =
    useState(false)
  const [isLoadingAddPhase, setIsLoadingAddPhase] = useState(false)

  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { systemAuthorization, projects } = useSelector(({ auth }) => auth)
  const isProjectViewer =
    equal(systemAuthorization, SA.projectLevel) && projects[+projectId]?.viewer
  const isGlobalViewer = equal(systemAuthorization, SA.globalViewer)
  const hasOnlyViewPermission =
    isGlobalViewer ||
    isProjectViewer ||
    ternary(
      reportData?.isSecondLastReport,
      equal(reportData?.lastReportStatus, publish),
      !reportData?.isLastReport,
    )

  // Dynamic table data set and table structure set
  useEffect(() => {
    const targetArray = constructionSummaryFormat?.length
      ? constructionSummaryFormat
      : constructionSummaryFeatures
    if (keys(constructionSummaryData?.phaseBased).length > 0) {
      const { phaseBased = {} } = constructionSummaryData

      setValues(phaseBased)
      const phaseNameArray = []
      const phaseKeyArray = keys(phaseBased)
      phaseKeyArray?.forEach((phase, index) => {
        phaseNameArray[index] = keys(phaseBased[phase])
      })

      const totalNameOfPhase = phaseNameArray
        ?.flat()
        .reduce((accumulator, currentValue) => {
          const phaseForUnit = currentValue.includes('unit')
            ? `phase${currentValue.split('unit')[1]}`
            : null

          const isPhase = currentValue.includes('phase') ? currentValue : null

          return [
            ...accumulator,
            ...(phaseForUnit ? [phaseForUnit] : []),
            ...(isPhase ? [isPhase] : []),
          ]
        }, [])

      const uniquePhaseArray = [...new Set(totalNameOfPhase)].sort(
        (a, b) => a.split('phase')[1] - b.split('phase')[1],
      )

      const renderPhaseHeaderArray = uniquePhaseArray
        ?.map((phase) => {
          if (phase.includes('phase')) {
            return {
              columnName: `Phase ${phase.split('phase')[1]}`,
              isShowDeleteButton: !equal(phase, 'phase1'),
            }
          }
          return null
        })
        .filter(Boolean)

      const descriptionColumn = [
        {
          columnName: 'Description of Work',
          isShowDeleteButton: false,
        },
        { columnName: 'Phase 1', isShowDeleteButton: false },
        ...renderPhaseHeaderArray.filter(
          (name) => name.columnName !== 'Phase 1',
        ),
      ]
      setPhaseBasedHeader([...descriptionColumn])
      const unitsArray = uniquePhaseArray?.map((ele) => ({
        [`unit${ele?.split('phase')[1]}`]: ' ',
      }))
      const unitsObj = {}
      unitsArray?.forEach((unit) => {
        const [key, value] = Object.entries(unit)[0]
        unitsObj[key] = value
      })
      const isNotOldData =
        keys(constructionSummaryData?.phaseBased).length &&
        Object.values(constructionSummaryData?.phaseBased).some(
          (obj) => 'sortIndex' in obj,
        )
      const modifiedTableData = []

      keys(constructionSummaryData?.phaseBased)?.forEach((key, i) => {
        const phaseAdd = {}
        uniquePhaseArray.forEach((phaseName) => {
          phaseAdd[phaseName] = ''
        })
        if (isNotOldData) {
          if (!equal(key, '01')) {
            modifiedTableData[
              constructionSummaryData?.phaseBased[key].sortIndex ?? i
            ] = {
              id: key,
              description:
                constructionSummaryData?.phaseBased[key].description || '',
              ...constructionSummaryData?.phaseBased[key],
              ...phaseAdd,
              ...constructionSummaryData?.phaseBased[key],
            }
          } else {
            modifiedTableData[
              constructionSummaryData?.phaseBased[key].sortIndex ?? 0
            ] = {
              id: key,
              description:
                constructionSummaryData?.phaseBased[key].description || '',
              ...constructionSummaryData?.phaseBased[key],
            }
          }
        } else {
          const updatePhaseBased = {
            '01': { unit1: ' ', ...(dataForUpdate.phaseBased['01'] || {}) },
          }
          modifiedTableData[0] = {
            id: '01',
            description: '',
            ...(dataForUpdate.phaseBased['01'] || {}),
          }
          constructionSummaryFormat?.forEach((data, index) => {
            updatePhaseBased[data.id] = {
              phase1: '',
              description: data.workDescription,
              sortIndex: index + 1,
              ...(dataForUpdate.phaseBased[data.id] || {}),
            }
            modifiedTableData[index + 1] = {
              id: data?.id,
              description: data?.workDescription,
              phase1: '',
              sortIndex: index + 1,
              ...phaseAdd,
            }
          })
          handleCollectDataFromChild({
            phaseBased: { ...dataForUpdate.phaseBased, ...updatePhaseBased },
          })
        }
      })
      setTableData([...modifiedTableData])
    } else if (
      !isConstructionSummaryLoading &&
      (constructionSummaryFormat?.length || constructionSummaryFeatures?.length)
    ) {
      const updatePhaseBasedBased = { '01': { unit1: ' ' } }
      const modifiedTableData = targetArray?.map((data, index) => {
        updatePhaseBasedBased[data.id] = {
          phase1: '',
          description: data.workDescription,
          sortIndex: index + 1,
        }
        return {
          id: data?.id,
          description: data?.workDescription,
          phase1: '',
          sortIndex: index + 1,
        }
      })

      setTableData([
        ...[{ id: '01', description: '', unit1: ' ' }],
        ...modifiedTableData,
      ])
      handleCollectDataFromChild({
        phaseBased: { ...updatePhaseBasedBased, ...dataForUpdate.phaseBased },
      })
    }
  }, [
    constructionSummaryData,
    constructionSummaryFormat,
    constructionSummaryFeatures,
  ])

  // set comments
  useEffect(() => {
    setComments(constructionSummaryData?.comments)
  }, [constructionSummaryData?.comments])

  useEffect(() => {
    setCkEditorChange(comments !== constructionSummaryData?.comments)
  }, [comments])

  // set memorized table data
  useEffect(() => {
    if (dataForUpdate?.phaseBased && !values?.phaseBased) {
      setValues(dataForUpdate?.phaseBased)
      if (memorizedPhaseBaseTable) {
        const { phaseMemorizedHeader, phaseMemorizedTableStructure } =
          memorizedPhaseBaseTable
        setPhaseBasedHeader(phaseMemorizedHeader)
        setTableData(phaseMemorizedTableStructure)
      }
    }
  }, [dataForUpdate, memorizedPhaseBaseTable])
  // compare values with current and previous data
  useEffect(() => {
    const res = keys(constructionSummaryData?.phaseBased || {})?.map((val) =>
      equal(
        JSON.stringify(constructionSummaryData?.phaseBased[val]),
        JSON.stringify(values[val]),
      ),
    )
    if (
      !keys(constructionSummaryData?.phaseBased)?.length &&
      keys(dataForUpdate?.phaseBased)?.length
    ) {
      if (
        constructionSummaryFormat?.length ||
        constructionSummaryFeatures?.length
      ) {
        const targetArray = constructionSummaryFormat?.length
          ? constructionSummaryFormat
          : constructionSummaryFeatures
        setPhaseBasedFormIsDirty(
          targetArray?.length !== keys(dataForUpdate?.phaseBased).length - 1,
        )
      } else {
        setPhaseBasedFormIsDirty(!!keys(dataForUpdate?.phaseBased)?.length)
      }
    }
    if (res?.length) {
      setPhaseBasedFormIsDirty(res?.some((val) => equal(val, false)))
    }
    if (deleteUnSaved) {
      setPhaseBasedFormIsDirty(true)
    }
  }, [dataForUpdate, values])

  const handleClose = () => {
    setOpenDeleteNotification(false)
  }

  // Add new phase column
  const handleAddPhase = () => {
    setIsLoadingAddPhase(true)
    const clonePhaseBasedHeader = [...phaseBasedHeader]
    const lastPhase = clonePhaseBasedHeader
      ?.slice(-1)[0]
      ?.columnName?.replace('Phase ', '')
    const newPhaseColumn = [
      {
        columnName: `Phase ${+lastPhase + 1}`,
        isShowDeleteButton: true,
      },
    ]

    setPhaseBasedHeader([...clonePhaseBasedHeader, ...newPhaseColumn])
    const cloneTableData = [...tableData]
    const newPhaseData = cloneTableData?.map((data, index) => {
      if (equal(index, 0)) {
        return {
          ...data,
          [`unit${+lastPhase + 1}`]: ' ',
        }
      }
      return { ...data, [`phase${+lastPhase + 1}`]: '' }
    })

    setTableData((prev) => {
      const cloneTableData = [...prev]
      return cloneTableData?.map((data, index) => {
        if (equal(index, 0)) {
          return {
            ...data,
            [`unit${+lastPhase + 1}`]: ' ',
          }
        }
        return { ...data, [`phase${+lastPhase + 1}`]: '' }
      })
    })

    setValues((prevValues) => {
      const newValues = { ...prevValues }

      keys(newValues).forEach((key) => {
        if (key !== '01') {
          newValues[key][`phase${+lastPhase + 1}`] = ''
        }
      })

      newValues['01'] = {
        ...newValues['01'],
        [`unit${+lastPhase + 1}`]: ' ',
      }

      return newValues
    })

    const cloneData = { ...values }
    cloneTableData?.forEach(({ id }, index) => {
      if (equal(index, 0)) {
        cloneData[id] = {
          ...cloneData[id],
          [`unit${+lastPhase + 1}`]: ' ',
        }
      } else {
        cloneData[id] = { ...cloneData[id], [`phase${+lastPhase + 1}`]: '' }
      }
    })
    setTimeout(() => {
      addDataAfterAddPhase({
        cloneValues: {
          ...cloneData,
        },
        tableData: newPhaseData,
        phaseBasedHeader: [...clonePhaseBasedHeader, ...newPhaseColumn],
      })
      setIsLoadingAddPhase(false)
    }, 700)
  }

  const handleDelete = (columnName) => {
    setSelectedColumn(columnName)
    setOpenDeleteNotification(true)
  }

  // Remove phase column
  const handleRemoveColumn = () => {
    setDeleteUnSaved(true)
    const clonePhaseBasedHeader = [...phaseBasedHeader]
    const index = clonePhaseBasedHeader?.findIndex(({ columnName }) =>
      equal(columnName, selectedColumn),
    )
    if (~index) {
      clonePhaseBasedHeader.splice(index, 1)
    }
    setPhaseBasedHeader([...clonePhaseBasedHeader])

    // Remove table cell
    const cloneTableData = [...tableData]
    const removePhaseData = cloneTableData?.map((data) => {
      const cloneData = data
      delete cloneData[`phase${selectedColumn?.split(' ')[1]}`]
      delete cloneData[`unit${selectedColumn?.split(' ')[1]}`]
      return cloneData
    })

    setTableData(removePhaseData)

    // Remove phase value
    const cloneValues = { ...values }
    const valueKeys = keys(cloneValues)
    const modifiedValue = valueKeys?.map((data) => {
      const dataToRemove = cloneValues[data]
      delete dataToRemove[`phase${selectedColumn?.split(' ')[1]}`]
      delete dataToRemove[`unit${selectedColumn?.split(' ')[1]}`]
      return { [data]: dataToRemove }
    })
    const renderModifiedTableValue = {}
    modifiedValue?.forEach((data) => {
      const [key, value] = Object.entries(data)[0]
      renderModifiedTableValue[key] = value
    })
    setValues(renderModifiedTableValue)
    setTimeout(() => {
      addDataAfterAddPhase({
        cloneValues: renderModifiedTableValue,
        tableData: removePhaseData,
        phaseBasedHeader: [...clonePhaseBasedHeader],
      })
    }, 700)
    setSelectedColumn(null)
    handleClose()
  }

  // Track latest changes on table
  const handleChange = (event, id) => {
    const { name, value } = event.target
    const cloneValues = { ...values }
    const updatedValue = {
      [id]: { ...cloneValues?.[id], [name]: value },
    }

    setValues({ ...cloneValues, ...updatedValue })
    handleCollectDataFromChild({
      phaseBased: {
        ...dataForUpdate.phaseBased,
        ...{
          [id]: { ...dataForUpdate.phaseBased[id], ...updatedValue[id] },
        },
      },
    })
    handleCollectTableStructure({
      phaseMemorizedTableStructure: tableData,
      phaseMemorizedHeader: phaseBasedHeader,
    })
  }
  /* IMPORT FROM LAST REPORT */
  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)

  const closeDeleteRowNotification = () => setIsDeleteRowNotificationOpen(false)
  const openDeleteRowNotification = () => setIsDeleteRowNotificationOpen(true)

  const handleImport = useCallback(() => {
    if (comments) {
      showImportWarning()
      return
    }
    dispatch(
      importPhaseBasedComments({
        projectId,
        ocId: ownerContractorId,
        onSuccess: (value) => setComments(value),
        reportId,
      }),
    )
  }, [projectId, ownerContractorId, dispatch, comments])

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = tableData
        ?.filter((data) => data.id !== '01')
        .map((data) => data.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleRowSelect = (event, id) => {
    const clickedElement = event.target
    const isCheckboxClicked =
      clickedElement.tagName === 'INPUT' && clickedElement.type === 'checkbox'
    if (isCheckboxClicked) {
      const selectedIndex = selected.indexOf(id)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      setSelected(newSelected)
    }
  }

  const handleAddDescription = () => {
    const id = new Date().valueOf()
    const sortIndex = keys(dataForUpdate.phaseBased)?.length
    const allKeys = phaseBasedHeader.filter((key) =>
      key.columnName.includes('Phase'),
    )

    const phaseObj = {}
    allKeys.forEach((item) => {
      const lastPhaseNumber = +item.columnName.match(/\d+/)[0]
      phaseObj[`phase${lastPhaseNumber}`] = ''
    })

    const cloneTableData = [...tableData]
    if (!cloneTableData.length) {
      cloneTableData.unshift({ id: '01', description: '', unit1: ' ' })
    }
    cloneTableData.push({
      id,
      description: '',
      sortIndex,
      ...phaseObj,
    })

    setTableData(cloneTableData)
    handleCollectTableStructure({
      phaseMemorizedTableStructure: cloneTableData,
      phaseMemorizedHeader: phaseBasedHeader,
    })
    handleCollectDataFromChild({
      phaseBased: {
        ...(!sortIndex && { '01': { unit1: ' ' } }),
        ...dataForUpdate.phaseBased,
        [id]: {
          description: '',
          sortIndex: sortIndex || 1,
          ...phaseObj,
        },
      },
    })
  }

  const handleDeleteRow = () => {
    if (selected.length > 0) {
      let cloneValues = { ...values }
      const cloneTableData = tableData.filter(
        ({ id }) => !selected.includes(id),
      )
      let clonePhaseValue = {}
      selected.forEach((key) => {
        delete cloneValues[key]
      })

      cloneTableData.forEach(({ id }, index) => {
        cloneValues = {
          ...cloneValues,
          [id]: {
            ...cloneValues[id],
            ...dataForUpdate.phaseBased[id],
            sortIndex: index,
          },
        }
        clonePhaseValue = {
          ...clonePhaseValue,
          [id]: {
            ...cloneValues[id],
            sortIndex: index,
          },
        }
      })
      handleCollectTableStructure({
        phaseMemorizedTableStructure: cloneTableData,
        phaseMemorizedHeader: phaseBasedHeader,
      })
      setTableData(cloneTableData)
      setDataForUpdate({ ...dataForUpdate, phaseBased: clonePhaseValue })
      setValues({ ...cloneValues })
    }
    setSelected([])
    closeDeleteRowNotification()
  }

  const deleteRowNotificationActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={closeDeleteRowNotification}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDeleteRow}>
        Yes
      </DKTButton>
    </>
  )

  const onDragEnd = (result) => {
    if (!result.destination.index) {
      showToast('Cannot Re-arrange item to the top of the table')
      return
    }
    if (result.destination && result.source) {
      const clonedData = [...tableData]
      clonedData.splice(result.source.index, 1)
      clonedData.splice(
        result.destination.index,
        0,
        tableData[result.source.index],
      )
      let clonePhaseBasedData = { ...dataForUpdate.phaseBased }
      clonedData.forEach(({ id }, index) => {
        clonePhaseBasedData = {
          ...clonePhaseBasedData,
          [id]: {
            ...clonePhaseBasedData[id],
            sortIndex: index,
          },
        }
      })

      const clonedTableData = [...tableData]
      clonedTableData.splice(result.source.index, 1)
      clonedTableData.splice(
        result.destination.index,
        0,
        tableData[result.source.index],
      )
      setTableData(clonedTableData)
      handleCollectTableStructure({
        phaseMemorizedTableStructure: clonedTableData,
        phaseMemorizedHeader: phaseBasedHeader,
      })
      handleCollectDataFromChild({
        phaseBased: clonePhaseBasedData,
      })
    }
  }

  const handleImportOverWrite = () => {
    const onSuccess = (value) => {
      setComments(value)
      hideImportWarning()
    }
    dispatch(
      importPhaseBasedComments({
        projectId,
        ocId: ownerContractorId,
        onSuccess,
        reportId,
      }),
    )
  }

  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={isImportingPhaseBasedComments}
      >
        {ternary(
          isImportingPhaseBasedComments,
          <DKTCircularProgress
            size={spinnerSize?.sm}
            {...ternary(
              isImportingPhaseBasedComments,
              { color: theme.palette.gray.dark },
              {},
            )}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  const addDataAfterAddPhase = ({
    cloneValues,
    tableData,
    phaseBasedHeader,
  }) => {
    if (cloneValues) {
      handleCollectDataFromChild({
        phaseBased: { ...cloneValues },
      })
    }
    handleCollectTableStructure({
      phaseMemorizedTableStructure: tableData,
      phaseMemorizedHeader: phaseBasedHeader,
    })
  }

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleRemoveColumn}>
        Yes
      </DKTButton>
    </>
  )
  const [showSelect, setShowSelect] = useState(false)

  useEffect(() => {
    let timer = null
    if (tableData?.length) {
      timer = setTimeout(() => setShowSelect(true), 500)
    }
    return () => clearTimeout(timer)
  }, [tableData])
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <ManpowerTrackerButton
            showHideManpowerTracker={showHideManpowerTracker}
            sx={{ marginBottom: 1.475 }}
          />
          {!!selected.length && (
            <EnhancedTableToolbar
              numSelected={selected.length}
              openDeleteRowNotification={openDeleteRowNotification}
            />
          )}
          <DragDropContext onDragEnd={onDragEnd}>
            <TableContainer>
              <div className={classes.overflowTable}>
                <Table
                  className={classes.commonTable}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          border: 'none',
                        }}
                      ></TableCell>
                      <TableCell>
                        <DKTCheckbox
                          color="primary"
                          checked={tableData.length > 0 && selected.length > 0}
                          onChange={handleSelectAllClick}
                          inputProps={{
                            'aria-label': 'select all desserts',
                          }}
                          disabled={hasOnlyViewPermission}
                        />
                      </TableCell>

                      {phaseBasedHeader?.map((heading, index) =>
                        ternary(
                          heading?.isShowDeleteButton,
                          <TableCell
                            key={index}
                            sx={{
                              padding: ' 0 0 0 10px',
                              height: '40px',
                              border: 'none',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {heading?.columnName}
                            {!hasOnlyViewPermission && (
                              <IconButton
                                sx={{
                                  margin: '0 0 0 16px',
                                  padding: 0,
                                }}
                                onClick={() =>
                                  handleDelete(heading?.columnName)
                                }
                              >
                                <RemoveCircleOutlineTwoToneIcon
                                  color="primary"
                                  sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '50%',
                                    fontSize: '18px',
                                  }}
                                />
                              </IconButton>
                            )}
                          </TableCell>,
                          <TableCell
                            key={index}
                            sx={{
                              padding: ' 0 0 0 10px',
                              height: '40px',
                              border: 'none',
                              whiteSpace: 'nowrap',
                              minWidth: 85,
                            }}
                          >
                            {heading?.columnName}
                          </TableCell>,
                        ),
                      )}
                    </TableRow>
                  </TableHead>
                  <Droppable droppableId="phaseBased">
                    {(provided) => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {tableData?.map((cell, index) => {
                          const isItemSelected =
                            selected.indexOf(cell?.id) !== -1
                          const labelId = `enhanced-table-checkbox-${index}`
                          return (
                            <Draggable
                              key={cell.id}
                              draggableId={cell?.id.toString()}
                              index={index}
                              isDragDisabled={equal(index, 0)}
                            >
                              {(provided) => (
                                <TableRow
                                  className={classes.tableSize}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {equal(cell?.id, '01') ? (
                                    <>
                                      <TableCell />
                                      <TableCell />
                                    </>
                                  ) : (
                                    <>
                                      <TableCell>
                                        <Box className={classes.dragIcon}>
                                          {!hasOnlyViewPermission && (
                                            <DragIcon />
                                          )}
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <DKTCheckbox
                                          color="primary"
                                          checked={isItemSelected}
                                          onChange={(e) =>
                                            handleRowSelect(e, cell.id)
                                          }
                                          inputProps={{
                                            'aria-labelledby': labelId,
                                          }}
                                          disabled={hasOnlyViewPermission}
                                        />
                                      </TableCell>
                                    </>
                                  )}
                                  {Object?.keys(cell)
                                    .sort((a, b) => {
                                      const numA =
                                        parseInt(a.match(/\d+/), 10) || 0
                                      const numB =
                                        parseInt(b.match(/\d+/), 10) || 0
                                      return numA - numB
                                    })
                                    ?.map((data) => {
                                      const cellId = cell?.id
                                      if (
                                        !equal(data, 'id') &&
                                        !equal(data, 'sortIndex') &&
                                        !equal(data, 'descriptionOfWork')
                                      ) {
                                        return ternary(
                                          data.startsWith('phase'),
                                          <TableCell sx={{ paddingBlock: 0.5 }}>
                                            {ternary(
                                              showSelect,
                                              <DKTSelect
                                                displayEmpty
                                                name={data}
                                                id={cellId}
                                                value={
                                                  values[cellId]
                                                    ? values[cellId][data]
                                                    : ''
                                                }
                                                placeholder="Select"
                                                showLabel={false}
                                                className={
                                                  classes.stateDropdown
                                                }
                                                options={options}
                                                onChange={(event) =>
                                                  handleChange(event, cellId)
                                                }
                                                selectSx={
                                                  values[cellId]
                                                    ? {
                                                        backgroundColor: `${
                                                          dynamicColor[
                                                            values[cellId][data]
                                                          ]
                                                        }`,
                                                        color: ternary(
                                                          equal(
                                                            dynamicColor[
                                                              values[cellId][
                                                                data
                                                              ]
                                                            ],
                                                            '#1564FF',
                                                          ),
                                                          'white',
                                                          '#333333',
                                                        ),
                                                      }
                                                    : {}
                                                }
                                                expandIconColor={ternary(
                                                  values[cellId] &&
                                                    equal(
                                                      dynamicColor[
                                                        values[cellId][data]
                                                      ],
                                                      dynamicColor['2'],
                                                    ),
                                                  'white',
                                                  '#333333',
                                                )}
                                                formControlProps={{
                                                  sx: { height: '45px' },
                                                }}
                                                disabled={hasOnlyViewPermission}
                                              />,
                                              <Skeleton
                                                variant="text"
                                                sx={{ ml: 1.2 }}
                                                height={35}
                                              />,
                                            )}
                                          </TableCell>,
                                          data.includes('unit') ? (
                                            <TableCell>
                                              <DKTInput
                                                placeholder={ternary(
                                                  hasOnlyViewPermission,
                                                  '',
                                                  'Bldg./Floor/Unit',
                                                )}
                                                type="text"
                                                name={data}
                                                id={cellId}
                                                value={
                                                  values[cellId]
                                                    ? values[cellId][
                                                        data
                                                      ].trimStart()
                                                    : ''
                                                }
                                                className={
                                                  classes.summaryTypeInput
                                                }
                                                onChange={(event) =>
                                                  handleChange(event, cellId)
                                                }
                                                disabled={hasOnlyViewPermission}
                                              />
                                            </TableCell>
                                          ) : (
                                            <TableCell>
                                              <DKTInput
                                                type="text"
                                                name={[data]}
                                                className={classes.input}
                                                value={
                                                  (values[cellId]?.[data] ??
                                                    cell.data) ||
                                                  ''
                                                }
                                                onChange={(event) =>
                                                  handleChange(event, cellId)
                                                }
                                                disabled={hasOnlyViewPermission}
                                              />
                                            </TableCell>
                                          ),
                                        )
                                      }
                                      return null
                                    })}
                                </TableRow>
                              )}
                            </Draggable>
                          )
                        })}
                      </TableBody>
                    )}
                  </Droppable>
                </Table>
              </div>
            </TableContainer>
          </DragDropContext>
        </Grid>

        <Grid item xs={2}>
          <DKTButton
            variant="text"
            onClick={handleAddPhase}
            sx={{
              height: '40px',
            }}
            startIcon={
              isLoadingAddPhase ? (
                <DKTCircularProgress size={spinnerSize?.sm} />
              ) : (
                <CircledPlusIcon
                  disabled={
                    hasOnlyViewPermission ||
                    isConstructionSummaryLoading ||
                    isFetchingConstructionSummaryFormat ||
                    isReportListLoading ||
                    !tableData.length
                  }
                />
              )
            }
            disabled={
              hasOnlyViewPermission ||
              isConstructionSummaryLoading ||
              isFetchingConstructionSummaryFormat ||
              isReportListLoading ||
              isLoadingAddPhase ||
              !tableData.length
            }
          >
            Add
          </DKTButton>
        </Grid>
      </Grid>
      <DKTButton
        variant="text"
        color="primary"
        className={classes.addDesc}
        startIcon={<CircledPlusIcon disabled={hasOnlyViewPermission} />}
        onClick={handleAddDescription}
        disabled={hasOnlyViewPermission}
      >
        Add Description
      </DKTButton>
      {/* Comments */}
      <Grid item xs={12} lg={10} mt={8} sx={{ mt: '25px' }}>
        <Stack
          direction="row"
          alignItems="end"
          justifyContent="space-between"
          mb={1}
        >
          <Typography
            variant="body2"
            color="gray.extraDark"
            sx={{ fontWeight: 'medium' }}
          >
            Comments
          </Typography>

          <DKTTooltip title={importButtonTooltip}>
            <Box>
              <DKTButton
                variant="outlined"
                onClick={() => handleImport('comments')}
                sx={{
                  borderColor: '#ACACAC',
                  width: '91px',
                  height: '32px',
                  color: '#2D2D2D',
                  '&:hover': {
                    borderColor: '#000',
                  },
                }}
                disabled={
                  hasOnlyViewPermission || isImportingPhaseBasedComments
                }
              >
                {ternary(
                  isImportingPhaseBasedComments,
                  <DKTCircularProgress color={theme.palette.gray.dark} />,
                  'Import',
                )}
              </DKTButton>
            </Box>
          </DKTTooltip>
        </Stack>
        <DKTEditor
          value={comments || ''}
          onChange={(event, editor) => {
            const data = editor.getData()
            setComments(data)
            handleCollectDataFromChild({
              comments: data,
            })
          }}
          disabled={hasOnlyViewPermission}
          className={classes.projectDesc}
        />
      </Grid>
      <DKTDialog
        open={deleteNotificationOpen}
        handleClose={handleClose}
        title="&nbsp;"
        actions={deleteNotificationAction}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                {`Are you sure you want to delete ${selectedColumn}?`}
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* Import content over-write warning */}
      <DKTDialog
        open={isImportWarningOpen}
        handleClose={hideImportWarning}
        title="&nbsp;"
        actions={importWarningActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Existing data will be overwritten.
              </Typography>
              <Typography variant="body1">Continue?</Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {/* show warning modal when Delete a row */}
      <DKTDialog
        open={isDeleteRowNotificationOpen}
        handleClose={closeDeleteRowNotification}
        title="&nbsp;"
        actions={deleteRowNotificationActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </>
  )
}

export default PhaseBased
