export const g703Columns = [
  {
    name: 'id',
    options: {
      sort: false,
      display: false,
      filter: false,
      empty: true,
      viewColumns: false,
    },
  },
  {
    name: 'rowNumber',
    label: '#',
    options: {
      sort: false,
    },
  },
  {
    name: 'descriptionOfWork',
    label: 'Description of Work',
    options: {
      sort: false,
    },
  },
  {
    name: 'original',
    label: 'Original',
    options: {
      sort: false,
    },
  },
  {
    name: 'current',
    label: 'Current (A)',
    options: {
      sort: false,
    },
  },
  {
    name: 'thisPeriod',
    label: 'This Period (B)',
    options: {
      sort: false,
    },
  },
  {
    name: 'recommended',
    label: 'Recommended (C)',
    options: {
      sort: false,
    },
  },
  {
    name: 'variance',
    label: 'Variance (B-C)',
    options: {
      sort: false,
    },
  },
  {
    name: 'completedStored',
    label: 'Completed + Stored (D)',
    options: {
      sort: false,
    },
  },
  {
    name: 'da',
    label: 'D/A %',
    options: {
      sort: false,
    },
  },
]
export const g703Options = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}
export const initialG703Tracker = {
  rowNumber: '01',
  descriptionOfWork: '',
  original: 0,
  current: 0,
  thisPeriod: 0,
  recommended: 0,
  variance: 0,
  completedStored: 0,
  da: 0,
}
export const g703InitialTotal = {
  original: 0,
  current: 0,
  thisPeriod: 0,
  recommended: 0,
  variance: 0,
  completedStored: 0,
  da: 0,
}

// Lien Waiver Tracker
export const lienWaiverColumns = [
  {
    name: 'issuerName',
    label: 'Issuer Name',
    options: {
      sort: false,
    },
  },
  {
    name: 'issuerType',
    label: 'Issuer Type',
    options: {
      sort: false,
    },
  },
  {
    name: 'conditionalAmount',
    label: 'Conditional Amount',
    options: {
      sort: false,
    },
  },
  {
    name: 'conditionalAmountDateReceived',
    label: 'Date',
    options: {
      sort: false,
    },
  },
  {
    name: 'unconditionalAmount',
    label: 'Unconditional Amount',
    options: {
      sort: false,
    },
  },
  {
    name: 'unconditionalAmountDateReceived',
    label: 'Date',
    options: {
      sort: false,
    },
  },
]
export const initialLienWaiversTracker = {
  issuerName: '',
  issuerType: '',
  conditionalAmount: 0,
  conditionalAmountDateReceived: null,
  unconditionalAmount: 0,
  unconditionalAmountDateReceived: null,
}
export const lienWaiverOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}
export const lienWaiverChoices = ['issuerType']

// Stored material tracker

export const storedMaterialColumns = [
  {
    name: 'description',
    label: 'Description',
    options: {
      sort: false,
    },
  },
  {
    name: 'location',
    label: 'Type',
    options: {
      sort: false,
    },
  },
  {
    name: 'amount',
    label: 'Amount',
    options: {
      sort: false,
    },
  },
  {
    name: 'documentationReceived',
    label: 'Documentation Received',
    options: {
      sort: false,
    },
  },
]
export const initialStoredMaterial = {
  description: '',
  location: '',
  amount: 0,
  documentationReceived: '',
}
export const storedMaterialOptions = {
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}
export const storedMaterialChoices = ['location']

// Change order tracker

export const changeOrderTrackerHeader = [
  {
    id: 'changeOrderNumber',
    header: 'CO #',
  },
  {
    id: 'date',
    header: 'Date',
  },
  {
    id: 'status',
    header: 'Status',
  },
  {
    id: 'amount',
    header: 'Amount',
  },
  {
    id: 'contractDay',
    header: 'Contract Days (+/-)',
  },
  {
    id: 'description',
    header: 'Description',
  },
]

export const coFormFields = {
  changeOrderFields: {
    changeOrderNumber: {
      type: 'select',
      label: 'Change Order Number',
      placeholder: 'Select',
      isDynamicOptions: true,
      displayEmpty: true,
      lg: 6,
      isRequired: true,
    },
    date: {
      type: 'date',
      label: 'Date ',
      xs: 6,
      lg: 6,
    },
    amount: {
      type: 'text',
      label: 'Amount',
      isCurrency: true,
      decimalPlaces: 2,
      xs: 6,
      lg: 6,
    },
    contractDay: {
      type: 'number',
      label: 'Contract Days (+/-)',
      xs: 6,
      lg: 6,
    },
    description: {
      type: 'textarea',
      label: 'Description',
      placeholder: 'Placeholder Text',
      xs: 12,
      lg: 12,
      rows: 10,
    },
  },
}
// Potential Change order tracker

export const potentialChangeOrderTrackerHeader = [
  {
    id: 'potentialChangeOrder',
    header: 'PCO #',
  },
  {
    id: 'amount',
    header: 'Amount',
  },
  {
    id: 'contractDay',
    header: 'Contract Days (+/-)',
  },
  {
    id: 'description',
    header: 'Description',
  },
]

export const pcoFormFields = {
  potentialChangeOrderFields: {
    potentialChangeOrder: {
      type: 'text',
      label: 'Potential Change Order Number',
      xs: 12,
      lg: 12,
      isRequired: true,
      infoIcon: `Enter number or “TBD”`,
    },
    amount: {
      type: 'text',
      label: 'Amount',
      isCurrency: true,
      decimalPlaces: 2,
      xs: 6,
      lg: 6,
    },
    contractDay: {
      type: 'number',
      label: 'Contract Days (+/-)',
      xs: 6,
      lg: 6,
    },
    description: {
      type: 'textarea',
      label: 'Description',
      placeholder: 'Placeholder Text',
      xs: 12,
      lg: 12,
      rows: 10,
    },
  },
}

// MilestoneTracker
export const milestoneTrackerColumns = [
  '',
  'Milestone Name',
  'Start',
  'Completion',
  'Description',
]
export const milestoneTrackerColumnKeys = [
  'milestoneName',
  'start',
  'completion',
  'description',
]

// G702 Split tracker
export const g702SplitColumns = [
  {
    name: 'contracts',
    label: 'Contracts',
    options: {
      sort: false,
    },
  },
  {
    name: '01',
    label: '#01',
    options: {
      sort: false,
    },
  },
  {
    name: '02',
    label: '#02',
    options: {
      sort: false,
    },
  },
  {
    name: '03',
    label: '#03',
    options: {
      sort: false,
    },
  },
  {
    name: 'total',
    label: 'Total',
    options: {
      sort: false,
    },
  },
]

export const g702SplitContracts = [
  { label: 'Description', name: 'description' },
  { label: 'Payment Application #', name: 'payApplicationNumber' },
  { label: 'Period To', name: 'periodTo' },
  { label: '1. Contract Sum', name: 'contractSum' },
  { label: '2. Net Change By Change Orders', name: 'netChangeByChangeOrders' },
  { label: '3. Contract Sum To Date(1+2)', name: 'contractSumToDate' },
  { label: '4. Total Completed + Stored', name: 'totalCompletedStored' },
  { label: '5. Retainage', name: 'retainage' },
  {
    label: '6. Total Earned Less Retainage (4-5)',
    name: 'totalEarnedLessRetainage',
  },
  { label: '7. Less Previous Disbursement', name: 'lessPreviousDisbursements' },
  { label: '8. Current Payment Due (6-7)', name: 'currentPaymentDue' },
  { label: '8a. Sales Tax', name: 'salesTax' },
  {
    label: '8. Current Payment Due + Sales Tax (8+8a)',
    name: 'currentPaymentSalesTax',
  },
  {
    label: '9. Balance to Finish, incl. Retainage (3-6)',
    name: 'balanceToFinishPlusRetainage',
  },
  { label: 'Percent Complete', name: 'percentComplete' },
]
