import { Box, Grid, Stack, Typography } from '@mui/material'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  choiceFields,
  formFields,
  optionFields,
} from '../../../Description/contingency.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  getContingency,
  getContingencyReferenceValues,
  saveContingency,
  saveContingencySuccess,
} from '../../../Redux/actions/contingency'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import DKTButton from '../../../Shared/DKTButton'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import { useStyles } from '../../../Styles/contingency.style'
import {
  checkUndefined,
  entries,
  equal,
  handleBlurAll,
  handleTextEditor,
  isArray,
  notNull,
  ternary,
} from '../../../Utils/javascript'
import { showToast } from '../../../Utils/toastService'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { CLEAR_CONTINGENCY_REDUCER } from '../../../Redux/constants/contingency'
import usePermission from '../../../Hooks/usePermission'
import FormSection from './FormSection'
import ImportCommentModal from './ImportCommentModal'

const mergedFormFields = entries(formFields).reduce((result, formField) => {
  const formFieldValue = formField[1]
  return {
    ...result,
    ...formFieldValue,
  }
}, {})

const Contingency = () => {
  const [ownerContractorId, setOwnerContractorId] = useState(null)
  const [ownerContractorUniqueCode, setOwnerContractorUniqueCode] =
    useState(null)
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)

  const { slug: projectId, reportId } = useParams()
  const dispatch = useDispatch()
  const classes = useStyles()
  const importFieldName = useRef(null)
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices } = useSelector(({ reportChoices }) => reportChoices)

  const {
    contingency,
    contingencyReference,
    contingencyError,
    isSavingContingency,
    isFetchingContingencyReferenceValues,
    isFetchingContingency,
  } = useSelector(({ contingency }) => contingency)
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    setErrors,
    setIsDirty,
    setDummyData,
    isDirty,
  } = useForm(mergedFormFields, CLEAR_CONTINGENCY_REDUCER)
  const { hasOnlyViewPermission } = usePermission()

  // set dummyData for check form is dirty or not
  useEffect(() => {
    if (!contingency) {
      setDummyData((prevValues) => ({
        ownerContractorDescription: prevValues.ownerContractorDescription,
        contractSum: '0',
        ...contingencyReference,
      }))
      return
    }
    setDummyData((prevValues) => ({
      ...prevValues,
      contractSum: '0',
      ...contingencyReference,
      ...contingency,
    }))
  }, [contingency, contingencyReference])
  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionFields?.join(','),
        projectId,
      }),
    )
    dispatch(getProjectChoices({ fieldName: choiceFields.join(',') }))
  }, [dispatch, projectId])
  useEffect(() => {
    if (choices) {
      const dynamicDropdown = (ary) => {
        const res = ary?.map((element) => ({
          label: element?.uniqueCode || element?.projectName,
          value: `${element?.id}`,
        }))
        return res
      }
      const ownerContractor = dynamicDropdown(choices?.ownerContractor)
      setDynamicOptions({
        ...choices,
        ownerContractor,
      })
    }
  }, [choices])

  useEffect(() => {
    let activeContractor = null
    if (isArray(choices?.ownerContractor) && choices?.ownerContractor?.length) {
      activeContractor = choices?.ownerContractor[0]
    }
    setOwnerContractorId(activeContractor?.id)
    setOwnerContractorUniqueCode(activeContractor?.uniqueCode)
  }, [choices?.ownerContractor[0]?.id])

  useEffect(() => {
    const activeContractorDescription = choices?.ownerContractor?.find((list) =>
      equal(list?.id, ownerContractorId),
    )?.description
    setFieldValue(
      'ownerContractorDescription',
      activeContractorDescription,
      'contingency',
    )
    if (reportId && ownerContractorId)
      dispatch(getContingency(reportId, ownerContractorId))

    if (projectId && reportId && ownerContractorId) {
      dispatch(
        getContingencyReferenceValues({
          projectId,
          reportId,
          ocId: ownerContractorId,
        }),
      )
    }
  }, [dispatch, ownerContractorId])

  // Set errors on save
  useEffect(() => {
    if (contingencyError) {
      const errorData = { ...errors }
      Object.entries(contingencyError)?.forEach(([errorName, [errorDesc]]) => {
        errorData[errorName] = errorDesc
      })
      setErrors((prev) => ({
        ...prev,
        ...errorData,
      }))
    }
  }, [contingencyError, setErrors])
  // contingencyError removed for temporary

  // VALUES TO CALCULATE INITIALLY
  /* 
    1. hccPerOfContractSum 
  */

  // CALCULATION

  const calculatePercentage = useCallback(
    (numerator, denominator) =>
      denominator !== '0' &&
      denominator !== 0 &&
      denominator !== null &&
      denominator !== undefined
        ? ((+numerator ?? 0) / (+denominator ?? 0)) * 100
        : 0,
    [],
  )

  useEffect(() => {
    setFieldValue(
      'hccPerOfContractSum',
      calculatePercentage(values.hardCostContingency, values.contractSum),
      'contingency',
    )
  }, [values.contractSum])
  useEffect(() => {
    // Calculate HCC % of contract sum
    setFieldValue(
      'revisedHccPerOfContractSum',
      calculatePercentage(values.revisedHcc, values.contractSum),
      'contingency',
    )
    // Calculate Remaining HCC (after CO’s)
    if (equal(values.useValueFromBudgetSummaryCo, 'YES'))
      setFieldValue(
        'remainingHcc',
        parseFloat((+values.revisedHcc ?? 0) - (+values.coTotal ?? 0))?.toFixed(
          2,
        ),
        'contingency',
      )
    // Calculate Remaining (after CO’s and PCOs)
    if (equal(values.useValueFromBudgetSummaryCoAndPco, 'YES'))
      setFieldValue(
        'remaining',
        parseFloat(
          (+values.revisedHcc ?? 0) -
            ((+values.coTotal ?? 0) + (+values.pcoTotal ?? 0)),
        )?.toFixed(2),
        'contingency',
      )
  }, [
    values.revisedHcc,
    values.contractSum,
    values.useValueFromBudgetSummaryCo,
    values.useValueFromBudgetSummaryCoAndPco,
    values.coTotal,
    values.pcoTotal,
  ])
  useEffect(() => {
    // Calculate Remaining HCC (after CO’s)
    if (equal(values.useValueFromBudgetSummaryCo, 'YES'))
      setFieldValue(
        'remainingHcc',
        parseFloat((+values.revisedHcc ?? 0) - (+values.coTotal ?? 0))?.toFixed(
          2,
        ),
        'contingency',
      )
  }, [values.useValueFromBudgetSummaryCo, values.revisedHcc, values.coTotal])
  // Calculate Remaining (after CO’s and PCOs) % of Revised HCC
  useEffect(() => {
    setFieldValue(
      'remainingHccPerOfRevisedHcc',
      calculatePercentage(values.remainingHcc, values.revisedHcc),
      'contingency',
    )
  }, [values.remainingHcc])
  // Calculate Remaining HCC (after CO’s) % of Revised HCC
  useEffect(() => {
    setFieldValue(
      'remainingPerOfRevisedHcc',
      calculatePercentage(values.remaining, values.revisedHcc),
      'contingency',
    )
  }, [values.remaining])
  useEffect(() => {
    // Calculate Remaining (after CO’s and PCOs)
    if (equal(values.useValueFromBudgetSummaryCoAndPco, 'YES'))
      setFieldValue(
        'remaining',
        parseFloat(
          (+values.revisedHcc ?? 0) -
            ((+values.coTotal ?? 0) + (+values.pcoTotal ?? 0)),
        ).toFixed(2),
        'contingency',
      )
  }, [values.useValueFromBudgetSummaryCoAndPco])

  // Right side calculation

  // Calculate Soft Cost Contingency (% of Contract Sum)
  useEffect(() => {
    setFieldValue(
      'sccPerOfContractSum',
      calculatePercentage(values.softCostContingency, values.contractSum),
      'contingency',
    )
  }, [values.softCostContingency])
  // Calculate Revised SCC (By Reallocations) (% of Contract Sum)
  useEffect(() => {
    setFieldValue(
      'revisedSccPerOfContractSum',
      calculatePercentage(values.revisedScc, values.contractSum),
      'contingency',
    )
  }, [values.revisedScc])
  // Calculate Remaining Soft Cost Contingency (% of Contract Sum)
  useEffect(() => {
    setFieldValue(
      'remainingSccPerOfRevisedScc',
      calculatePercentage(values.remainingScc, values.revisedScc),
      'contingency',
    )
  }, [values.remainingScc, values.revisedScc])
  // Reset contingency on unmount
  useEffect(() => () => dispatch(saveContingencySuccess([])), [])

  useEffect(() => {
    if (
      equal(values?.useValueFromBudgetSummaryCoAndPco, 'YES') &&
      (checkUndefined(contingencyReference?.coTotal) ||
        !notNull(contingencyReference?.coTotal)) &&
      !isFetchingContingencyReferenceValues
    ) {
      showToast('Budget summary needs to be created first')
    }
  }, [values?.useValueFromBudgetSummaryCoAndPco, contingencyReference])

  // Prefill contingency form fields
  useEffect(() => {
    if (!contingency) {
      setValues((prevValues) => ({
        ownerContractorDescription: prevValues.ownerContractorDescription,
        contractSum: '0',
        ...contingencyReference,
      }))
      return
    }
    setValues((prevValues) => ({
      ...prevValues,
      contractSum: '0',
      ...contingencyReference,
      ...contingency,
    }))
  }, [contingency, contingencyReference])

  // Calculate Original GC Contingency (% of Contract Sum)
  useEffect(() => {
    setFieldValue(
      'contractorPerOfContractSum',
      calculatePercentage(
        values.originalContractorContingency,
        values.contractSum,
      ),
      'contingency',
    )
  }, [values.contractSum, values.originalContractorContingency])

  // Calculate Remaining GC Contingency (% of Original GC Contingency)
  useEffect(() => {
    setFieldValue(
      'originalContractorPerOfContractSum',
      calculatePercentage(
        values.remainingContractorContingency,
        values.originalContractorContingency,
      ),
      'contingency',
    )
  }, [
    values.remainingContractorContingency,
    values.originalContractorContingency,
  ])

  /* IMPORT FROM LAST REPORT */
  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)

  const handleSave = () => {
    const isFormValid = handleSubmit({ comp: 'Company Information' })

    const cloneValue = {
      ...values,
      isPrintGcContingency: !!values?.isPrintGcContingency ?? false,
      isPrintSoftCostContingency: !!values?.isPrintSoftCostContingency ?? false,
    }
    if (!cloneValue?.originalContractorContingency) {
      setFieldValue('originalContractorContingency', 0, 'contingency')
      cloneValue.originalContractorContingency =
        values?.originalContractorContingency || 0
    }

    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    setIsDirty(false)
    dispatch(
      saveContingency({
        payload: cloneValue,
        reportId,
        projectId,
        ocId: ownerContractorId,
        ownerContractorUniqueCode,
      }),
    )
  }

  const actions = () => (
    <>
      <Box sx={{ minWidth: '228px' }}>
        <Typography
          component="h6"
          fontSize={20}
          fontWeight={700}
          textAlign="left"
          textTransform="capitalize"
        >
          Report #{reportData?.overrideReportCode}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" gap={2}>
          <DKTButtonSelect
            options={actionList}
            disabled={
              hasOnlyViewPermission ||
              isFetchingContingencyReferenceValues ||
              isFetchingContingency ||
              true
            }
          />
          <DKTButton
            className={classes.rightButton}
            onClick={handleSave}
            onMouseOver={handleBlurAll}
            disabled={
              isSavingContingency ||
              hasOnlyViewPermission ||
              isFetchingContingencyReferenceValues ||
              isFetchingContingency
            }
          >
            {ternary(isSavingContingency, 'Saving...', 'Save')}
          </DKTButton>
        </Stack>
      </Box>
    </>
  )
  const actionList = [{ label: 'Delete', onClick: () => null }]

  return (
    <div>
      <InnerLayout
        contentTitle="Contingency"
        actions={actions}
        maxWidth="md"
        isShowMenu
      >
        {equal(isFetchingContingencyReferenceValues, true) ||
        equal(isFetchingContingency, true) ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <DKTCircularProgress />
          </Stack>
        ) : equal(isFetchingContingencyReferenceValues, 'FAILED') ||
          equal(isFetchingContingency, 'FAILED') ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching Contingency data. Please
              try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <Grid container>
            <Grid item xs={12} lg={10}>
              <Grid container columnSpacing={10} rowSpacing={3}>
                {/* Hard Cost Contingency */}
                <Grid item lg={6} md={12}>
                  <FormSection
                    title="Hard Cost Contingency (HCC)"
                    formFields={formFields.hardCostContingency}
                    {...{
                      values,
                      errors,
                      handleChange,
                      mergedFormFields,
                      dynamicOptions,
                    }}
                    commentsSectionProps={{
                      title: 'Comments',
                      valuesKey: 'hccComments',
                      onChangeHandler: handleTextEditor,
                      disabled: hasOnlyViewPermission,
                      ...{
                        values,
                        showImportWarning,
                        importFieldName,
                        ownerContractorId,
                        setFieldValue,
                      },
                    }}
                  />
                </Grid>

                <Grid item lg={6} md={12}>
                  {/* General Contractor (GC) Contingency */}
                  <Box mb={2.3}>
                    <FormSection
                      title="General Contractor (GC) Contingency"
                      formFields={formFields.contractorContingency}
                      {...{
                        values,
                        errors,
                        handleChange,
                        mergedFormFields,
                        dynamicOptions,
                      }}
                      commentsSectionProps={{
                        title: 'Comments',
                        valuesKey: 'contractorContingencyComments',
                        onChangeHandler: handleTextEditor,
                        disabled:
                          hasOnlyViewPermission ||
                          !values?.isPrintGcContingency,
                        ...{
                          values,
                          showImportWarning,
                          importFieldName,
                          ownerContractorId,
                          setFieldValue,
                        },
                      }}
                    />
                  </Box>
                  {/* Soft Cost Contingency */}
                  <Box>
                    <FormSection
                      title="Soft Cost Contingency (SCC)"
                      formFields={formFields.softCostContingency}
                      {...{
                        values,
                        errors,
                        handleChange,
                        mergedFormFields,
                        dynamicOptions,
                      }}
                      commentsSectionProps={{
                        title: 'Comments',
                        valuesKey: 'sccComments',
                        onChangeHandler: handleTextEditor,
                        disabled:
                          hasOnlyViewPermission ||
                          !values?.isPrintSoftCostContingency,
                        ...{
                          values,
                          showImportWarning,
                          importFieldName,
                          ownerContractorId,
                          setFieldValue,
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Import content over-write warning */}
        <ImportCommentModal
          {...{
            isImportWarningOpen,
            hideImportWarning,
            importFieldName,
            setFieldValue,
            ownerContractorId,
          }}
        />
        {/* show modal when tries to navigate without save data */}
        {!hasOnlyViewPermission && (
          <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
        )}
      </InnerLayout>
    </div>
  )
}

export default Contingency
