import { initialReportTierSettings } from '../../Description/reportTierSettings.description'
import { equal, ternary } from '../../Utils/javascript'
import {
  CREATE_CONSTRUCTION_MONITORING_REPORT_FAILED,
  CREATE_CONSTRUCTION_MONITORING_REPORT_REQUESTED,
  CREATE_CONSTRUCTION_MONITORING_REPORT_SUCCESS,
  FETCH_CONSTRUCTION_MONITORING_REPORT_FAILED,
  FETCH_CONSTRUCTION_MONITORING_REPORT_REQUESTED,
  FETCH_CONSTRUCTION_MONITORING_REPORT_SUCCESS,
  FETCH_CONSTRUCTION_SUMMARY_FORMAT_FAILED,
  FETCH_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED,
  FETCH_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS,
  FETCH_LIMITATIONS_FAILED,
  FETCH_LIMITATIONS_REQUESTED,
  FETCH_LIMITATIONS_SUCCESS,
  FETCH_REPORT_TIER_SETTINGS_FAILED,
  FETCH_REPORT_TIER_SETTINGS_REQUESTED,
  FETCH_REPORT_TIER_SETTINGS_SUCCESS,
  HANDLE_REPORT_TIER_SETTINGS_CHANGE,
  SAVE_CONSTRUCTION_SUMMARY_FORMAT_FAILED,
  SAVE_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED,
  SAVE_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS,
  SAVE_LIMITATIONS_FAILED,
  SAVE_LIMITATIONS_REQUESTED,
  SAVE_LIMITATIONS_SUCCESS,
  SAVE_REPORT_TIER_SETTINGS_FAILED,
  SAVE_REPORT_TIER_SETTINGS_REQUESTED,
  SAVE_REPORT_TIER_SETTINGS_SUCCESS,
  FETCH_ES_FAILED,
  FETCH_ES_REQUESTED,
  FETCH_ES_SUCCESS,
  SAVE_ES_FAILED,
  SAVE_ES_REQUESTED,
  SAVE_ES_SUCCESS,
  SAVE_CP_REQUESTED,
  SAVE_CP_SUCCESS,
  SAVE_CP_FAILED,
  FETCH_CP_REQUESTED,
  FETCH_CP_SUCCESS,
  FETCH_CP_FAILED,
} from '../constants/constructionMonitoringReport'

const initialState = {
  isCreatingCMR: false,
  isFetchingCMR: false,
  isFetchingLimitations: false,
  isFetchingReportTierSettings: false,
  isFetchingConstructionSummaryFormat: true,
  isSavingLimitations: false,
  isSavingReportTierSettings: false,
  isSavingConstructionSummaryFormat: false,
  createCMRError: null,
  limitations: {},
  constructionSummaryFormat: null,
  reportTierSettings: initialReportTierSettings,
  isSavedReportTierSettings: false,
  subReportTierSettings: initialReportTierSettings,
  saveLimitationsError: null,
  saveReportTierSettingsError: null,
  saveConstructionSummaryFormatError: null,
  fetchLimitationsError: null,
  fetchReportTierSettingsError: null,
  fetchConstructionSummaryFormatError: null,
  fetchCMRError: null,
  constructionMonitoringReport: {},
  // executive Summary
  isFetchingES: false,
  executiveSummaryData: '',
  isSavingEs: false,
  saveESError: null,
}

export const constructionMonitoringReport = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case CREATE_CONSTRUCTION_MONITORING_REPORT_REQUESTED:
      return {
        ...state,
        isCreatingCMR: true,
      }
    case CREATE_CONSTRUCTION_MONITORING_REPORT_SUCCESS:
      return {
        ...state,
        isCreatingCMR: false,
        createCMRError: null,
        constructionMonitoringReport: action.payload,
      }
    case CREATE_CONSTRUCTION_MONITORING_REPORT_FAILED:
      return {
        ...state,
        isCreatingCMR: false,
        createCMRError: action.payload,
      }
    case SAVE_LIMITATIONS_REQUESTED:
      return {
        ...state,
        isSavingLimitations: true,
      }
    case SAVE_LIMITATIONS_SUCCESS:
      return {
        ...state,
        isSavingLimitations: false,
        saveLimitationsError: null,
        limitations: action.payload,
      }
    case SAVE_LIMITATIONS_FAILED:
      return {
        ...state,
        isSavingLimitations: false,
        saveLimitationsError: action.payload,
      }

    case SAVE_REPORT_TIER_SETTINGS_REQUESTED:
      return {
        ...state,
        isSavingReportTierSettings: true,
      }
    case SAVE_REPORT_TIER_SETTINGS_SUCCESS:
      return {
        ...state,
        isSavingReportTierSettings: false,
        saveReportTierSettingsError: null,
        reportTierSettings: action.payload?.reportTierSetting,
        isSavedReportTierSettings: true,
      }
    case SAVE_REPORT_TIER_SETTINGS_FAILED:
      return {
        ...state,
        isSavingReportTierSettings: false,
        saveReportTierSettingsError: action.payload,
      }

    case SAVE_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED:
      return {
        ...state,
        isSavingConstructionSummaryFormat: true,
      }
    case SAVE_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS:
      return {
        ...state,
        isSavingConstructionSummaryFormat: false,
        saveConstructionSummaryFormatError: null,
        constructionSummaryFormat: action.payload,
      }
    case SAVE_CONSTRUCTION_SUMMARY_FORMAT_FAILED:
      return {
        ...state,
        isSavingConstructionSummaryFormat: false,
        saveConstructionSummaryFormatError: action.payload,
      }

    case FETCH_CONSTRUCTION_MONITORING_REPORT_REQUESTED:
      return {
        ...state,
        isFetchingCMR: true,
      }
    case FETCH_CONSTRUCTION_MONITORING_REPORT_SUCCESS:
      return {
        ...state,
        isFetchingCMR: false,
        fetchCMRError: null,
        constructionMonitoringReport: action.payload,
      }
    case FETCH_CONSTRUCTION_MONITORING_REPORT_FAILED:
      return {
        ...state,
        isFetchingCMR: false,
        fetchCMRError: action.payload,
      }

    case FETCH_LIMITATIONS_REQUESTED:
      return {
        ...state,
        isFetchingLimitations: true,
      }
    case FETCH_LIMITATIONS_SUCCESS:
      return {
        ...state,
        isFetchingLimitations: false,
        fetchLimitationsError: null,
        limitations: action.payload,
      }
    case FETCH_LIMITATIONS_FAILED:
      return {
        ...state,
        isFetchingLimitations: false,
        fetchLimitationsError: action.payload,
      }

    case FETCH_REPORT_TIER_SETTINGS_REQUESTED:
      return {
        ...state,
        isFetchingReportTierSettings: true,
      }
    case FETCH_REPORT_TIER_SETTINGS_SUCCESS:
      return {
        ...state,
        isFetchingReportTierSettings: false,
        fetchReportTierSettingsError: null,
        isSavedReportTierSettings: true,
        reportTierSettings: action.payload,
      }
    case FETCH_REPORT_TIER_SETTINGS_FAILED:
      return {
        ...state,
        isFetchingReportTierSettings: false,
        fetchReportTierSettingsError: action.payload,
      }

    case FETCH_CONSTRUCTION_SUMMARY_FORMAT_REQUESTED:
      return {
        ...state,
        isFetchingConstructionSummaryFormat: true,
      }
    case FETCH_CONSTRUCTION_SUMMARY_FORMAT_SUCCESS:
      return {
        ...state,
        isFetchingConstructionSummaryFormat: false,
        fetchConstructionSummaryFormatError: null,
        constructionSummaryFormat: action.payload,
      }
    case FETCH_CONSTRUCTION_SUMMARY_FORMAT_FAILED:
      return {
        ...state,
        isFetchingConstructionSummaryFormat: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        fetchConstructionSummaryFormatError: action.payload?.data,
      }
    case HANDLE_REPORT_TIER_SETTINGS_CHANGE:
      return {
        ...state,
        reportTierSettings: action.payload,
      }
    // executive summary
    case FETCH_ES_REQUESTED:
      return {
        ...state,
        isFetchingES: true,
      }
    case FETCH_ES_SUCCESS:
      return {
        ...state,
        isFetchingES: false,
        executiveSummaryData: action.payload,
      }
    case FETCH_ES_FAILED:
      return {
        ...state,
        isFetchingES: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    case SAVE_ES_REQUESTED:
      return {
        ...state,
        isSavingEs: true,
      }
    case SAVE_ES_SUCCESS:
      return {
        ...state,
        isSavingEs: false,
        executiveSummaryData: action.payload,
      }
    case SAVE_ES_FAILED:
      return {
        ...state,
        isSavingEs: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        saveESError: action.payload.data,
      }
    case FETCH_CP_REQUESTED:
      return {
        ...state,
        isFetchingCP: true,
      }
    case FETCH_CP_SUCCESS:
      return {
        ...state,
        isFetchingCP: false,
        coverPageData: action.payload,
      }
    case FETCH_CP_FAILED:
      return {
        ...state,
        isFetchingCP: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
      }
    case SAVE_CP_REQUESTED:
      return {
        ...state,
        isSavingCp: true,
      }
    case SAVE_CP_SUCCESS:
      return {
        ...state,
        isSavingCp: false,
        coverPageData: action.payload,
      }
    case SAVE_CP_FAILED:
      return {
        ...state,
        isSavingCp: ternary(
          equal(action.payload?.status, 404),
          false,
          'FAILED',
        ),
        saveCPError: action.payload.data,
      }
    default:
      return state
  }
}
