import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    secondary: {
      main: '#F2F0E9',
    },
    black: { main: '#000' },
    gray: {
      main: '#E5E5E5',
      medium: '#686868',
      dark: '#C4C4C4',
      extraDark: '#2D2D2D',
    },
    primary: { main: '#1564FF', light: '#1563ff1b' },
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 575,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1440,
      xxl: 1650,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-asterisk': {
            color: '#ff0000',
          },
        },
      },
    },
  },
})

export default theme
