import { values } from '../Utils/javascript'

export const cmrItems = {
  limitations: 'Limitations',
  reportTierSettings: 'Report Tier Settings',
  constructionSummaryFormat: 'Construction Summary Format',
  coverPage: 'Cover Page',
  executiveSummary: 'Executive Summary',
}
export const cmrItem = {
  type: 'select',
  label: 'Select CMR Item',
  options: values(cmrItems).map((cmrItem) => ({
    value: cmrItem,
    label: cmrItem,
  })),
  placeholder: 'Select',
  displayEmpty: true,
}
export const coverItems = {
  acreiq: 'AcreIq',
  custom: 'Custom Page',
}
export const coverItem = {
  type: 'select',
  label: 'Cover page selection',
  options: [
    {
      label: 'acreIQ Design',
      value: 'UD',
    },
    {
      label: 'Custom Design',
      value: 'UC',
    },
  ],
  placeholder: 'Select',
  displayEmpty: true,
}

export const formFields = {
  limitations: {
    nameAndSignature: {
      type: 'select',
      label: 'Name & signature',
      isRequired: true,
      isDynamicOptions: true,
      placeholder: 'Select',
      displayEmpty: true,
      lg: 12,
    },
    title: {
      type: 'text',
      label: 'Title',
      isDynamicOptions: true,
      displayEmpty: true,
      lg: 12,
    },
  },
}
