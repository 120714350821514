import { makeStyles } from '@mui/styles'
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import React, { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Cropper } from 'react-cropper'
import { coverItem } from '../../../Description/constructionMonitoringReport.description'
import { InnerLayout } from '../../../Layout/admin'
import DKTButton from '../../../Shared/DKTButton'
import DKTConfirmNavigateShowModal from '../../../Shared/DKTConfirmNavigateShowModal'
import DKTEditor from '../../../Shared/DKTEditor'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTSelect from '../../../Shared/DKTSelect'
import {
  equal,
  renderHtmlContent,
  replaceMentionsWithValues,
  ternary,
} from '../../../Utils/javascript'
import {
  fetchCoverPage,
  saveCoverPage,
} from '../../../Redux/actions/constructionMonitoringReport'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import {
  coverPageConfigFeed,
  coverPageToolbarItems,
  coverPageValues,
  defaultCoverPageHtmlText,
} from '../../../Description/coverPage.description'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { showToast } from '../../../Utils/toastService'
import { CircledPlusIcon, DeleteIcon } from '../../../Assets/SVGs'
import DKTDialog from '../../../Shared/DKTDialog'
import theme from '../../../Theme'

const useStyles = makeStyles(() => ({
  coverEditor: {
    resize: 'none',
    '& .ck-content': {
      maxHeight: 762,
      minHeight: 762,
      padding: 48,
      backgroundColor: '#D9D9D9 !important',
      overflow: 'hidden',
      fontFamily: 'Calibri',
    },
  },
}))

const CoverPage = ({ renderSelect, items }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [coverPage, setCoverPage] = useState({
    value: '',
    error: '',
  })
  const cropperRef = createRef()
  const [cropImage, setCropImage] = useState()
  const [image, setImage] = useState()
  const [previewImage, setPreviewImage] = useState('')
  const [values, setValues] = useState({})
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isCropModalOpen, setIsCropModalOpen] = useState(false)
  const [hasImageChanges, setHasImageChanges] = useState(false)

  const { isSavingCp, isFetchingCP, coverPageData } = useSelector(
    ({ constructionMonitoringReport }) => constructionMonitoringReport,
  )

  useEffect(() => {
    dispatch(fetchCoverPage())
  }, [])

  useEffect(() => {
    setCoverPage({ value: coverPageData?.reportPdfBackgroundType, error: '' })
    setImage(coverPageData?.reportPdfBackgroundImage)
    setPreviewImage(coverPageData?.reportPdfBackgroundImage)
    setValues({
      ...values,
      coverPageData:
        coverPageData?.reportPdfCoverPageHtmlText || defaultCoverPageHtmlText,
      previewAndEdit: replaceMentionsWithValues(
        coverPageData?.reportPdfCoverPageHtmlText || defaultCoverPageHtmlText,
        coverPageValues,
        valuesFormatter,
      ),
      errors: '',
    })
  }, [coverPageData])

  useEffect(() => {
    if (coverPageData?.reportPdfCoverPageHtmlText) {
      setIsUnsavedData(
        !equal(
          values?.coverPageData,
          coverPageData?.reportPdfCoverPageHtmlText,
        ),
      )
    }
  }, [values?.coverPageData])

  const valuesFormatter = (label, value) => {
    switch (label) {
      case '#Cover_Photo':
        return `<img
            style="width: auto; height: 215px;"
            src=${value}
            alt="Cover of the book 'Title of the Book'"
          />`

      default:
        return value
    }
  }

  const {
    isUnsavedData,
    setIsUnsavedData,
    discardUnsavedChanges,
    isUnsavedChangesModalOpen,
    closeUnsavedChangesModal,
  } = items
  const handleSave = () => {
    if (
      (!coverPage?.value || values?.errors) &&
      !equal(coverPage?.value, 'UD')
    ) {
      setCoverPage((prev) => ({
        ...prev,
        error: !coverPage?.value ? 'Please select value' : '',
      }))
      dispatch(updateOnSaveStatus({ cancel: true }))
      return
    }
    const onSuccess = () => {
      setIsUnsavedData(false)
      setHasImageChanges(false)
    }
    dispatch(
      saveCoverPage({
        coverPageData: {
          report_pdf_background_type: coverPage?.value,
          ...(image instanceof Blob
            ? { report_pdf_background_image: image }
            : { report_pdf_background_image_url: image }),
          report_pdf_cover_page_html_text: values?.coverPageData,
        },
        onSuccess,
      }),
    )
  }
  const actions = () => (
    <DKTButton onClick={handleSave}>
      {ternary(isSavingCp, 'Saving...', 'Save')}
    </DKTButton>
  )

  const handleEditorChange = (event, editor) => {
    const data = editor.getData()
    setValues({
      ...values,
      coverPageData: data,
      previewAndEdit: replaceMentionsWithValues(
        data,
        coverPageValues,
        valuesFormatter,
      ),
      errors: !data && 'Required',
    })
  }

  const deleteBackground = () => {
    setImage('')
    setPreviewImage('')
    setIsDeleteModalOpen(false)
    setHasImageChanges(Boolean(coverPageData?.reportPdfBackgroundImage))
  }

  const deleteModalActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setIsDeleteModalOpen(false)}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={deleteBackground}>
        Yes
      </DKTButton>
    </>
  )

  const getCropData = () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas({
        fillColor: '#ffffff', // Ensure background is white if transparent areas exist
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      })
      setPreviewImage(croppedCanvas.toDataURL('image/png', 1.0))
      setIsCropModalOpen(false)
      setHasImageChanges(true)
    }
  }

  const cropActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={() => setIsCropModalOpen(false)}
      >
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={getCropData}>
        Yes
      </DKTButton>
    </>
  )

  const onChange = (e) => {
    e.preventDefault()
    let files
    if (e.dataTransfer) {
      files = e.dataTransfer.files
    } else if (e.target) {
      files = e.target.files
    }
    const allowedTypes = ['image/jpeg', 'image/png']
    const file = files[0]

    if (!allowedTypes.includes(file.type)) {
      showToast('Only JPEG and PNG files are allowed.')
      return
    }

    setImage(file)

    const reader = new FileReader()
    reader.onloadend = ({ target }) => {
      setCropImage(target.result)
      setIsCropModalOpen(true)
    }
    reader.readAsDataURL(file)
  }

  return (
    <InnerLayout
      actionTitle="Manage Construction Monitoring Report Settings"
      actions={actions}
      contentTitle="Construction Monitoring Report (CMR)"
    >
      {renderSelect()}
      {equal(isFetchingCP, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : (
        <>
          <Grid container alignItems="flex-end" my={2}>
            <Grid item xs={3}>
              <DKTSelect
                {...coverItem}
                name="coverItem"
                id="coverItem"
                value={coverPage?.value || ''}
                onChange={(e) => {
                  setIsUnsavedData(
                    equal(
                      coverPage?.value,
                      coverPageData?.reportPdfBackgroundType,
                    ),
                  )
                  setCoverPage((prev) => ({
                    ...prev,
                    value: e.target.value,
                    error: '',
                  }))
                }}
                error={coverPage?.error || ''}
              />
            </Grid>
            {equal(coverPage?.value, 'UC') && (
              <Grid item xs={4}>
                <Stack
                  sx={{
                    position: 'relative',
                    '& .MuiFormHelperText-root': {
                      marginLeft: 2,
                      position: 'absolute',
                      bottom: -15,
                    },
                  }}
                >
                  <label
                    htmlFor="bg-image"
                    style={{
                      cursor: 'pointer',
                      padding: '8px 12px',
                      width: 'fit-content',
                      color: theme.palette.primary.main,
                      borderRadius: '5px',
                      fontSize: '16px',
                      display: 'inline-flex',
                      gap: 8,
                      alignItems: 'center',
                    }}
                  >
                    <CircledPlusIcon style={{ fontSize: '20px' }} />{' '}
                    Upload/Replace Custom Background
                    <input
                      type="file"
                      onChange={onChange}
                      onClick={(event) => {
                        event.currentTarget.value = null
                      }}
                      id="bg-image"
                      style={{ display: 'none' }}
                    />
                  </label>
                </Stack>
              </Grid>
            )}
          </Grid>

          {equal(coverPage?.value, 'UC') && (
            <Stack direction="row" gap="30px" flexWrap="wrap">
              <Box
                position="relative"
                minWidth="816px"
                maxWidth="816px"
                sx={{
                  '& .ck-sticky-panel': {
                    padding: '20px 2px 0 !important',
                  },
                }}
              >
                {image && (
                  <IconButton
                    onClick={() => setIsDeleteModalOpen(true)}
                    sx={{
                      position: 'absolute',
                      top: 36,
                      right: 12,
                      zIndex: 9,
                      background: 'white',
                      borderRadius: 0,
                      '&:hover': {
                        background: 'white',
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                <DKTEditor
                  configData={{
                    mention: {
                      feeds: [
                        {
                          marker: '#',
                          feed: coverPageConfigFeed,
                          dropdownLimit: coverPageConfigFeed?.length,
                        },
                      ],
                    },
                    toolbar: {
                      items: coverPageToolbarItems,
                      shouldNotGroupWhenFull: false,
                    },
                    fontColor: {},
                    height: 856,
                  }}
                  className={classes.coverEditor}
                  value={values?.coverPageData || ''}
                  title="Cover Page Generator (Use # to activate list of tags.)"
                  onChange={handleEditorChange}
                  isRequired
                  error={values?.errors}
                  onReady={(editor) => {
                    editor.editing.view.document.on(
                      'keydown',
                      (event, data) => {
                        const editorElement = editor.ui.view.editable.element
                        if (
                          editorElement.scrollHeight > 858 &&
                          data.keyCode === 13
                        ) {
                          data.preventDefault()
                        }
                      },
                    )
                  }}
                />
              </Box>
              <Box sx={{ minWidth: 816, maxWidth: 816 }}>
                <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                  Sample Preview
                </Typography>
                <Box
                  sx={{
                    boxSizing: 'border-box',
                    p: '48px',
                    mt: '5px',
                    maxHeight: 1056,
                    minHeight: 1056,
                    fontFamily: 'Calibri',
                    overflow: 'hidden',
                    ...(image
                      ? {
                          backgroundImage: `url(${previewImage})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          backgroundRepeat: 'no-repeat',
                        }
                      : {}),
                  }}
                >
                  <Box
                    dangerouslySetInnerHTML={renderHtmlContent(
                      values?.previewAndEdit,
                    )}
                    sx={{ position: 'relative', zIndex: 2 }}
                  />
                </Box>
              </Box>
            </Stack>
          )}
        </>
      )}

      {/* show modal when tries to navigate without save data */}

      <DKTConfirmNavigateShowModal
        isActive={isUnsavedChangesModalOpen}
        onConfirm={discardUnsavedChanges}
        onCancel={closeUnsavedChangesModal}
        onSave={handleSave}
      />
      <DKTReactRouterPrompt
        isDirty={isUnsavedData || hasImageChanges}
        onSave={handleSave}
      />

      <DKTDialog
        open={isCropModalOpen}
        handleClose={() => setIsCropModalOpen(false)}
        title="Confirm Alignment"
        actions={cropActions}
        maxWidth="xs"
      >
        <Cropper
          ref={cropperRef}
          style={{ height: 264, width: '100%' }} // Increase height to accommodate the crop box
          aspectRatio={204 / 264} // Lock aspect ratio to 816:1056
          src={cropImage}
          viewMode={1}
          background={false}
          responsive={false} // Ensure image scales correctly
          autoCropArea={1} // Ensure maximum coverage
          checkOrientation={false}
          guides={false}
          zoomable // Allow zooming
          cropBoxMovable // Allow dragging the crop box
          cropBoxResizable={false} // Disable resizing
          dragMode="move" // Allow moving the image
          ready={() => {
            const { cropper } = cropperRef.current

            cropper.setCropBoxData({
              width: 204,
              height: 264,
              left: (cropper.getContainerData().width - 204) / 2,
              top: (cropper.getContainerData().height - 264) / 2,
            })
          }}
        />
      </DKTDialog>

      <DKTDialog
        open={isDeleteModalOpen}
        handleClose={() => setIsDeleteModalOpen(false)}
        title="&nbsp;"
        actions={deleteModalActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Are you sure you want to delete?
              </Typography>
              <Typography variant="body1">
                You can&apos;t undo this action
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
    </InnerLayout>
  )
}

export default CoverPage
