// Fetch construction schedule
export const FETCH_REPORT_PDF_REQUESTED = 'FETCH_REPORT_PDF_REQUESTED'
export const FETCH_REPORT_PDF_SUCCESS = 'FETCH_REPORT_PDF_SUCCESS'
export const FETCH_REPORT_PDF_FAILED = 'FETCH_REPORT_PDF_FAILED'
export const OPEN_CURVE_MODAL = 'OPEN_CURVE_MODAL'

// Fetch generate link
export const FETCH_GENERATE_PDF_LINK_REQUESTED =
  'FETCH_GENERATE_PDF_LINK_REQUESTED'
export const FETCH_GENERATE_PDF_LINK_SUCCESS = 'FETCH_GENERATE_PDF_LINK_SUCCESS'
export const FETCH_GENERATE_PDF_LINK_FAILED = 'FETCH_GENERATE_PDF_LINK_FAILED'
export const CLEAR_GENERATE_PDF_LINK = 'CLEAR_GENERATE_PDF_LINK'
