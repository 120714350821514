import { useTheme } from '@emotion/react'
import { Box, Grid, InputLabel, Stack, Typography } from '@mui/material'
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import {
  choiceFields,
  criticalIssues,
  formFields,
} from '../../../Description/executiveSummary.description'
import useForm from '../../../Hooks/useForm'
import { InnerLayout } from '../../../Layout/reportMonitoring'
import { getProjectChoices } from '../../../Redux/actions/choices'
import {
  getExecutiveSummary,
  importComments,
  importExecutiveSummary,
  importOpenIssues,
  importQualityOfWork,
  saveExecutiveSummary,
  saveExecutiveSummarySuccess,
} from '../../../Redux/actions/executiveSummary'
import { updateOnSaveStatus } from '../../../Redux/actions/confirmation'
import { getReportChoices } from '../../../Redux/actions/reportChoices'
import DKTButton from '../../../Shared/DKTButton'
import DKTButtonSelect from '../../../Shared/DKTButtonSelect'
import DKTCircularProgress from '../../../Shared/DKTCircularProgress'
import DKTDialog from '../../../Shared/DKTDialog'
import DKTRadioGroup from '../../../Shared/DKTRadioGroup'
import DKTReactRouterPrompt from '../../../Shared/DKTReactRouterPrompt'
import DKTTooltip from '../../../Shared/DKTTooltip'
import DKTForm from '../../../Shared/DKTForm'
import { useStyles } from '../../../Styles/executiveSummary.style'
import { importButtonTooltip, spinnerSize } from '../../../Utils/constant'
import {
  entries,
  equal,
  handleTextEditor,
  isArray,
  isEmptyString,
  ternary,
} from '../../../Utils/javascript'
import { renderFormFields } from '../../../Utils/renderFormFields'
import { timeValidation } from '../../../Utils/regex'
import AutoGenerateES from './AutoGenerateES'
import usePermission from '../../../Hooks/usePermission'
import DKTEditor from '../../../Shared/DKTEditor'

const initialFormFields = entries(formFields).reduce((result, formField) => {
  const formFieldValue = formField[1]
  return {
    ...result,
    ...formFieldValue,
  }
}, {})

const ImportButton = ({
  isImporting = false,
  onImport = () => null,
  hasOnlyViewPermission,
}) => {
  const theme = useTheme()
  return (
    <DKTTooltip title={importButtonTooltip}>
      <Box>
        <DKTButton
          variant="outlined"
          sx={{
            maxWidth: 'max-content',
            borderColor: '#ACACAC',
            color: '#2D2D2D',
            width: '91px',
            height: '32px',
            '&:hover': {
              borderColor: '#000',
            },
          }}
          onClick={onImport}
          disabled={hasOnlyViewPermission || isImporting}
        >
          {ternary(
            isImporting,
            <DKTCircularProgress
              size={spinnerSize?.sm}
              color={theme.palette.gray.dark}
            />,
            'Import',
          )}
        </DKTButton>
      </Box>
    </DKTTooltip>
  )
}

const optionsFields = ['ownerContractor']

const ExecutiveSummary = () => {
  const [isImportWarningOpen, setIsImportWarningOpen] = useState(false)
  const [updatedFormFields, setUpdatedFormFields] = useState({ ...formFields })
  const [mergedFormFields, setMergedFormFields] = useState({
    ...initialFormFields,
  })
  const [isAutoGenerateOpen, setIsAutoGenerateOpen] = useState(false)

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    setErrors,
    isDirty,
    setIsDirty,
    setDummyData,
  } = useForm(mergedFormFields)
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const query = useSearchParams()[0]
  const isOwnerContractor = query.get('ocId')
  const { slug: projectId, reportId } = useParams()
  const [searchParams] = useSearchParams()
  const {
    executiveSummary,
    isLoadingExecutiveSummary,
    isSavingExecutiveSummary,
    isImportingExecutiveSummary,
    isImportingOpenIssues,
    isImportingComments,
    isImportingQualityOfWork,
  } = useSelector(({ executiveSummary }) => executiveSummary)
  const { choices } = useSelector(({ projectChoices }) => projectChoices)
  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const { choices: reportChoices } = useSelector(
    ({ reportChoices }) => reportChoices,
  )
  const { hasOnlyViewPermission } = usePermission()

  const importField = useRef(null)
  const isImporting =
    isImportingExecutiveSummary ||
    isImportingOpenIssues ||
    isImportingComments ||
    isImportingQualityOfWork

  const showImportWarning = () => setIsImportWarningOpen(true)
  const hideImportWarning = () => setIsImportWarningOpen(false)
  const onOpenCloseAutoGenModal = (isOpen = false) =>
    setIsAutoGenerateOpen(isOpen)

  // Get owner contractor id
  useEffect(() => {
    dispatch(
      getReportChoices({
        formNames: optionsFields?.join(','),
        projectId,
      }),
    )
  }, [dispatch, projectId])

  // If url missed owner contractor then set default to OC-01
  useEffect(() => {
    if (!isOwnerContractor) {
      if (
        isArray(reportChoices?.ownerContractor) &&
        reportChoices?.ownerContractor.length
      ) {
        const activeContractor = reportChoices?.ownerContractor[0]?.id
        setFieldValue('ownerContractor', activeContractor)
        navigate(
          `/projects/${projectId}/project-reports/${reportId}/executive-summary/?ocId=${activeContractor}`,
        )
      }
    }
  }, [reportChoices?.ownerContractor, isOwnerContractor])

  // Get choices
  useEffect(() => {
    dispatch(getProjectChoices({ fieldName: choiceFields.join(',') }))
  }, [dispatch])
  // Get executiveSummary
  useEffect(() => {
    dispatch(getExecutiveSummary({ reportId, projectId }))
  }, [dispatch, reportId])
  // Pre-populate Value
  useEffect(() => {
    const prePopulateValues = { ...executiveSummary }
    setValues((prevValues) => ({ ...prevValues, ...prePopulateValues }))
    setDummyData((prevValues) => ({ ...prevValues, ...prePopulateValues }))
  }, [executiveSummary])

  // Set initial report date from report
  useEffect(() => {
    if (
      reportData &&
      !isLoadingExecutiveSummary &&
      !executiveSummary?.reportDate
    ) {
      setErrors({})
      setFieldValue('reportDate', moment().format('MM/DD/YYYY'))
      setDummyData((pre) => ({
        ...pre,
        reportDate: moment().format('MM/DD/YYYY'),
      }))
    }
  }, [reportData?.createdAt, executiveSummary?.reportDate])

  // Reset and disable values
  useEffect(() => {
    setValues((values) => ({
      ...values,
      siteVisitDate:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.siteVisitDate === 'N/A'
          ? null
          : values.siteVisitDate,
      timeOfVisit:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.timeOfVisit === 'N/A'
          ? null
          : values.timeOfVisit,
      timeOfVisitMeridiem:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.timeOfVisitMeridiem === 'N/A'
          ? null
          : values.timeOfVisitMeridiem,
      temperature:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.temperature === 'N/A'
          ? null
          : values.temperature,
      siteConditions:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.siteConditions === 'N/A'
          ? null
          : values.siteConditions,
    }))
    setDummyData((values) => ({
      ...values,
      siteVisitDate:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.siteVisitDate === 'N/A'
          ? null
          : values.siteVisitDate,
      timeOfVisit:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.timeOfVisit === 'N/A'
          ? null
          : values.timeOfVisit,
      timeOfVisitMeridiem:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.timeOfVisitMeridiem === 'N/A'
          ? null
          : values.timeOfVisitMeridiem,
      temperature:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.temperature === 'N/A'
          ? null
          : values.temperature,
      siteConditions:
        values?.onSiteVisit === 'NO'
          ? 'N/A'
          : values?.siteConditions === 'N/A'
          ? null
          : values.siteConditions,
    }))
  }, [executiveSummary, values.onSiteVisit])

  useEffect(() => {
    setValues((values) => ({
      ...values,
      virtualMeetingDate:
        values?.virtualMeeting === 'NO'
          ? 'N/A'
          : values?.virtualMeetingDate === 'N/A'
          ? null
          : values.virtualMeetingDate,
    }))
    setDummyData((values) => ({
      ...values,
      virtualMeetingDate:
        values?.virtualMeeting === 'NO'
          ? 'N/A'
          : values?.virtualMeetingDate === 'N/A'
          ? null
          : values.virtualMeetingDate,
    }))
  }, [executiveSummary, values?.virtualMeeting])

  // Reset executiveSummary fields on unmount
  useEffect(() => () => dispatch(saveExecutiveSummarySuccess({})), [])

  const populateField = (fieldName) => (value) => {
    setFieldValue(fieldName, value)
    hideImportWarning()
    importField.current = null
  }

  const handleImport = (importFieldName, importFromLastReport) => () => {
    const importContent = () =>
      dispatch(
        importFromLastReport(
          projectId,
          searchParams.get('ocId'),
          populateField(importFieldName),
          reportId,
        ),
      )
    if (!values[importFieldName]) {
      importContent()
      return
    }
    importField.current = importContent
    showImportWarning()
  }

  // Set field required based on another
  useEffect(() => {
    const isOnSiteVisit = equal(values.onSiteVisit, 'NO')
    setUpdatedFormFields((prev) => ({
      ...prev,
      leftSide: {
        ...prev.leftSide,
        timeOfVisit: {
          type: 'text',
          label: 'Time of Visit',
          placeholder: '00:00',
          isTime: true,
          xs: 8,
          ...(!isOnSiteVisit && { validate: [timeValidation] }),
        },
      },
    }))
    setMergedFormFields((prev) => ({
      ...prev,
      timeOfVisit: {
        type: 'text',
        label: 'Time of Visit',
        placeholder: '00:00',
        isTime: true,
        xs: 8,
        ...(!isOnSiteVisit && { validate: [timeValidation] }),
      },
    }))
    setErrors((prevErrors) => {
      const clonedErrors = { ...prevErrors }
      if (isOnSiteVisit) {
        delete clonedErrors.siteVisitDate
        delete clonedErrors.timeOfVisit
        delete clonedErrors.timeOfVisitMeridiem
        delete clonedErrors.temperature
        delete clonedErrors.siteConditions
      }
      return clonedErrors
    })
  }, [executiveSummary, values.onSiteVisit])
  const handleImportOverWrite = () => {
    if (importField.current instanceof Function) importField.current()
  }

  const customFormControlFields = ({ name, formControl }) => ({
    ...ternary(
      equal(name, 'siteVisitDate') ||
        equal(name, 'timeOfVisit') ||
        equal(name, 'timeOfVisitMeridiem') ||
        equal(name, 'temperature') ||
        equal(name, 'siteConditions'),
      { disabled: equal(values.onSiteVisit, 'NO') },
      {},
    ),
    ...ternary(
      equal(name, 'virtualMeetingDate'),
      { disabled: equal(values.virtualMeeting, 'NO') },
      {},
    ),
    options: ternary(
      equal(formControl.type, 'select'),
      choices[name],
      formControl.options || [],
    ),
    ...ternary(
      equal(name, 'temperature') && !values.temperature,
      { value: '65' },
      {},
    ),
    ...ternary(hasOnlyViewPermission, { disabled: true }, {}),
  })

  const renderFormFieldsWithOptions = useCallback(
    (formFieldsToRender) =>
      renderFormFields({
        errors,
        formFields: formFieldsToRender,
        values,
        handleChange,
        customFormControlFields,
      }),
    [errors, values, customFormControlFields, handleChange],
  )

  const handleSave = () => {
    const isFormValid = handleSubmit()
    if (!isFormValid) {
      dispatch(updateOnSaveStatus({ cancel: true }))
    } else {
      let cloneValues = {
        ...values,
        virtualMeetingDate: ternary(
          isEmptyString(values?.virtualMeetingDate),
          null,
          values?.virtualMeetingDate,
        ),
        siteVisitDate: ternary(
          isEmptyString(values?.siteVisitDate),
          null,
          values?.siteVisitDate,
        ),
      }
      if (equal(values.onSiteVisit, 'NO')) {
        cloneValues = {
          ...cloneValues,
          siteVisitDate: null,
          timeOfVisit: null,
          timeOfVisitMeridiem: null,
          temperature: null,
          siteConditions: null,
        }
      }
      if (equal(values.virtualMeeting, 'NO')) {
        cloneValues = {
          ...cloneValues,
          virtualMeetingDate: null,
        }
      }
      dispatch(
        saveExecutiveSummary(
          { ...cloneValues, report: reportId },
          reportId,
          projectId,
          executiveSummary?.id,
        ),
      )
      setIsDirty(false)
    }
  }

  const actions = () => (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        component="h6"
        fontSize={20}
        fontWeight={700}
        textAlign="center"
        textTransform="capitalize"
      >
        Report #{reportData?.overrideReportCode}
      </Typography>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        <DKTButtonSelect
          options={actionList}
          disabled={hasOnlyViewPermission || isLoadingExecutiveSummary || true}
        />
        <DKTButton
          className={classes.rightButton}
          onClick={handleSave}
          disabled={
            hasOnlyViewPermission ||
            isSavingExecutiveSummary ||
            isLoadingExecutiveSummary
          }
        >
          {ternary(isSavingExecutiveSummary, 'Saving...', 'Save')}
        </DKTButton>
      </Stack>
    </Box>
  )
  const actionList = [{ label: 'Delete', onClick: () => null }]
  const importWarningActions = (
    <>
      <DKTButton
        variant="contained"
        disableElevation
        onClick={hideImportWarning}
      >
        No
      </DKTButton>
      <DKTButton
        variant="outlined"
        onClick={handleImportOverWrite}
        disabled={isImporting}
      >
        {ternary(
          isImporting,
          <DKTCircularProgress
            size={spinnerSize?.sm}
            {...ternary(isImporting, { color: theme.palette.gray.dark }, {})}
          />,
          'Yes',
        )}
      </DKTButton>
    </>
  )
  const executiveSummaryFields = renderFormFieldsWithOptions(
    updatedFormFields.leftSide,
  )
  return (
    <InnerLayout
      contentTitle="Executive summary"
      actions={actions}
      maxWidth="md"
      isShowMenu
    >
      {equal(isLoadingExecutiveSummary, true) ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <DKTCircularProgress />
        </Stack>
      ) : equal(isLoadingExecutiveSummary, 'FAILED') ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: 'calc(100vh - 290px)' }}
        >
          <Typography variant="body2" color="gray.extraDark" ml={2}>
            There might be some issue with fetching Executive summary data.
            Please try contacting the admin or refreshing this page.
          </Typography>
        </Stack>
      ) : (
        <Grid container>
          <Grid item xs={12} xl={11}>
            <Grid container columnGap={{ sm: 0, xl: 5 }} spacing={3}>
              <Grid item xl={2.5} lg={3} sm={4} mt={1.5}>
                <DKTForm autoComplete="off">
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    {executiveSummaryFields}
                  </Grid>
                </DKTForm>
              </Grid>
              <Grid item xl={8.5} lg={9} sm={8}>
                <Box>
                  <Stack
                    direction="row"
                    alignItems="end"
                    justifyContent="space-between"
                    mb={2}
                    mt={-1.5}
                  >
                    <Typography
                      variant="body2"
                      color="gray.extraDark"
                      sx={{ fontWeight: 'medium' }}
                    >
                      Executive Summary
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <DKTButton
                        variant="outlined"
                        onClick={() => onOpenCloseAutoGenModal(true)}
                        disabled={hasOnlyViewPermission}
                        sx={{
                          height: 32,
                          borderColor: '#ACACAC',
                          color: '#2d2d2d',
                          padding: '0 16px',
                          '&:hover': {
                            borderColor: '#000',
                          },
                        }}
                      >
                        Auto-generate
                      </DKTButton>
                      <ImportButton
                        isImporting={isImportingExecutiveSummary}
                        onImport={handleImport(
                          'executiveSummary',
                          importExecutiveSummary,
                        )}
                        hasOnlyViewPermission={hasOnlyViewPermission}
                      />
                    </Stack>
                  </Stack>
                  <DKTEditor
                    value={values?.executiveSummary || ''}
                    onChange={(event, editor) => {
                      handleTextEditor(
                        editor,
                        'executiveSummary',
                        setFieldValue,
                      )
                    }}
                    disabled={hasOnlyViewPermission}
                    className={classes.projectDesc}
                  />
                </Box>
                <Stack
                  direction="row"
                  alignItems="end"
                  justifyContent="space-between"
                  mb={2}
                  mt={4}
                >
                  <Typography
                    variant="body2"
                    color="gray.extraDark"
                    sx={{ fontWeight: 'medium' }}
                  >
                    Quality of Work
                  </Typography>
                  <ImportButton
                    isImporting={isImportingQualityOfWork}
                    onImport={handleImport(
                      'qualityOfWork',
                      importQualityOfWork,
                    )}
                    hasOnlyViewPermission={hasOnlyViewPermission}
                  />
                </Stack>
                <DKTEditor
                  value={values?.qualityOfWork || ''}
                  onChange={(event, editor) => {
                    handleTextEditor(editor, 'qualityOfWork', setFieldValue)
                  }}
                  disabled={hasOnlyViewPermission}
                  className={classes.projectDesc}
                />
                <Box>
                  <Stack
                    direction="row"
                    alignItems="end"
                    justifyContent="space-between"
                    mb={2}
                    mt={4}
                  >
                    <Typography
                      variant="body2"
                      color="gray.extraDark"
                      sx={{ fontWeight: 'medium' }}
                    >
                      Open Issues
                    </Typography>
                    <ImportButton
                      isImporting={isImportingOpenIssues}
                      onImport={handleImport('openIssues', importOpenIssues)}
                      hasOnlyViewPermission={hasOnlyViewPermission}
                    />
                  </Stack>
                  <DKTEditor
                    value={values?.openIssues || ''}
                    onChange={(event, editor) => {
                      handleTextEditor(editor, 'openIssues', setFieldValue)
                    }}
                    disabled={hasOnlyViewPermission}
                    className={classes.projectDesc}
                  />
                </Box>
                <Grid container rowSpacing={5} mt={3.6}>
                  <Grid item sm={12}>
                    <InputLabel className={classes.inlineLabel}>
                      Critical Issues
                    </InputLabel>
                    {criticalIssues.map((criticalIssue, index) => {
                      const groupName = `ci${index + 1}`
                      return (
                        <Grid item sm={12}>
                          <Stack
                            direction={{
                              sm: 'column',
                              md: 'column',
                              lg: 'row',
                            }}
                            alignItems={{
                              sm: 'start',
                              md: 'start',
                              lg: 'center',
                            }}
                            justifyContent="space-between"
                            key={index}
                            sx={{
                              borderBottom: '1px solid #E5E5E5',
                              paddingBlock: { sm: '5px', md: '5px', lg: '0' },
                              paddingLeft: { lg: '16px' },
                              fontSize: '14px',
                            }}
                          >
                            {criticalIssue}
                            <DKTRadioGroup
                              row
                              name={groupName}
                              value={values[groupName]}
                              onChange={handleChange}
                              fields={choices.criticalIssues || []}
                              disabled={hasOnlyViewPermission}
                              sx={{ textTransform: 'uppercase' }}
                              radioProps={{
                                sx: {
                                  color: '#000',
                                  '&.Mui-checked': {
                                    color: '#000',
                                  },
                                },
                              }}
                            />
                          </Stack>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Stack
                  direction="row"
                  alignItems="end"
                  justifyContent="space-between"
                  mb={2}
                  mt={4}
                >
                  <Typography
                    variant="body2"
                    color="gray.extraDark"
                    sx={{ fontWeight: 'medium' }}
                  >
                    Comments
                  </Typography>
                  <ImportButton
                    isImporting={isImportingComments}
                    onImport={handleImport('comments', importComments)}
                    hasOnlyViewPermission={hasOnlyViewPermission}
                  />
                </Stack>
                <DKTEditor
                  value={values?.comments || ''}
                  onChange={(event, editor) => {
                    handleTextEditor(editor, 'comments', setFieldValue)
                  }}
                  disabled={hasOnlyViewPermission}
                  className={classes.projectDesc}
                />
              </Grid>
            </Grid>
          </Grid>
          {isAutoGenerateOpen && (
            <AutoGenerateES
              isOpen={isAutoGenerateOpen}
              onClose={onOpenCloseAutoGenModal}
              exportToExecutiveSummary={setFieldValue}
            />
          )}
        </Grid>
      )}

      {/* Import content over-write warning */}
      <DKTDialog
        open={isImportWarningOpen}
        handleClose={hideImportWarning}
        title="&nbsp;"
        actions={importWarningActions}
        maxWidth="xs"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.deleteNotificationContent}>
              <Typography variant="h5">
                Existing data will be overwritten.
              </Typography>
              <Typography variant="body1">Continue?</Typography>
            </Box>
          </Grid>
        </Grid>
      </DKTDialog>
      {!hasOnlyViewPermission && (
        <DKTReactRouterPrompt isDirty={isDirty} onSave={handleSave} />
      )}
    </InnerLayout>
  )
}

export default ExecutiveSummary
