import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Delete } from '@mui/icons-material'
import { useParams } from 'react-router-dom'
import DKTButton from '../../Shared/DKTButton'
import { disabledAddClientList } from '../../Utils/constant'
import {
  entries,
  equal,
  handleTextEditor,
  isArray,
  keys,
  ternary,
} from '../../Utils/javascript'
import { useStyles } from '../../Styles/coverLetter.style'
import DKTReactRouterPrompt from '../../Shared/DKTReactRouterPrompt'
import useForm from '../../Hooks/useForm'
import DKTSelect from '../../Shared/DKTSelect'
import DKTStack from '../../Shared/DKTStack'
import DKTInput from '../../Shared/DKTInput'
import DKTEditor from '../../Shared/DKTEditor'
import {
  clearCoverLetterValues,
  fetchCMRCoverLetter,
  fetchClientNames,
  fetchSignature,
  updateCoverLetter,
} from '../../Redux/actions/coverLetterAndPhoto'
import { updateOnSaveStatus } from '../../Redux/actions/confirmation'
import { formFields } from '../../Description/CoverLetterAndPhotoDescription'
import DKTFileInput from '../../Shared/DKTFileInput'
import DKTDialog from '../../Shared/DKTDialog'
import DKTForm from '../../Shared/DKTForm'
import { InnerLayout } from '../../Layout/reportMonitoring'
import DKTButtonSelect from '../../Shared/DKTButtonSelect'
import { CircledPlusIcon, ImagePlaceholder } from '../../Assets/SVGs'
import DKTCircularProgress from '../../Shared/DKTCircularProgress'
import FormSectionDivider from '../../Shared/FormSectionDivider'
import DKTTooltip from '../../Shared/DKTTooltip'
import DKTInfoBox from '../../Shared/DKTInfoBox'
import usePermission from '../../Hooks/usePermission'

const CoverLetter = () => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    isDirty,
    setIsDirty,
    dummyData,
    setDummyData,
  } = useForm({
    ...formFields.CMRCoverLetterDate,
    ...formFields.CMRCoverLetter,
    ...formFields.CMRTextFields,
  })
  const [coverPhoto, setCoverPhoto] = useState()
  const [inputFileError, setInputFileError] = useState(false)
  const [deleteNotificationOpen, setOpenDeleteNotification] = useState(false)
  const [dynamicOptions, setDynamicOptions] = useState({})
  const [selectedSignatures, setSelectedSignatures] = useState({})
  const [imageDelete, setImageDelete] = useState(false)
  const [dynamicFields, setDynamicFields] = useState([])

  const { slug: projectId, reportId } = useParams()

  const { reportData } = useSelector(({ reportMonitoring }) => reportMonitoring)
  const ocId = reportData?.ownerContractor
  const {
    CMRCoverLetterData,
    CMRCoverLetterDefaultData,
    isCoverLetterUpdateLoading,
    signatures,
    isCMRCoverLetterLoading,
    isCMRCoverLetterDefaultValueLoading,
    isSignatureLoading,
    clientsDetails,
  } = useSelector(({ coverLetterAndPhoto }) => coverLetterAndPhoto)

  const { hasOnlyViewPermission } = usePermission()

  const dispatch = useDispatch()
  const classes = useStyles()

  const clientNameMapping = () =>
    Array.from(
      new Set(
        entries(values)
          ?.filter(([key]) => key.startsWith('clientName'))
          .reduce((acc, [key, value]) => {
            const index = key.split('.')[1]
            if (!acc[index]) acc[index] = []
            acc[index].push(value)
            return acc
          }, [])
          .flat(),
      ),
    )

  const selectedClient = clientNameMapping()
  const isDisabledAddClient =
    !dynamicOptions?.clientName?.length ||
    dynamicOptions?.clientName?.every(({ disabled }) =>
      equal(disabled, true),
    ) ||
    dynamicFields?.length >= 5 ||
    selectedClient?.length >= 5 ||
    dynamicFields?.length >= dynamicOptions?.clientName?.length ||
    hasOnlyViewPermission

  useEffect(() => {
    if (ocId) {
      dispatch(fetchCMRCoverLetter(projectId, reportId, ocId))
    }
    dispatch(fetchClientNames(projectId))
  }, [dispatch, reportId, ocId])

  useEffect(() => {
    dispatch(fetchSignature())
  }, [dispatch])

  useEffect(
    () => () => {
      dispatch(clearCoverLetterValues())
    },
    [],
  )

  useEffect(() => {
    const updateValues = (data) => {
      const signatures = {
        signature: data?.cmrCoverLetterSignature?.[0]?.signature?.toString(),
        nameAndContactInformation:
          data?.cmrCoverLetterSignature?.[0]?.nameAndContactInformation,
        signatureOne: data?.cmrCoverLetterSignature?.[1]?.signature?.toString(),
        nameAndContactInformationOne:
          data?.cmrCoverLetterSignature?.[1]?.nameAndContactInformation,
        reportNumber: data?.report?.reportCode,
        overrideReportCode: Number(
          data?.report?.overrideReportCode,
        )?.toString(),
      }

      let cloneFields = []
      let tempValue = {}
      const setClientNameIndex = {}
      const valuesAfterDeleteClients = { ...values }
      if (data?.report?.clientNames?.length) {
        const clientNames = clientNameMapping()
        const clientIDs = dynamicFields?.map((data) => data?.id)
        clientIDs?.forEach((id) => {
          delete valuesAfterDeleteClients[`clientName.${id}`]
        })
        clientNames?.forEach((clientName, index) => {
          setClientNameIndex[`clientName.${index}`] = clientName
        })
        data.report.clientNames.forEach((loc, index) => {
          const updatedLocation = addClientName(index, cloneFields)
          cloneFields = [...updatedLocation]
          tempValue = {
            ...tempValue,
            [`clientName.${index}`]: loc?.toString(),
          }
        })
      }
      setDynamicFields([...cloneFields])

      setValues({
        ...valuesAfterDeleteClients,
        ...setClientNameIndex,
        ...data,
        ...signatures,
        ...CMRCoverLetterData,
        ...tempValue,
      })
      setCoverPhoto(data.coverPhoto)
      setDummyData({
        ...data,
        ...signatures,
        ...CMRCoverLetterData,
        ...tempValue,
      })
    }

    if (keys(CMRCoverLetterData)?.length) {
      let updatedCMRCoverLetterData = { ...CMRCoverLetterData }
      if (!CMRCoverLetterData?.id && keys(CMRCoverLetterDefaultData)?.length) {
        updatedCMRCoverLetterData = {
          ...CMRCoverLetterData,
          report: {
            ...CMRCoverLetterData?.report,
            clientNames: CMRCoverLetterDefaultData?.clients,
          },
          ...CMRCoverLetterDefaultData,
        }
      }
      updateValues(updatedCMRCoverLetterData)
    } else {
      updateValues(CMRCoverLetterDefaultData)
    }
  }, [CMRCoverLetterData, CMRCoverLetterDefaultData])

  useEffect(() => {
    if (coverPhoto !== undefined && dummyData?.coverPhoto !== undefined) {
      setIsDirty(coverPhoto !== dummyData?.coverPhoto || isDirty)
    }
  }, [coverPhoto, dispatch])

  useEffect(() => {
    const dynamicOptionArray = (arr) => {
      const res = arr?.map((sts) => ({
        value: `${sts?.id}`,
        label: `${sts?.firstName} ${sts?.lastName}`,
      }))
      return [{ label: 'None', value: '' }, ...res]
    }
    const signature = dynamicOptionArray(signatures)
    const signatureOne = dynamicOptionArray(signatures)

    setDynamicOptions({
      ...dynamicOptions,
      signature,
      signatureOne,
    })
  }, [signatures])

  useEffect(() => {
    if (signatures) {
      const signatureImages = {
        signature: ternary(
          values?.signature,
          signatures?.find(({ id }) => equal(`${id}`, values?.signature))
            ?.signature,
          '',
        ),
        signatureOne: ternary(
          values?.signatureOne,
          signatures?.find(({ id }) => equal(`${id}`, values?.signatureOne))
            ?.signature,
          '',
        ),
      }
      setSelectedSignatures(signatureImages)
    }
  }, [values?.signature, values?.signatureOne, signatures])

  useEffect(() => {
    const dynamicOptionArray = (arr) =>
      arr?.map((item) => {
        const clientNames = clientNameMapping()
        const isAlreadyUsedId = clientNames?.find((id) => equal(+id, +item.id))
        return {
          value: `${item?.id}`,
          label: `${item?.companyName}`,
          disabled: !!isAlreadyUsedId,
        }
      })

    const clientName = dynamicOptionArray(clientsDetails)

    setDynamicOptions((prevOptions) => ({
      ...prevOptions,
      clientName: clientName?.length
        ? clientName
        : [{ label: 'No data available', disabled: true }],
    }))
  }, [clientsDetails, values])

  const setClientDataForDelete = (id = null) =>
    setOpenDeleteNotification({ id, deleteClient: true })

  const onFileUpload = (logo) => {
    setCoverPhoto(logo)
    setInputFileError(false)
    setImageDelete(false)
  }

  const handleDeleteLogo = () => {
    setOpenDeleteNotification({ popupOpen: true, id: 'companyLogo' })
  }

  const handleClose = () => setOpenDeleteNotification(false)
  const handleDelete = () => {
    if (deleteNotificationOpen?.deleteClient) {
      handleClientDelete()
    } else {
      setCoverPhoto(null)
      setImageDelete(true)
    }
    handleClose()
  }
  const actionList = [{ label: 'Delete', onClick: () => null }]

  const actions = () => (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        component="h6"
        fontSize={20}
        fontWeight={700}
        textAlign="center"
        textTransform="capitalize"
      >
        Report #{reportData?.overrideReportCode}
      </Typography>
      <Stack direction="row" gap={2} className={classes.rightButton}>
        <DKTButtonSelect
          options={actionList}
          disabled={
            hasOnlyViewPermission ||
            isCMRCoverLetterLoading ||
            isCMRCoverLetterDefaultValueLoading ||
            isSignatureLoading ||
            true
          }
        />
        <DKTButton
          className={classes.rightButton}
          onClick={handleSaveCoverLetter}
          disabled={
            hasOnlyViewPermission ||
            isCoverLetterUpdateLoading ||
            isCMRCoverLetterLoading ||
            isCMRCoverLetterDefaultValueLoading ||
            isSignatureLoading
          }
        >
          {ternary(isCoverLetterUpdateLoading, 'Saving...', 'Save')}
        </DKTButton>
      </Stack>
    </Box>
  )

  const deleteNotificationAction = (
    <>
      <DKTButton variant="contained" disableElevation onClick={handleClose}>
        No
      </DKTButton>
      <DKTButton variant="outlined" onClick={handleDelete}>
        Yes
      </DKTButton>
    </>
  )

  const getOptionLists = () => {
    const startValue =
      Number(CMRCoverLetterData?.report?.previousReportOverrideId) + 1
    const generateSelectOptions = (start, end) =>
      Array.from({ length: end - start + 1 }, (_, index) => ({
        label: (start + index).toString().padStart(2, '0'),
        value: `${start + index}`,
      }))
    const selectOptions = generateSelectOptions(startValue, 100)
    return selectOptions
  }

  const mapFormFields = ({ formFields, makeFieldNameUnique, id }) =>
    entries(formFields).map(
      (
        [
          name,
          {
            isRequired,
            isDynamicOptions,
            defaultValue,
            options,
            isConfirmPassword,
            dependsOn,
            overrideOptions,
            isDynamicClientName,
            ...formField
          },
        ],
        index,
      ) => {
        name = ternary(makeFieldNameUnique, `${name}.${id}`, name)
        const FormControl = ternary(
          equal(formField.type, 'select'),
          DKTSelect,
          DKTInput,
        )
        const overrideDisabled =
          equal(name, 'overrideReportCode') && !reportData?.isLastReport
        const optValues = isDynamicOptions
          ? dynamicOptions[name] || []
          : overrideOptions
          ? getOptionLists()
          : isDynamicClientName
          ? dynamicOptions?.clientName
          : options
        return (
          <Grid
            key={index}
            item
            xs={formField.xs ?? 12}
            lg={formField.lg ?? 12}
          >
            <Stack direction="row" width="100%" spacing={2} alignItems="end">
              <FormControl
                {...formField}
                options={optValues}
                isRequired={isRequired}
                id={name}
                name={name}
                value={(values && values[name]) || defaultValue || ''}
                onChange={handleChange}
                error={errors[name]}
                disabled={
                  hasOnlyViewPermission ||
                  formField.disabled ||
                  overrideDisabled
                }
              />
              {makeFieldNameUnique && name.startsWith('clientName') && (
                <IconButton
                  color="error"
                  onClick={() => setClientDataForDelete(id)}
                  sx={{ marginTop: '16px', height: 'fit-content' }}
                  disabled={hasOnlyViewPermission}
                >
                  <Delete />
                </IconButton>
              )}
            </Stack>
          </Grid>
        )
      },
    )
  const renderFormFields = (formFields) =>
    isArray(formFields)
      ? formFields?.map((formField) =>
          mapFormFields({
            formFields: formField?.data,
            makeFieldNameUnique: true,
            id: formField?.id,
          }),
        )
      : mapFormFields({ formFields })

  const handleSaveCoverLetter = () => {
    const isFormValid = handleSubmit({
      flag: {
        ...formFields.CMRCoverLetterDate,
        ...formFields.CMRCoverLetter,
        ...formFields.CMRTextFields,
      },
    })
    if (isFormValid) {
      const {
        project,
        clientNameAddress,
        date,
        body,
        salutation,
        subject,
        signature,
        signatureOne,
        nameAndContactInformation,
        nameAndContactInformationOne,
        overrideReportCode,
      } = values
      const CMReportCoverLetterSignature = [
        {
          signature: signature || '',
          nameAndContactInformation: nameAndContactInformation || '',
        },
        {
          signature: signatureOne || '',
          nameAndContactInformation: nameAndContactInformationOne || '',
        },
      ]
      const clientNames = clientNameMapping()
      const formData = new FormData()

      formData.append('project', project || projectId)
      formData.append('clientNameAddress', clientNameAddress || '')
      formData.append('date', date || '')
      formData.append('body', body || '')
      formData.append('salutation', salutation || '')
      formData.append('subject', subject || '')
      formData.append('report', reportId || '')
      formData.append('imageDelete', imageDelete)
      formData.append(
        'overrideReportCode',
        overrideReportCode
          ? overrideReportCode.toString().padStart(2, '0')
          : '',
      )
      clientNames?.map((data) => formData.append('client_names', +data))
      formData.append(
        'cmrCoverLetterSignature',
        JSON.stringify(CMReportCoverLetterSignature),
      )
      if (
        (!CMRCoverLetterData?.id && keys(CMRCoverLetterDefaultData)?.length) ||
        coverPhoto instanceof File
      ) {
        formData.append('coverPhoto', coverPhoto || '')
      }

      if (ocId) {
        dispatch(updateCoverLetter(reportId, projectId, ocId, formData))
      }
      setIsDirty(false)
      setImageDelete(false)
    } else {
      dispatch(updateOnSaveStatus({ cancel: true }))
    }
  }

  const addClientName = (index, locationData = false) => {
    const location = locationData || [...dynamicFields]
    const addClientObj = {
      data: formFields?.addClientNames,
      id: index,
    }
    const dataToAdd = [...location, addClientObj]

    if (!locationData) setDynamicFields([...dataToAdd])
    return [...dataToAdd]
  }

  const handleClientDelete = () => {
    const cloneValues = { ...values }
    delete cloneValues[`clientName.${deleteNotificationOpen?.id}`]
    setValues(cloneValues)
    setDynamicFields((prev) =>
      prev.filter(({ id }) => !equal(id, deleteNotificationOpen?.id)),
    )
    setIsDirty(true)
  }

  const generateNewClientId = () => {
    const lastItem = dynamicFields?.at(-1)
    const id = lastItem?.id
    const incrementedId = id !== undefined ? Number(id) + 1 : 1
    return incrementedId
  }
  return (
    <InnerLayout
      contentTitle="Cover Page & Letter"
      actions={actions}
      maxWidth="md"
      isShowMenu
    >
      <>
        {equal(isCMRCoverLetterLoading, true) ||
        equal(isCMRCoverLetterDefaultValueLoading, true) ||
        equal(isSignatureLoading, true) ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <DKTCircularProgress />
          </Stack>
        ) : equal(isCMRCoverLetterLoading, 'FAILED') ||
          equal(isSignatureLoading, 'FAILED') ||
          equal(isCMRCoverLetterDefaultValueLoading, 'FAILED') ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 'calc(100vh - 290px)' }}
          >
            <Typography variant="body2" color="gray.extraDark" ml={2}>
              There might be some issue with fetching Cover Letter & Photo data.
              Please try contacting the admin or refreshing this page.
            </Typography>
          </Stack>
        ) : (
          <Grid container spacing={8}>
            <Grid item md={6} xs={6}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <DKTForm autoComplete="off" width="100%">
                    <Grid container spacing={2}>
                      {renderFormFields(formFields.CMRReportNumber)}
                      {renderFormFields(formFields.CMRCoverLetterDate)}
                    </Grid>
                  </DKTForm>
                </Grid>
                <Grid item md={12}>
                  <DKTEditor
                    title="Client Name & Address"
                    value={values?.clientNameAddress || ''}
                    onChange={(event, editor) => {
                      handleTextEditor(
                        editor,
                        'clientNameAddress',
                        setFieldValue,
                      )
                    }}
                    disabled={hasOnlyViewPermission}
                    className={classes.projectDesc}
                  />
                </Grid>
                <Grid item md={12}>
                  <DKTForm
                    sx={{ '& .MuiGrid-root + .MuiGrid-root': { mt: 2 } }}
                    autoComplete="off"
                  >
                    {renderFormFields(formFields.CMRCoverLetter)}
                  </DKTForm>
                </Grid>
                <Grid item md={12}>
                  <DKTEditor
                    title="Body"
                    value={values?.body || ''}
                    onChange={(event, editor) => {
                      handleTextEditor(editor, 'body', setFieldValue)
                    }}
                    disabled={hasOnlyViewPermission}
                    className={classes.projectDesc}
                  />
                </Grid>
                {renderFormFields(formFields.signatures)}
                <Grid item md={6}>
                  {values?.signature && (
                    <DKTStack
                      minHeight={168}
                      maxHeight={168}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ overflow: 'hidden' }}
                    >
                      {ternary(
                        selectedSignatures.signature,
                        <img src={selectedSignatures.signature} alt="" />,
                        <ImagePlaceholder />,
                      )}
                    </DKTStack>
                  )}
                </Grid>
                <Grid item md={6}>
                  {values?.signatureOne && (
                    <DKTStack
                      minHeight={168}
                      maxHeight={168}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ overflow: 'hidden' }}
                    >
                      {ternary(
                        selectedSignatures.signatureOne,
                        <img src={selectedSignatures.signatureOne} alt="" />,
                        <ImagePlaceholder />,
                      )}
                    </DKTStack>
                  )}
                </Grid>
                <Grid item md={6}>
                  <DKTEditor
                    title="Name & Contact Information"
                    value={values?.nameAndContactInformation || ''}
                    onChange={(event, editor) => {
                      handleTextEditor(
                        editor,
                        'nameAndContactInformation',
                        setFieldValue,
                      )
                    }}
                    disabled={hasOnlyViewPermission}
                    className={classes.projectDesc}
                  />
                </Grid>
                <Grid item md={6}>
                  <DKTEditor
                    title="Name & Contact Information"
                    value={values?.nameAndContactInformationOne || ''}
                    onChange={(event, editor) => {
                      handleTextEditor(
                        editor,
                        'nameAndContactInformationOne',
                        setFieldValue,
                      )
                    }}
                    disabled={hasOnlyViewPermission}
                    className={classes.projectDesc}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                component="form"
                enctype="multipart/form-data"
                sx={{ maxWidth: 450, position: 'relative', width: '100%' }}
              >
                <DKTFileInput
                  label="Cover Page Photo (max 1.5MB)"
                  onFileUpload={onFileUpload}
                  defaultImage={coverPhoto}
                  errorText={ternary(
                    inputFileError,
                    'Upload Cover Photo',
                    null,
                  )}
                  height="254px"
                  disabled={hasOnlyViewPermission}
                  sx={{
                    paddingTop: '56.25%',
                    '& img': {
                      position: 'absolute',
                      top: 0,
                      height: '100%',
                      bottom: 0,
                      objectFit: 'contain',
                    },
                  }}
                />
                {ternary(
                  coverPhoto && !hasOnlyViewPermission,
                  <IconButton
                    color="error"
                    onClick={handleDeleteLogo}
                    sx={{ position: 'absolute', top: -10, right: 0 }}
                  >
                    <Delete />
                  </IconButton>,
                  null,
                )}
              </Box>
              <Box sx={{ maxWidth: 450, width: '100%' }}>
                <FormSectionDivider
                  sx={{ marginTop: 2 }}
                  sectionTitle="Cover Page-Client Names"
                >
                  <DKTInfoBox
                    title={
                      <List className={classes.termsWrapper}>
                        <ListItem>
                          New Clients can be added in Projects &gt; Project Info
                          &gt; Project Directory
                        </ListItem>
                      </List>
                    }
                    slotProps={{
                      popper: {
                        sx: {
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: '#062340',
                          },
                          '& .MuiTooltip-arrow:before': {
                            backgroundColor: '#062340',
                          },
                        },
                      },
                    }}
                    sx={{
                      mr: 'auto',
                      ml: 1,
                      cursor: 'pointer',
                      height: 15,
                      width: 15,
                      verticalAlign: 'text-bottom',
                    }}
                  />
                </FormSectionDivider>
                <Box sx={{ marginTop: 2 }}>
                  <DKTForm autoComplete="off">
                    <Grid container spacing={2}>
                      {renderFormFields(dynamicFields)}
                    </Grid>
                  </DKTForm>
                </Box>
                <DKTTooltip
                  title={`${ternary(
                    isDisabledAddClient,
                    disabledAddClientList,
                    '',
                  )}`}
                >
                  <span
                    style={{
                      cursor: 'pointer',
                      display: 'inline-block',
                      marginTop: 24,
                    }}
                  >
                    <DKTButton
                      variant="text"
                      color="primary"
                      sx={{ textTransform: 'initial', px: 1 }}
                      startIcon={
                        <CircledPlusIcon disabled={isDisabledAddClient} />
                      }
                      onClick={() => addClientName(generateNewClientId())}
                      disabled={isDisabledAddClient}
                    >
                      <Typography>Add Client</Typography>
                    </DKTButton>
                  </span>
                </DKTTooltip>
              </Box>
            </Grid>
          </Grid>
        )}

        {!hasOnlyViewPermission && (
          <DKTReactRouterPrompt
            isDirty={isDirty}
            onSave={handleSaveCoverLetter}
          />
        )}
        <DKTDialog
          open={deleteNotificationOpen || false}
          handleClose={handleClose}
          title="&nbsp;"
          actions={deleteNotificationAction}
          maxWidth="xs"
        >
          <Grid container>
            <Grid item xs={12}>
              <Box className={classes.deleteNotificationContent}>
                <Typography variant="h5">
                  Are you sure you want to delete?
                </Typography>
                <Typography variant="body1">
                  You can’t undo this action
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DKTDialog>
      </>
    </InnerLayout>
  )
}

export default CoverLetter
