import { FormHelperText, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { CircledPlusIcon, ImagePlaceholder } from '../Assets/SVGs'
import ImageCrop from '../Presentation/admin/companyInformation/ImageCrop'
import { allowImageType, maxLimitFileSize } from '../Utils/constant'
import {
  checkIncludes,
  compressImage,
  convertImgType,
  equal,
  gt,
  ternary,
} from '../Utils/javascript'
import { showToast } from '../Utils/toastService'
import theme from '../Theme'

const DKTFileInput = ({
  label,
  onFileUpload,
  onMultipleFileUpload,
  errorText,
  defaultImage,
  disabled,
  multiple = false,
  isCompLogo = false,
  sx,
  height,
  accept = '.heic,image/*',
  isMultiFileUpload = false,
  name = 'uploadFile',
  uploadLimit = maxLimitFileSize,
  removeUploadLimit = false,
  hideDefaultImage = false,
  setCompressedLoader = () => {},
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [uploadFile, setUploadFile] = useState(null)
  const [isImgCropModalOpen, setIsImgCropModalOpen] = useState(false)
  const [cropImage, setCropImage] = useState(null)

  const openImageCropModal = () => setIsImgCropModalOpen(true)
  const closeImageCropModal = () => setIsImgCropModalOpen(false)

  useEffect(() => {
    if (typeof defaultImage === 'string' || equal(defaultImage, null)) {
      setUploadFile(defaultImage)
    }
  }, [defaultImage])

  const uploadFileData = async (file) => {
    const fileType = file.type.split('/')
    if (fileType[0] === 'image' && checkIncludes(fileType[1], allowImageType)) {
      if (!removeUploadLimit && gt(file.size / 1024 / 1024, uploadLimit)) {
        showToast(`Image size must be less than ${uploadLimit}MB`)
        return
      }
      let updatedImage
      if (equal(fileType[1], 'heic')) {
        updatedImage = await convertImgType(file)
      } else {
        updatedImage = file
      }
      const reader = new FileReader()
      reader.readAsDataURL(updatedImage)
      reader.onloadend = ({ target }) => {
        if (isCompLogo) {
          setCropImage({ file, link: target.result })
          openImageCropModal()
        } else {
          onFileUpload(updatedImage, target.result)
          setUploadFile(target.result)
        }
      }
    } else {
      showToast('You can only upload jpg, jpeg, webp, png, heic file.')
    }
  }
  const uploadMultipleFileData = async (files) => {
    if (files) {
      const base64StringArray = []
      if (removeUploadLimit) {
        setCompressedLoader(true)
        try {
          const compressedFiles = await Promise.all(
            Array.from(files).map(async (file) => {
              if (equal(file.type, 'image/heic')) {
                const updatedImage = await convertImgType(file)

                const compressedFile = await compressImage(
                  updatedImage,
                  uploadLimit,
                )
                return compressedFile
              }
              const compressedFile = await compressImage(file, uploadLimit)
              return compressedFile
            }),
          )
          const validCompressedFiles = compressedFiles?.filter(
            (file) => file !== null,
          )

          onMultipleFileUpload(validCompressedFiles)
        } catch (error) {
          showToast('Something went wrong', 'error')
        } finally {
          setCompressedLoader(false)
        }
        return
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const fileType = files.item(i).type.split('/')
        if (
          fileType[0] === 'image' &&
          checkIncludes(fileType[1], allowImageType)
        ) {
          if (
            !removeUploadLimit &&
            gt(files.item(i).size / 1024 / 1024, uploadLimit)
          ) {
            showToast(
              `${
                files.item(i)?.name
              } size is more than 1.5MB. Image size must be less than ${uploadLimit}MB`,
            )
            break
          } else if (files[i].name.length >= 100) {
            showToast('fileName size must be less than 100 characters')
            break
          }
          base64StringArray.push(files.item(i))
        } else if (isMultiFileUpload) {
          if (!removeUploadLimit && gt(files.item(i).size / 1024 / 1024, 15)) {
            showToast('File size must be less than 15MB')
            break
          }
          base64StringArray.push(files.item(i))
        } else {
          showToast('You can only upload jpg, jpeg, webp, png, heic file.')
        }
      }
      const base64Files = await Promise.all(base64StringArray)
      if (Array.isArray(base64Files)) {
        onMultipleFileUpload(base64Files)
      }
    }
  }

  const handleFileChange = (e) => {
    if (multiple) {
      uploadMultipleFileData(e.target.files)
      return
    }
    uploadFileData(e.target.files[0])
  }

  const handleFileSelect = (e) => {
    e.preventDefault()
    const { files } = e.dataTransfer
    if (multiple) {
      uploadMultipleFileData(files)
      return
    }
    if (files.length > 0) uploadFileData(files[0])
  }
  const handleDragEnter = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsHovered(true)
  }
  const handleDragLeave = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsHovered(false)
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.dataTransfer.dropEffect = 'copy'
  }
  return (
    <>
      {label && (
        <Typography
          fontSize={12}
          fontWeight={500}
          mb={1}
          color="gray.extraDark"
        >
          {label}
        </Typography>
      )}
      {hideDefaultImage ? (
        <label
          htmlFor={name}
          style={{
            cursor: 'pointer',
            padding: '8px 12px',
            width: 'fit-content',
            color: theme.palette.primary.main,
            borderRadius: '5px',
            fontSize: '16px',
            display: 'inline-flex',
            gap: 8,
            alignItems: 'center',
          }}
        >
          <CircledPlusIcon style={{ fontSize: '20px' }} /> Upload/Replace Custom
          Background
        </label>
      ) : (
        <Stack
          position="relative"
          justifyContent="center"
          alignItems="center"
          backgroundColor={ternary(
            uploadFile,
            '#fff',
            ternary(!isHovered, 'gray.main', 'primary.light'),
          )}
          overflow="hidden"
          onDrop={!disabled && handleFileSelect}
          onDragOver={!disabled && handleDragOver}
          onDragEnter={!disabled && handleDragEnter}
          onDragLeave={!disabled && handleDragLeave}
          sx={uploadFile ? sx || {} : { height: height || '168px' }}
        >
          {ternary(
            uploadFile,
            <img src={uploadFile} alt="Company Logo" />,
            <>
              {!isMultiFileUpload && <ImagePlaceholder />}
              <Typography
                variant="subtitle2"
                color="#646464"
                fontWeight={400}
                mt={isMultiFileUpload ? 0 : 1}
                textTransform="capitalize"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {isMultiFileUpload && <CloudUploadOutlinedIcon />}&nbsp; Drag
                and drop or&nbsp;
                <label htmlFor={name} style={{ cursor: 'pointer' }}>
                  <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    color="primary.main"
                    sx={{ display: 'inline' }}
                  >
                    Upload
                  </Typography>
                </label>
              </Typography>
            </>,
          )}
        </Stack>
      )}
      <input
        type="file"
        name={name}
        id={name}
        style={{ marginTop: 10 }}
        onChange={handleFileChange}
        multiple={multiple}
        hidden
        accept={accept}
        disabled={disabled}
        onClick={(event) => {
          event.currentTarget.value = null
        }}
      />
      <FormHelperText style={{ color: '#d32f2f' }} error={!!errorText}>
        {errorText}
      </FormHelperText>
      <ImageCrop
        open={isImgCropModalOpen}
        onClose={closeImageCropModal}
        image={cropImage}
        setUploadFile={setUploadFile}
        onFileUpload={onFileUpload}
      />
    </>
  )
}

export default DKTFileInput
