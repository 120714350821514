import { forwardSlashSymbol, minusSymbol, percentageSymbol } from './symbols'

/**
 * Copyright
 */
export const copyRight = () => `© 2015-${new Date().getFullYear()} `

/**
 * CSS
 */

export const fullWidth = true

export const margin = 'normal'

/**
 * Redirection
 */

export const redirectTo = {
  dashboard: '/dashboard/kpis',
  login: '/login',
  launchpad: 'launchpad',
}

/**
 * API Endpoints
 */

export const compare = 'compare'
export const jobManagers = 'job_managers'
export const dataset = 'dataset'
export const optimization = 'optimization'
export const plannings = 'plannings'
export const workOpportunities = 'bookings'
export const workopportunity = 'work-opportunities'
export const missionControl = 'missionControl'
export const employees = 'employees'
export const label = 'labels'
export const modifiedLabel = 'modifiedLabel'

export const apiEndPoints = {
  login: 'user/login/token/',
  twoFactorAuthentication: 'user/verify-otp/',
  recentOTP: 'user/recent-otp/',
  randomPassword: 'user/random-password',
  userProfile: 'user/profile/',
  updateProfile: 'user/update-profile/',
  changePassword: 'user/change-password/',
  forgotPassword: 'user/forgot-password/',
  activateUser: 'user/activation/',
  systemAuthorization: 'user/system-authorization/',
  createCompany: 'company/',
  stateList: 'states/',
  resetPassword: 'user/reset-password/',
  companyPersonnel: 'company/personnel/',
  createCompanyPersonnel: 'user/register/',
  deleteCompanyPersonnel: 'company/personnel/delete/',
  resetPersonnelPassword: 'user/admin-reset-password/',
  company: 'company/',
  companyServiceOffered: 'company/service-offered/',
  companyServiceStateLocation: 'company/service-state-location/',
  companyServiceStateUsTerritories: 'company/service-us-territories/',
  zipCodeForStateAndCity: 'company/us-state-cities/',
  projectAssignments: 'project/assignment',
  projectChoices: 'choices/',
  report: 'company/report/',
  limitations: 'company/report/limitation/',
  reportTierSettings: 'company/report/report-tier-setting/',
  constructionSummaryFormat: 'company/report/construction-summary-format/',
  project: 'project/',
  deleteProject: 'project/delete/',
  siteAndBuilding: 'project/site-and-building',
  deleteSiteAndBuilding: 'project/site-and-building/delete/',
  siteAddressList: 'project/site-address/',
  environmental: 'project/environmental/',
  deleteEnvironmental: 'project/environmental/delete',
  ownerContractor: 'project/owner-contractor/',
  deleteOwnerContractor: 'project/owner-contractor/delete',
  buildingAndUnitInfo: 'project/building-and-unit-information/',
  siteAddressDelete: 'project/site-address/delete',
  projectDirectory: 'project/project-directory/',
  deleteProjectDirectory: 'project/project-directory/delete/',
  projectContact: 'project/project-contact/',
  ownerArchitect: 'project/owner-architect/',
  deleteOwnerArchitect: 'project/owner-architect/delete/',
  constructionSchedule: 'project/construction-schedule/',
  deleteConstructionSchedule: 'project/construction-schedule/delete/',
  projectTeam: 'project/project-team-member/',
  deleteProjectTeam: 'project/project-team-member/delete/',
  reportDistribution: 'project/project-contact/',
  budgetContingency: 'project/budget-and-contingency/',
  deleteBudgetContingency: 'project/budget-and-contingency/delete/',
  deleteReportDistribution: 'project/project-contact/delete/',
  projectContactSearch: 'project/project-contact/search/',
  projectContactUpdate: 'project/project-contact/company/',
  reportTier: 'project/report-tier/',
  reportChoices: 'unique-codes/',
  reportList: 'report/',
  deleteReport: 'report/delete/',
  reportPerformanceChart: 'report/report-data/',
  getReportDrawProgressionChart: 'report/draw-progress-economic-completion/',
  reportDrawProcess: 'report/draw-progress/',
  reportEconomicCompletion: 'report/economic-completion/',
  reportType: 'company/report-type/',
  executiveSummary: 'report/executive-summary/',
  importLastReport: 'report/import/',
  budgetSummary: 'report/budget-summary/',
  g703Tracker: 'report/G703-tracker/',
  lienWaiversTracker: 'report/lien-waiver-tracker/',
  storedMaterialTracker: 'report/stored-material-tracker/',
  coTracker: 'report/co-tracker/',
  pcoTracker: 'report/pco-tracker/',
  contingency: 'report/contingency/',
  constructionSummary: 'report/construction-summary/',
  constructionScheduleReport: 'report/construction-schedule/',
  phaseTracker: 'report/phase-tracker/',
  milestoneTracker: 'report/milestone-tracker/',
  permitsEntitlements: 'report/permits-and-entitlements/',
  certificateOfOccupancy: 'report/building/',
  thirdPartyReport: 'report/third-party-report/',
  thirdPartyReportSection: 'report/third-party-report-section/',
  photographs: 'report/photograph/',
  photographsUpdate: 'report/photograph-update/',
  appendix: 'report/appendix/',
  milestoneTrackerLastReportData: 'report/milestone-tracker/last-report/',
  phaseTrackerLastReportData: 'report/phase-tracker/last-report/',
  globalDashboard: 'project/dashboard-project/',
  favorites: 'project/favorite-project/',
  exportReport: 'report/report-pdf/',
  defaultValueOfCMRCoverLetter: 'report/cover-letter-default-values/',
  CMRCoverLetter: 'report/cover-letter/',
  addUpdateCoverLetter: 'report/cover-letter/',
  getPdfList: 'report/attachments-upload/',
  updateREportAttachmentsPdfList: 'report/attachments-update/',
  deleteAttachmentPdf: 'report/attachment/delete/',
  getProjectPdfList: 'project/attachments-upload/',
  updateProjectAttachmentsPdfList: 'project/attachments-update/',
  deleteProjectAttachmentPdf: 'project/attachment/delete/',
  projectDashboard: 'project/dashboard/',
  projectDashboardAttachments: 'project/dashboard-attachment/',
  reportIssue: 'company/report-issues/',
  clientCompany: 'client/',
  clientProjects: 'client/projects',
  clientProjectAccess: 'client/access/',
  clientAccessDashboard: 'client/dashboard/',
  projectLimitation: 'project/project-limitation',
  signature: 'user/signatures/',
  generateLink: 'report/report-requests/',
  refreshAccessToken: 'user/login/token/refresh/',
  manpowerTracker: 'report/manpower-tracker/',
  permitTracker: 'report/permit-tracker/',
  g702SplitTracker: 'report/G702-tracker/',
  issueLog: 'report/issue-log/',
  companyES: 'company/executive-summary/',
  autoGenEsValues: 'report/executive-summary-values/',
  autoGenEsText: 'report/executive-summary-report-text/',
  companyKeyPersonnel: 'company/key-personnel/',
  clientsName: 'report/project-clients/',
  rfpList: 'project/rfp/',
  scopeServiceOpts: 'project/services/',
  rfpCompany: 'company/company-list/',
  rfpFavoriteCompany: 'company/favourite-company/',
  sendRFPNotification: 'project/send-rfp-notification/',
  bids: 'project/bids-list/',
  returnBid: 'project/return-bid/',
  projectBids: 'project/bids/',
  resendMail: 'user/resend-mail/',
  downloadExcel: 'company/excel-report/',
  coverPage: 'company/report-pdf-background-image/',
}

/**
 * Methods
 */

export const method = {
  post: 'post',
  get: 'get',
  delete: 'delete',
  put: 'put',
  patch: 'patch',
}

/**
 * Input field
 */

export const fileFormatSelect = 'File Format'

export const startYear = 'FY'

export const numberPostFix = percentageSymbol

export const accept =
  '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12'

export const successStatus = [200, 201, 204]

export const notEmptyOrNull = 'notEmptyOrNull'

export const yearWithPrefix = 'yearWithPrefix'

export const numberWithPercentage = 'numberWithPercentage'

export const zipCode = 'zipCode'

export const password = 'password'

export const email = 'email'

export const confirmPasswordConst = 'confirmPassword'

export const number = 'number'

export const numerical = 'numerical'

export const defaultTrueValidation = 'defaultTrueValidation'

export const stringValidation = 'stringValidation'

/**
 * Button
 */
export const startOptimization = 'Start optimization'

export const startDataImport = 'Start data import'

export const addDataset = 'Add dataset'

export const addTalent = 'Add talent'

export const addCategory = 'Add category'

export const editTalent = 'Update'

export const addLabel = 'Add'

export const removeLabel = 'Remove'

export const updateExcludeInclude = 'Update Include/ Exclude'

export const startNewOptimization = 'Start New Optimization'

export const latestReport = `${new Date().toISOString()} Test UAT - Full Optimization`

/**
 * Dashboard
 */

export const noOptimizationDataAvailable = 'Create new optimization'

/**
 * Extra constants
 */

export const suggestedEmployee = 'suggestedEmployee'

export const success = 'SUCCESSFUL'

export const started = 'STARTED'

export const failed = 'FAILED'

export const inprogress = 'INPROGRESS'

export const y = 'YES'

export const n = 'NO'

export const counts = 'Show by count'

export const hours = 'Show by hours'

export const isReportDownload = true

export const submit = 'Submit'

export const labelCategory = 'labelCategory'

export const topLocation = 'Top 6 Locations'

export const emptyObject = {}

export const addLabelNote =
  'Labels are not added again if a talent already has a label.'

export const removeLabelNote =
  'Labels are only removed from the talents that have those labels.'

export const importStatus = {
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
  INPROGRESS: 'IN PROGRESS',
  STARTED: 'STARTED',
}

export const defaultPage = 1
export const limit = 100

export const emptyValue = 'Blank'

export const addAction = 'add'

export const name = 'name'

export const modifyApiObj = {
  key: label,
  labelKey: name,
  modifiedKey: modifiedLabel,
}

export const isRunning = [inprogress, started]
const hashTable = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
]

export const uid = () => {
  const uuid = []
  for (let i = 0; i < 35; i += 1) {
    if (i === 7 || i === 12 || i === 17 || i === 22) {
      uuid[i] = minusSymbol
    } else {
      uuid[i] = hashTable[Math.floor(Math.random() * hashTable.length - 1)]
    }
  }
  return uuid.join('')
}

export const setTotalOverviewWidth = {
  1: 15,
  2: 30,
  3: 45,
  4: 60,
  5: 70,
}
export const setSubTotalOverviewWidth = {
  1: 15,
  2: 25,
  3: 35,
  4: 45,
  5: 55,
}

export const newJoiner = 'newJoiner'

export const exclude = 'exclude'

export const coreTeam = 'coreTeam'

export const reqHrs = 'requested_hours'

export const genHrs = 'generic_hours'

export const hrs = 'hours'

export const count = 'count'

export const timeFte = 'timeFte'

export const totalScore = 'Total Score'

export const assignedHour = 'assigned_hours'

export const employeeDetail = 'employeeDetails'

export const planningCounts = 'Planning Start Date / Planning End Date'
// color

export const white = '#FFFFFF'
export const steelBlue = '#3C678E'
export const downy = '#59C8B1'
export const flamenco = '#FF7400'
export const doveGray = '#666666'
export const error = '#DD3B4E'
export const jordyBlue = '#64A2DC'
export const tradewind = '#50AC9A'
export const purple = '#9c27b0'

export const OBJECT = 'object'

export const STRING = 'string'

export const BOOLEAN = 'boolean'

export const NUMBER = 'number'

export const localeConstant = 'locale'

export const show = 'Show'

export const hide = 'Hide'

export const optimizationStatus = 'optimizationStatus'

export const kpis = 'KPIs'

export const optimizations = 'Optimizations'

export const moreTheBetter = 'More the better'

export const lessTheBetter = 'Less the better'

export const inputTypeSelect = 'select'

export const uuidConstant = 'uuid'

export const noPlanningFound = 'No planning found'

export const noDetailChangesFound = 'No detail change found'

export const employeeLabels = 'employee_labels'

export const empModifiedLabel = 'empModifiedLabel'

export const bookingLabels = 'booking_labels'

export const bookingModifiedLabel = 'bookingModifiedLabel'

export const btnVariant = {
  outlined: 'outlined',
  contained: 'contained',
}

export const btnColor = {
  primary: 'primary',
  secondary: 'secondary',
}

export const btnSize = {
  large: 'large',
  small: 'small',
  medium: 'medium',
}

export const btnType = {
  submit: 'submit',
  cancel: 'cancel',
  button: 'button',
}

export const backBtnTitle = 'Back'

export const nextBtnTitle = 'Next'

export const importDataBtnTitle = 'Import data'

export const validateBtnTitle = 'Validating data'

export const downloadLogsBtnTitle = 'Download logs'

export const differenceConstant = 'difference'

export const hoursConstant = 'hours'

export const rangeConstant = 'range'

export const dateRangeConstant = 'dateRange'

export const fitConstant = 'fit'

export const timeConstant = 'time'

export const timeFTEConstant = 'timeFte'

export const entryDateConstant = 'entryDate'

export const leaveDateConstant = 'leaveDate'

export const cursorPointerStyle = 'pointer'

export const showCheckBoxNone = 'none'

export const suggestedEmployeeConstant = 'Suggested Talent'

export const tooltipPlacement = { left: 'left', right: 'right' }

export const slashSeparator = ` ${forwardSlashSymbol} `

export const operatingUnit = 'Operating Unit'

export const included = 'Included'

export const FTE = 'FTE'

export const grade = 'Grade'

export const assignedHours = 'Assigned Hours'

export const unassignedHours = 'Unassigned hours'

export const MIN_VALUE = 0

export const MIN_ONE = 1

export const defaultType = 'application/json'

export const errorStatusCodes = [401, 404, 500]

export const noEntries = 'No entries found'

export const percentageValue = 'percentageValue'

export const nullEmptyValues = [null, undefined, 'null', '']

export const noEntrieswithTip =
  'No entries found. Tip: Hidden columns are not searched, show them to include them in search.'

export const rowSelectionChange = 'rowSelectionChange'

export const companyLogoError = 'Upload Company Logo'

export const administrator = 'A'
export const globalEditor = 'GE'
export const globalViewer = 'GV'
export const projectLevel = 'PL'

// Portal
export const clientPortal = 'CL'
export const consultantPortal = 'CT'

/* System authorization */
export const SA = {
  administrator,
  globalEditor,
  globalViewer,
  projectLevel,
}

export const roles = [administrator, globalEditor, globalViewer, projectLevel]

export const adminOnlyRole = [administrator]

export const adminAccessRoles = [administrator, clientPortal]

export const clientCompanyAccessRoles = [...roles, clientPortal]

export const spinnerSize = {
  sm: 25,
  md: 30,
  lg: 40,
  xl: 45,
}

export const importButtonTooltip =
  'Imports content from previous report if available'

export const milestoneDeleteMessage = 'Milestone deleted successfully!'

// Valid image file format for upload
export const allowImageType = ['jpg', 'jpeg', 'png', 'webp', 'heic']

// Maximum file size upload limit(in MB)
export const maxLimitFileSize = 1.5
export const documentDeleteMessage = 'Document deleted successfully!'

// Editor Comment Configuration toolbar options
export const editorCommentConfiguration = {
  toolbar: ['bold', 'italic', 'Underline', '|', 'NumberedList', 'BulletedList'],
  placeholder: 'Placeholder Text',
}
// symbol
export const fahrenheit = '℉'

// Swap mapping warning message
export const swapWarning =
  'The selected Owner-Contractor Agreement Mapping already in use, Are you sure you want to select it again?'

// Swap mapping message based on OC
export const swapWarningOC =
  'Action cannot be completed. Number of sections created on this page is limited by number of Owner-Contractor Agreements.'

/// / Google map keys
export const mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY
/// /

export const twoFAText =
  'Two-Factor Authentication (2FA) adds an extra layer of security to your account by requiring two independent factors for authentication. It provides an additional level of protection against unauthorized access and helps prevent security breaches.'

// image upload quality
export const quality = 1.0

// dashboard filter type
export const dashboardFilters = {
  projectType: 'all',
  projectStatus: 'all',
  constructionType: 'all',
}

export const selectAllOption = {
  value: 'all',
  label: 'Select/Unselect All',
}

export const publish = 'P'

export const g702TrackerTooltipText =
  'Save the current pay app first in order to use G702 tracker'

export const PLUS = '+'
export const MINUS = '-'
export const MULTIPLY = '*'
export const DIVIDE = '/'

export const MAX_PDF_SIZE_MB = 50

export const sitekey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY
export const recaptchaLoginAction = 'login'
export const reCaptchaId = 'html_element'
export const actionDisabled = true

export const contactForRFPText =
  'Add Contact for RFP button disabled if all personnel names have been selected or if there are no personnel names available in the company personnel list.'

export const disabledAddClientList =
  'No client is available to add, or you might have reached the limit for adding clients to this report.'

export const noDataMessage = 'Sorry, there is no matching data to display'
