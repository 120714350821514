import { InputAdornment } from '@mui/material'
import { numberRangeValidation } from '../Utils/regex'
import { BOOLEAN } from '../Utils/constant'

const percentageAdornment = <InputAdornment position="end">%</InputAdornment>

export const formFields = {
  draw: {
    drawNumber: {
      type: 'select',
      label: 'Draw Number',
      placeholder: 'Select',
      xs: 2.8,
      lg: 2.8,
      isRequired: true,
      displayEmpty: true,
      isDynamicOptions: true,
    },
    drawReceivedDate: {
      type: 'date',
      label: 'Draw Received Date',
      xs: 2.8,
      lg: 2.8,
      isRequired: true,
    },
  },
  paymentApplication: {},
  changeOrder: {
    changeOrderPrevious: {
      type: 'text',
      label: 'Change Orders Previous',
      isCurrency: true,
      xs: 6,
      lg: 6,
    },
    amendedDaysPrevious: {
      type: 'text',
      label: 'Amended Days Previous',
      xs: 6,
      lg: 6,
    },
    changeOrderCurrent: {
      type: 'text',
      label: 'Change Orders Current',
      isRequired: true,
      isCurrency: true,
      xs: 6,
      lg: 6,
    },
    amendedDaysCurrent: {
      type: 'number',
      label: 'Amended Days Current',
      isRequired: true,
      xs: 6,
      lg: 6,
    },
    changeOrderTotal: {
      type: 'text',
      label: 'Change Order Total',
      isCurrency: true,
      disabled: true,
      xs: 6,
      lg: 6,
    },
    amendedDaysTotal: {
      type: 'number',
      label: 'Amended Days Total',
      disabled: true,
      xs: 6,
      lg: 6,
    },
  },
  pcoChangeOrder: {
    isPrintPotentialChangeOrder: {
      type: 'checkbox',
      valueType: BOOLEAN,
      fields: [
        {
          label: 'Print in report',
          value: 'isPrintPotentialChangeOrder',
          labelledCheckbox: true,
        },
      ],
      sx: {
        ':first-of-type': {
          paddingTop: 0,
        },
      },
    },
    potentialChangeOrderTotal: {
      type: 'text',
      label: 'Potential Change Orders Total',
      isCurrency: true,
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintPotentialChangeOrder',
    },
    amendedDays: {
      type: 'number',
      label: 'Amended Days',
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintPotentialChangeOrder',
    },
  },
  paymentApplicationColumnLeft: {
    payApplicationNumber: {
      type: 'select',
      label: 'Pay Application Number',
      placeholder: 'Select',
      isRequired: true,
      displayEmpty: true,
      isDynamicOptions: true,
      xs: 7.5,
      lg: 7.5,
    },
    override: {
      type: 'select',
      label: 'Override',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      xs: 7.5,
      lg: 4.5,
    },
    periodTo: {
      type: 'date',
      label: 'Period To',
      isRequired: true,
      lg: 12,
    },
    contractSum: {
      type: 'text',
      isCurrency: true,
      label: 'Original Contract Sum',
      lg: 12,
      disabled: true,
    },
    netChangeByChangeOrders: {
      type: 'text',
      label: 'Net Change by Change Orders',
      lg: 12,
      isRequired: true,
      isCurrency: true,
    },
    contractSumToDate: {
      type: 'text',
      label: 'Contract Sum to Date',
      lg: 12,
      isCurrency: true,
      disabled: true,
      isLimitLess: true,
    },
    totalCompletedStored: {
      type: 'text',
      label: 'Total Completed + Stored',
      lg: 12,
      isCurrency: true,
      isRequired: true,
    },
    retainage: {
      type: 'text',
      label: 'Retainage',
      lg: 7.5,
      isRequired: true,
      isCurrency: true,
    },
    retainageCalculation: {
      type: 'text',
      label: '%',
      lg: 4.5,
      endAdornment: percentageAdornment,
      disabled: true,
      decimalPlaces: 2,
    },
    totalEarnedLessRetainage: {
      type: 'text',
      label: 'Total Earned Less Retainage',
      lg: 12,
      isCurrency: true,
      disabled: true,
      isLimitLess: true,
    },
    previousDisbursements: {
      type: 'text',
      label: 'Less Previous Certificates for Payment',
      lg: 12,
      isCurrency: true,
      isLimitLess: true,
    },
    currentPaymentDue: {
      type: 'text',
      label: 'Current Payment Due',
      lg: 12,
      isRequired: true,
      isCurrency: true,
      isLimitLess: true,
    },
    balanceToFinish: {
      type: 'text',
      label: 'Balance to Finish (incl. Retainage)',
      lg: 12,
      isCurrency: true,
      disabled: true,
      isLimitLess: true,
    },
  },
  paymentApplicationColumnRight: {
    percentComplete: {
      type: 'text',
      label: 'Percent Complete',
      lg: 12,
      endAdornment: percentageAdornment,
      disabled: true,
      decimalPlaces: 2,
    },
    consultantsComplete: {
      type: 'text',
      label: 'Consultant’s Percent Complete',
      lg: 12,
      endAdornment: percentageAdornment,
      decimalPlaces: 2,
      inputProps: { inputMode: 'numeric', min: 0, max: 100 },
      validate: [
        numberRangeValidation,
        'Consultant’s Percent Complete should only be from 0-100%',
      ],
    },
    estimatedBuyout: {
      type: 'number',
      label: 'Estimated Buyout Percent',
      lg: 12,
      endAdornment: percentageAdornment,
      formControlProps: {
        sx: {
          mb: '77px',
        },
      },
      inputProps: { inputMode: 'numeric', min: 0, max: 100 },
      validate: [
        numberRangeValidation,
        'Estimated Buyout Percent should only be from 0-100%',
      ],
    },
    disbursementRecommendation: {
      type: 'radioGroup',
      label: 'Disbursement Recommendation',
      lg: 12,
      isDynamicFields: true,
      labelSX: {
        marginLeft: '-14px',
        position: 'relative',
        marginBottom: '-10px',
      },
    },
    disbursementTotalCompletedStored: {
      type: 'text',
      label: 'Total Completed + Stored',
      lg: 12,
      isCurrency: true,
      isRequired: true,
    },
    disbursementRetainage: {
      type: 'text',
      label: 'Retainage',
      lg: 12,
      isRequired: true,
      isCurrency: true,
    },
    disbursementTotalEarnedLessRetainage: {
      type: 'text',
      label: 'Total Earned Less Retainage',
      lg: 12,
      isCurrency: true,
      disabled: true,
      isLimitLess: true,
    },
    disbursementPreviousDisbursements: {
      type: 'text',
      label: 'Less Previous Certificates for Payment',
      lg: 12,
      isCurrency: true,
      isLimitLess: true,
    },
    disbursementCurrentPaymentDue: {
      type: 'text',
      label: 'Current Payment Due',
      lg: 12,
      isRequired: true,
      isCurrency: true,
      isLimitLess: true,
    },
    disbursementBalanceToFinish: {
      type: 'text',
      label: 'Balance to Finish (incl. Retainage)',
      lg: 12,
      isCurrency: true,
      disabled: true,
      isLimitLess: true,
    },
  },

  // Lien Waivers & Stored Materials
  lienWaivers: {
    isPrintLienWaiver: {
      type: 'checkbox',
      valueType: BOOLEAN,
      fields: [
        {
          label: 'Print in report',
          value: 'isPrintLienWaiver',
          labelledCheckbox: true,
        },
      ],
      sx: {
        ':first-of-type': {
          paddingTop: 0,
        },
      },
    },
    issuerName: {
      type: 'text',
      label: 'Issuer Name',
      xs: 12,
      lg: 12,
      disabledKey: 'isPrintLienWaiver',
    },
    issuerType: {
      type: 'select',
      label: 'Issuer Type',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      xs: 12,
      lg: 12,
      disabledKey: 'isPrintLienWaiver',
    },
    conditionalLienWaiver: {
      type: 'text',
      label: 'Conditional Lien Waiver',
      xs: 6,
      lg: 6,
      isCurrency: true,
      disabledKey: 'isPrintLienWaiver',
    },
    conditionalDateReceived: {
      type: 'date',
      label: 'Date',
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintLienWaiver',
    },
    unconditionalLienWaiver: {
      type: 'text',
      label: 'Unconditional Lien Waiver',
      xs: 6,
      lg: 6,
      isCurrency: true,
      disabledKey: 'isPrintLienWaiver',
    },
    unconditionalDateReceived: {
      type: 'date',
      label: 'Date',
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintLienWaiver',
    },
  },
  storedMaterials: {
    isPrintStoredMaterials: {
      type: 'checkbox',
      valueType: BOOLEAN,
      fields: [
        {
          label: 'Print in report',
          value: 'isPrintStoredMaterials',
          labelledCheckbox: true,
        },
      ],
      sx: {
        ':first-of-type': {
          paddingTop: 0,
        },
      },
    },
    storedMaterialsOnSite: {
      type: 'select',
      label: 'Stored Materials On Site',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintStoredMaterials',
    },
    materialsStoredToDate: {
      type: 'text',
      label: 'Material Stored To Date ($)',
      placeholder: 'Select',
      displayEmpty: true,
      isCurrency: true,
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintStoredMaterials',
    },
    storedMaterialsOffSite: {
      type: 'select',
      label: 'Stored Materials Off Site',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintStoredMaterials',
    },
    documentationReceived: {
      type: 'select',
      label: 'Documentation Received',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintStoredMaterials',
    },
    materialDepositThisPeriod: {
      type: 'text',
      label: 'Material Deposit This Period ($)',
      placeholder: 'Select',
      displayEmpty: true,
      isCurrency: true,
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintStoredMaterials',
    },
    depositDocumentationReceived: {
      type: 'select',
      label: 'Deposit Documentation Received',
      placeholder: 'Select',
      displayEmpty: true,
      isDynamicOptions: true,
      xs: 6,
      lg: 6,
      disabledKey: 'isPrintStoredMaterials',
    },
  },
}

export const options = {
  responsive: 'standard',
  filter: false,
  search: false,
  print: false,
  download: false,
  viewColumns: false,
}

export const columns = [
  {
    name: 'id',
    options: {
      display: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    },
  },
  {
    name: 'drawNumber',
    label: 'Draw Number',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'payApplicationNumber',
    label: 'Pay Application #',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'periodTo',
    label: 'Period To',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'currentPaymentDue',
    label: 'Current Payment Due',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'recommended',
    label: 'Recommended',
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: 'override',
    options: {
      filter: true,
      sort: true,
      display: false,
    },
  },
]
